import type { Team, User } from '@/__generated__/types';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';

interface CollaboratorProps {
  user: User;
  teams?: Team[];
  withTitle?: boolean;
  withTeams?: boolean;
}

export default function Collaborator ({ user, teams, withTitle = false }: CollaboratorProps) {
  const loggedInUser = useLoggedInUser();

  const isInternal = user?.organization?.id === loggedInUser?.organizationId ||
    user?.organizationId === loggedInUser?.organizationId;

  const isDeleted = Boolean(user?.deletedAt);

  const teamsToDisplay = teams?.slice(0, 2);

  return (
    <span
      className={cx('flex gap-2 items-center', {
        'opacity-50': isDeleted,
      })}
    >
      <ProfileImage user={user} size='collaborator' />
      <div className='flex flex-col'>
        <p
          className={cx('effra-14', {
            'text-gray-60': isDeleted,
            'text-primary': isInternal && !isDeleted,
            'text-tertiary': !isInternal && !isDeleted,
          })}
        >
          {user?.id === loggedInUser?.id ? 'You' : user?.fullName?.trim()}
          {isDeleted && <span className='effra-12 text-gray-60'>(Removed User)</span>}
        </p>
        <p>{withTitle && user?.title}</p>
        <div className='flex items-center gap-[8px] effra-12'>
          {teamsToDisplay?.map((team) => (
            <div key={team.id} className='flex items-center gap-[4px] px-[4px] py-[2px] bg-gray-90 rounded-[2px]'>
              <div>
                <FontAwesomeIcon icon={faUsersLine} />
              </div>
              <div>{team.name}</div>
            </div>
          ))}
          {teams?.length > 2 && (
            <div className='flex items-center gap-[4px] px-[4px] py-[2px] bg-gray-90 rounded-[2px]'>
              <div>{`+${teams.length - 2}`}</div>
            </div>
          )}
        </div>
      </div>
    </span>
  );
}
