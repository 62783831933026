import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { TaskFragmentDoc } from './taskFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetAllTasksQueryVariables = Types.Exact<{
  filters: Types.InputMaybe<Types.DriverFilters>;
}>;


export type GetAllTasksQuery = { __typename?: 'Query', getAllTasks: Array<{ __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, templateId: string | null, assigneeId: string | null, ownerId: string | null, visibility: string | null, deactivated: boolean | null, status: Types.TaskStatus | null, statusModuleId: string | null, approvalsEnabled: boolean | null, permission: Types.Permission | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, assignee: { __typename?: 'User', id: string, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null }> | null }> | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null }> | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: Types.ShareRole | null, userId: string | null }> | null }> };


export const GetAllTasksDocument = gql`
    query GetAllTasks($filters: DriverFilters) {
  getAllTasks(filters: $filters) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

/**
 * __useGetAllTasksQuery__
 *
 * To run a query within a React component, call `useGetAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTasksQuery, GetAllTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(GetAllTasksDocument, options);
      }
export function useGetAllTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTasksQuery, GetAllTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(GetAllTasksDocument, options);
        }
export function useGetAllTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllTasksQuery, GetAllTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(GetAllTasksDocument, options);
        }
export type GetAllTasksQueryHookResult = ReturnType<typeof useGetAllTasksQuery>;
export type GetAllTasksLazyQueryHookResult = ReturnType<typeof useGetAllTasksLazyQuery>;
export type GetAllTasksSuspenseQueryHookResult = ReturnType<typeof useGetAllTasksSuspenseQuery>;
export type GetAllTasksQueryResult = Apollo.QueryResult<GetAllTasksQuery, GetAllTasksQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllTasksQuery((req, res, ctx) => {
 *   const { filters } = req.variables;
 *   return res(
 *     ctx.data({ getAllTasks })
 *   )
 * })
 */
export const mockGetAllTasksQuery = (resolver: Parameters<typeof graphql.query<GetAllTasksQuery, GetAllTasksQueryVariables>>[1]) =>
  graphql.query<GetAllTasksQuery, GetAllTasksQueryVariables>(
    'GetAllTasks',
    resolver
  )
