import { useEditContext } from '@/components';
import DisplayValue from '@/components/modules/DisplayValue';
import { useUpdateAttributeValue } from '@/hooks/useUpdateAttributeValue';
import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { usePopper } from 'react-popper';
import HookFormLabel from '../HookFormLabel';
import useAutoValue from '../useAutoValue';
import useGetTemplateModuleName from '../useGetTemplateModuleName';
import SelectBoxButton from './SelectBoxButton';
import SelectBoxOptions from './SelectBoxOptions';

export interface Option {
  label: string;
  value: string;
  isProRestricted?: boolean;
}

export interface SelectInputProps {
  className?: HTMLDivElement['className'];
  options: Option[];
  labelProps?: React.ComponentProps<typeof HookFormLabel>;
  buttonProps?: React.ComponentProps<typeof SelectBoxButton>;
  name: string;
  theme?: 'light' | 'dark';
  mapOfAutoValues?: Record<string, string>;
  fieldToWatch?: string;
  enableSubscription?: boolean;
  isNotAttribute?: boolean;
}

export default function SelectInput (
  {
    className,
    labelProps,
    buttonProps,
    name,
    options,
    theme = 'light',
    mapOfAutoValues = {},
    fieldToWatch,
    enableSubscription,
    isNotAttribute,
  }: Readonly<SelectInputProps>,
): JSX.Element {
  const { canUserEdit } = useEditContext();
  const { control, setValue } = useFormContext() ?? {};
  const value: string = useWatch({ name }) ?? '';
  const [selectedItem, setSelectedItem] = useState<string | null>(value);
  const [popperElement, setPopperElement] = useState(null);
  const [popperRefElement, setPopperRefElement] = useState(null);
  const { styles, attributes } = usePopper(popperRefElement, popperElement);

  const data = useAutoValue({
    fieldToWatch,
    mapOfAutoValues,
    enableSubscription,
  });

  const templateId = useWatch({ name: 'templateId' });
  const files = useWatch({ name: 'files' });
  const hasFile = files?.length > 0;

  const showValidation = !hasFile && (name === 'Verification Type') &&
    (['Demonstration', 'Inspection', 'Test'].includes(selectedItem));

  const fieldToWatchName = useGetTemplateModuleName(templateId, fieldToWatch);

  const { isDirty: fieldToWatchIsDirty } = useFormState({ name: fieldToWatchName });

  const { updateValue } = useUpdateAttributeValue();

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  useEffect(() => {
    if (data && fieldToWatchIsDirty) {
      setSelectedItem(data);
      setValue(name, data, { shouldDirty: true });
    }
  }, [data]);

  const handleChange = useCallback((value, cb: (value) => void) => {
    setSelectedItem(value);
    if (cb) cb(value);
    if (isNotAttribute) return;
    void updateValue({ newFieldValue: value, attributeName: name });
  }, [name, updateValue]);

  return (
    <div className={cx('relative', theme, className)}>
      {canUserEdit ?
        (
          <>
            <HookFormLabel className='mb-2 label' {...labelProps} />
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => (
                <Listbox
                  value={value}
                  onChange={(value) => handleChange(value, onChange)}
                >
                  <SelectBoxButton
                    name={labelProps?.label}
                    selectedItem={selectedItem}
                    ref={setPopperRefElement}
                    {...buttonProps}
                  />
                  {showValidation && (
                    <div className='text-[10px] uppercase text-[#EF2F95] tracking-widest'>
                      Please upload a file to verify that the requirement has been met.
                    </div>
                  )}

                  <SelectBoxOptions
                    options={options}
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                  />
                </Listbox>
              )}
            />
          </>
        ) :
        <DisplayValue className={className} labelProps={labelProps} value={value} />}
    </div>
  );
}
