import { useEditContext } from '@/components';
import { CreateNewTaskModal } from '@/components/modals';
import SelectTasksToAddModal from '@/components/modals/AddTaskModal/SelectTasksToAddModal';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
interface AddMilestoneButtonProps {
  className?: string;
  hardwareId?: string;
}
export default function AddNewTaskButton (
  { hardwareId, className }: Readonly<AddMilestoneButtonProps>,
): JSX.Element {
  const { objectCardData } = useObjectCardContext();
  const isTask = objectCardData?.template?.type === 'task';
  const { closeModal, showModal } = useModal();
  const { canUserEdit } = useEditContext();
  const { addChild } = useNestingMutations(hardwareId);

  const state = useAppSelector(selectRootState);
  const { modularObjects: modularObjectState, templates } = state;

  const objects = Object.values(modularObjectState);
  const tasks = objects.filter((object) => templates[object?.templateId]?.type === 'task');

  if (!canUserEdit) return null;
  return (
    <button
      type='button'
      className={`font-medium flex items-center btn-primary-hollow effra-xs px-4 py-3 justify-center gap-3 ${className}`}
      onClick={() => {
        tasks.length ?
          showModal(
            <SelectTasksToAddModal
              hardwareId={hardwareId}
              onSubmit={async (integrations) => {
                await addChild({
                  variables: {
                    input: integrations.map(({ parentId, objectId }) => ({
                      parentId,
                      objectId,
                    })),
                  },
                });
                closeModal();
              }}
            />,
            { isMegaModal: true, showCloseIcon: false },
          ) :
          showModal(
            <CreateNewTaskModal
              hardwareId={hardwareId}
            />,
            { isMegaModal: true, showCloseIcon: false },
          );
      }}
    >
      <span className='whitespace-nowrap truncate'>
        {isTask ? 'Add Nested Driver' : 'Add Driver'}
      </span>
    </button>
  );
}
