import cx from 'classnames';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onClose: () => void;
  header: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  cancelText?: string;
  confirmText: string;
  headerClass?: string;
}

export function ConfirmationModal ({
  onConfirm,
  onClose,
  header,
  message,
  cancelText,
  confirmText,
  headerClass,
}: ConfirmationModalProps): JSX.Element {
  return (
    <div className='flex-1' key='confirmation-modal-content'>
      <div className={cx(headerClass, 'flex pt-0 pb-6 effra-24 font-bold')}>
        {header}
      </div>
      <div className='pb-6 whitespace-pre-line effra-14'>{message}</div>
      <div className='flex flex-row gap-4 justify-between'>
        {cancelText && (
          <button
            type='button'
            className='w-1/2 font-medium btn-primary-hollow effra-xs'
            onClick={onClose}
          >
            {cancelText}
          </button>
        )}
        <button
          type='button'
          className={cx('font-medium btn-primary effra-xs', { 'w-1/2': cancelText, 'w-full': !cancelText })}
          onClick={onConfirm}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
}
