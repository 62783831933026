import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetUserCountAndModularObjectCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserCountAndModularObjectCountQuery = { __typename?: 'Query', getUserCountAndModularObjectCount: { __typename?: 'UserCountAndModularObjectCount', userCount: number | null, buildCount: number | null, driverCount: number | null } };


export const GetUserCountAndModularObjectCountDocument = gql`
    query GetUserCountAndModularObjectCount {
  getUserCountAndModularObjectCount {
    userCount
    buildCount
    driverCount
  }
}
    `;

/**
 * __useGetUserCountAndModularObjectCountQuery__
 *
 * To run a query within a React component, call `useGetUserCountAndModularObjectCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCountAndModularObjectCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCountAndModularObjectCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCountAndModularObjectCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>(GetUserCountAndModularObjectCountDocument, options);
      }
export function useGetUserCountAndModularObjectCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>(GetUserCountAndModularObjectCountDocument, options);
        }
export function useGetUserCountAndModularObjectCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>(GetUserCountAndModularObjectCountDocument, options);
        }
export type GetUserCountAndModularObjectCountQueryHookResult = ReturnType<typeof useGetUserCountAndModularObjectCountQuery>;
export type GetUserCountAndModularObjectCountLazyQueryHookResult = ReturnType<typeof useGetUserCountAndModularObjectCountLazyQuery>;
export type GetUserCountAndModularObjectCountSuspenseQueryHookResult = ReturnType<typeof useGetUserCountAndModularObjectCountSuspenseQuery>;
export type GetUserCountAndModularObjectCountQueryResult = Apollo.QueryResult<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserCountAndModularObjectCountQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getUserCountAndModularObjectCount })
 *   )
 * })
 */
export const mockGetUserCountAndModularObjectCountQuery = (resolver: Parameters<typeof graphql.query<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>>[1]) =>
  graphql.query<GetUserCountAndModularObjectCountQuery, GetUserCountAndModularObjectCountQueryVariables>(
    'GetUserCountAndModularObjectCount',
    resolver
  )
