import { useCallback, useReducer } from 'react';
import { addTeam, addUser, removeTeam, removeUser, setTeams, setUsers } from './CollabDiscovery.actions';
import reducer, { initialState } from './CollabDiscovery.reducer';

export default function useSearchableUserList () {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddUser = useCallback((user) => {
    dispatch(addUser(user));
  }, []);

  const handleRemoveUser = useCallback((id) => {
    dispatch(removeUser(id));
  }, []);

  const handleSetUsers = useCallback((users) => {
    dispatch(setUsers(users));
  }, []);

  const handleAddTeam = useCallback((team) => {
    dispatch(addTeam(team));
  }, []);

  const handleRemoveTeam = useCallback((id) => {
    dispatch(removeTeam(id));
  }, []);

  const handleSetTeams = useCallback((teams) => {
    dispatch(setTeams(teams));
  }, []);

  return {
    users: state.userList,
    setUsers: handleSetUsers,
    addedUsers: state.addedUsers,
    addUser: handleAddUser,
    removeUser: handleRemoveUser,
    teams: state.teamList,
    setTeams: handleSetTeams,
    addedTeams: state.addedTeams,
    addTeam: handleAddTeam,
    removeTeam: handleRemoveTeam,
  };
}
