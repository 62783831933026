import AddMilestoneButton from '@/components/common/Tasks/TaskSection/AddNewTaskButton';
import RapidTaskCreation from '@/components/common/Tasks/TaskSection/RapidTaskCreation';
import { CreateNewTaskModal } from '@/components/modals';
import { useModal } from '@/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileArrowUp } from '@fortawesome/sharp-regular-svg-icons';
import { useListActionContext } from './ListActions.context';

interface TaskPlaceholderProps {
  openCSVUploader: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hardwareId?: any;
}

export default function TaskPlaceholder (
  { openCSVUploader }: TaskPlaceholderProps,
): JSX.Element {
  const { hardwareId } = useListActionContext();
  const { showModal } = useModal();
  const milestoneTemplatePath = '/templates/default_driver_template.csv';

  return (
    <div className='flex flex-col gap-6 py-6 pt-6 w-full h-full'>
      <div className='flex flex-col gap-4 justify-between px-6 xl:flex-row'>
        <AddMilestoneButton hardwareId={hardwareId} />
        <div className='flex gap-4 justify-around items-center shrink'>
          <a
            className='flex gap-2 text-black cursor-pointer truncate'
            onClick={openCSVUploader}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openCSVUploader();
              }
            }}
          >
            <FontAwesomeIcon icon={faFileArrowUp} className='self-center shrink-0' />
            <div className='truncate'>Upload Template</div>
          </a>
          <a className='flex gap-2 text-black truncate' href={milestoneTemplatePath} download>
            <FontAwesomeIcon icon={faFileArrowDown} className='self-center shrink-0' />
            <div className='truncate'>Download Template</div>
          </a>
        </div>
      </div>
      <div className='flex'>
        <RapidTaskCreation />
      </div>
      <div className='flex justify-center items-center p-6 w-full h-full'>
        <div className='effra-12 text-black/60'>
          No drivers yet.{' '}
          <a
            className='cursor-pointer text-primary'
            onClick={() => {
              showModal(<CreateNewTaskModal hardwareId={hardwareId} />, {
                isMegaModal: true,
                showCloseIcon: false,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                showModal(<CreateNewTaskModal hardwareId={hardwareId} />, {
                  isMegaModal: true,
                  showCloseIcon: false,
                });
              }
            }}
          >
            Add one at a time{' '}
          </a>
          or{' '}
          <a
            className='cursor-pointer text-primary'
            onClick={openCSVUploader}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openCSVUploader();
              }
            }}
            aria-label='Upload CSV File'
          >
            bulk upload with a spreadsheet template
          </a>.
        </div>
      </div>
    </div>
  );
}
