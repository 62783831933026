import { User } from '@/components';
import DatePickerInput from '@/components/form/DatePickerInput/DatePickerInput';
import FormLabel from '@/components/FormLabel/FormLabel';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { GetModularObjectsByTemplateIdDocument } from '@/graphql/modularObject/getModularObjectsByTemplateId.generated';
import {
  GetTemplatesUsedByModularObjectsDocument,
} from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import getIcon from '@/util/getIcon';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import CardSection from '../ModularObject/Card v2/CardSection';
import ImageDisplay from '../ModularObject/Card v2/Header/HeaderMiddle/HeaderImage/ImageDisplay';
import CloseButton from '../ModularObject/Card v2/Header/HeaderTop/HeaderButtons/CloseButton';
import SaveButton from '../ModularObject/Card v2/Header/HeaderTop/SaveButton';
import { useCreateModularObjectMutation } from './CreateModularObject.generated';
import { useGetTemplateNameForNewObjectCreationQuery } from './GetTemplateNameForNewObjectCreation.generated';

const OBJECT_NAME_FIELD_NAME = 'name';
const START_DATE_FIELD_NAME = 'startDate';
const TARGET_DATE_FIELD_NAME = 'targetDate';

export const CreateNewObjectCard = () => {
  const [modularObjectName, setModularObjectName] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [targetDate, setTargetDate] = useState<Date | null>(null);

  const [createModularObject, { loading: isPendingObjectCreation }] = useCreateModularObjectMutation();
  const user = useLoggedInUser();
  const { closeObjectCardDrawer, newObjectConfig, openViewObjectCardDrawer } = useObjectCardContext();

  const { loading: isLoading, data } = useGetTemplateNameForNewObjectCreationQuery({
    variables: { input: { getOnlyUsedByModularObjects: false, byId: newObjectConfig.templateId } },
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.value;
    setModularObjectName(name);
  }, []);

  const template = useMemo(() => {
    if (!data?.getTemplates?.[0]) return null;

    return data?.getTemplates[0];
  }, [data?.getTemplates]);

  const handleSave = useCallback(async () => {
    const { data } = await createModularObject({
      variables: {
        input: { modularObjects: [{ name: modularObjectName, templateId: template.id, startDate, targetDate }] },
      },
      refetchQueries: [{ query: GetTemplatesUsedByModularObjectsDocument }, {
        query: GetModularObjectsByTemplateIdDocument,
        variables: { templateId: template.id },
      }, { query: GetTopLevelModularObjectsDocument }],
    });

    const newObject = data?.createModularObjects?.[0];

    if (newObjectConfig.afterObjectCreationSave) {
      return newObjectConfig.afterObjectCreationSave(newObject);
    }

    return openViewObjectCardDrawer({ modularObjectId: newObject.id });
  }, [
    createModularObject,
    modularObjectName,
    newObjectConfig,
    openViewObjectCardDrawer,
    template?.id,
    startDate,
    targetDate,
  ]);

  const isTask = template?.type === 'task';

  const placeHolder = isTask ? 'Enter driver name' : 'Enter build name';

  const shouldDisableSave = modularObjectName.length === 0 || !startDate || !targetDate;

  if (isLoading) {
    return null;
  }

  return (
    <div className='flex flex-col w-full'>
      <div className='flex flex-col gap-2 justify-center items-end w-full bg-white px-[20px] h-[80px]'>
        <div className='flex items-center gap-[16px]'>
          <User
            user={user}
            imageOnly
          />

          <div className='flex flex-col'>
            <SaveButton onClick={handleSave} disabled={shouldDisableSave} isSaving={isPendingObjectCreation} />
          </div>
          <CloseButton onClick={closeObjectCardDrawer} />
        </div>
      </div>

      <div className='bg-white p-[24px] pt-[16px]'>
        <div className='flex truncate label-uppercase text-neutral-500 ml-[123px]'>
          <FormLabel
            className='mb-[0]'
            label={pluralize.singular(template.name)}
            name={OBJECT_NAME_FIELD_NAME}
          />
        </div>

        <div className='flex gap-9'>
          <div className='border w-[88px]'>
            <ImageDisplay
              placeholderImage={getIcon(template?.name, 'w-[60%] text-[6rem]')}
            />
          </div>
          <div className='flex flex-col flex-1'>
            <textarea
              name={OBJECT_NAME_FIELD_NAME}
              className='py-2 px-3 font-bold border resize-none focus:border-b input placeholder-neutral-200 no-scrollbar font-effra grow text-wrap text-[24px] h-[88px] hover:border-b-black hover:border-neutral-200 focus:border-b-primary focus:border-neutral-200'
              autoComplete='off'
              onChange={handleChange}
              value={modularObjectName}
              placeholder={placeHolder}
            />
          </div>
        </div>
      </div>
      <div className='flex overflow-y-auto flex-col w-full grow'>
        <CardSection title='Details' className='pb-8 gap-[8px]'>
          <div className='flex items-center gap-[8px] px-[24px]'>
            <FormLabel name={START_DATE_FIELD_NAME} label='Start Date' className='w-[40%] max-w-[100px] label' />
            <DatePickerInput
              id={START_DATE_FIELD_NAME}
              endDate={targetDate}
              name={START_DATE_FIELD_NAME}
              value={startDate}
              onChange={date => {
                setStartDate(new Date(date));
              }}
              placeholder='Enter start date'
              className='max-w-[15rem]'
            />
          </div>
          <div className='flex items-center h-[28px] gap-[8px] px-[24px]'>
            <FormLabel name={TARGET_DATE_FIELD_NAME} label='Target Date' className='w-[40%] max-w-[100px] label' />
            <DatePickerInput
              id={TARGET_DATE_FIELD_NAME}
              startDate={startDate}
              value={targetDate}
              onChange={data => {
                setTargetDate(new Date(data));
              }}
              name={TARGET_DATE_FIELD_NAME}
              placeholder='Enter target date'
              className='max-w-[15rem]'
            />
          </div>
        </CardSection>
      </div>
    </div>
  );
};
