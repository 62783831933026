import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetModularObjectDependenciesByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input'];
}>;


export type GetModularObjectDependenciesByIdQuery = { __typename?: 'Query', getModularObjectByID: { __typename?: 'ModularObject', id: string, blockedBy: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, gapYears: number | null, gapMonths: number | null, gapDays: number | null, hasPendingApproval: boolean | null, blockedBy: { __typename?: 'ModularObject', id: string, name: string | null, startDateLocked: boolean | null, targetDateLocked: boolean | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null } | null }> | null, blocking: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, gapYears: number | null, gapMonths: number | null, gapDays: number | null, hasPendingApproval: boolean | null, modularObject: { __typename?: 'ModularObject', id: string, name: string | null, startDateLocked: boolean | null, targetDateLocked: boolean | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null } | null } | null }> | null } };


export const GetModularObjectDependenciesByIdDocument = gql`
    query getModularObjectDependenciesById($id: UUID!) {
  getModularObjectByID(id: $id) {
    id
    blockedBy {
      id
      modularObjectId
      blockedBy {
        id
        name
        startDateLocked
        targetDateLocked
        template {
          id
          name
        }
        owner(includeDeleted: true) {
          id
          organizationId
        }
      }
      blockedById
      blockType
      gapYears
      gapMonths
      gapDays
      hasPendingApproval
    }
    blocking {
      id
      modularObjectId
      modularObject {
        id
        name
        startDateLocked
        targetDateLocked
        owner(includeDeleted: true) {
          id
          organizationId
        }
        template {
          id
          name
        }
      }
      blockedById
      blockType
      gapYears
      gapMonths
      gapDays
      hasPendingApproval
    }
  }
}
    `;

/**
 * __useGetModularObjectDependenciesByIdQuery__
 *
 * To run a query within a React component, call `useGetModularObjectDependenciesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectDependenciesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectDependenciesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModularObjectDependenciesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>(GetModularObjectDependenciesByIdDocument, options);
      }
export function useGetModularObjectDependenciesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>(GetModularObjectDependenciesByIdDocument, options);
        }
export function useGetModularObjectDependenciesByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>(GetModularObjectDependenciesByIdDocument, options);
        }
export type GetModularObjectDependenciesByIdQueryHookResult = ReturnType<typeof useGetModularObjectDependenciesByIdQuery>;
export type GetModularObjectDependenciesByIdLazyQueryHookResult = ReturnType<typeof useGetModularObjectDependenciesByIdLazyQuery>;
export type GetModularObjectDependenciesByIdSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectDependenciesByIdSuspenseQuery>;
export type GetModularObjectDependenciesByIdQueryResult = Apollo.QueryResult<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectDependenciesByIdQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByID })
 *   )
 * })
 */
export const mockGetModularObjectDependenciesByIdQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>>[1]) =>
  graphql.query<GetModularObjectDependenciesByIdQuery, GetModularObjectDependenciesByIdQueryVariables>(
    'getModularObjectDependenciesById',
    resolver
  )
