import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useCallback } from 'react';
import { useDeleteCollaboratorMutation } from '../../CollaboratorDiscovery/mutations/deleteShares.generated';
import { GetModularObjectByIdDocument } from '../../ObjectSelectionModal/getModularObjectById.generated';

export function transformCollaboratorInput (selectedCollaborators) {
  return selectedCollaborators.map(({ user, role, externalId, externalType, id }) => ({
    id,
    userId: user?.id,
    user: {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      organizationId: user?.organizationId,
      profileImage: user?.profileImage,
    },
    role,
    externalId,
    externalType,
  }));
}

export function transformTeamShareInput (selectedTeamShares) {
  return selectedTeamShares.map(({ id }) => id);
}

export default function useRemoveCollaborators (selected) {
  const loggedInUser = useLoggedInUser();
  const [removeCollaborators, { error }] = useDeleteCollaboratorMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      deleteShares: selected.filter(({ __typename }) => __typename === 'Share'),
      deleteTeamShares: selected.filter(({ __typename }) => __typename === 'TeamShare'),
    },
    refetchQueries: [GetTopLevelModularObjectsDocument, GetModularObjectByIdDocument],
  });

  const selectedShares = selected.filter(({ __typename }) => __typename === 'Share');
  const selectedTeamShares = selected.filter(({ __typename }) => __typename === 'TeamShare');

  const isCurrentUserBeingRemoved = selectedShares.find(({ user }) => user.id === loggedInUser.id);

  const handleRemoveCollaborators = useCallback(async () => {
    return await removeCollaborators({
      variables: {
        input: {
          shares: transformCollaboratorInput(selectedShares),
          allowPartialSuccess: false,
        },
        teamShareIds: transformTeamShareInput(selectedTeamShares),
        allowPartialSuccess: false,
      },
      onQueryUpdated: (observableQuery) => {
        void observableQuery.refetch();
        // if (isCurrentUserBeingRemoved) dispatch(closeModularObjectModal());
      },
      refetchQueries: isCurrentUserBeingRemoved
        ? [GetTopLevelModularObjectsDocument, GetModularObjectByIdDocument]
        : [],
    });
  }, [removeCollaborators, selectedShares, selectedTeamShares, isCurrentUserBeingRemoved]);

  return { handleRemoveCollaborators, error };
}
