import { useMemo } from 'react';

export enum DropdownOption {
  Editor = 'Editor',
  Viewer = 'Viewer',
  Owner = 'Owner',
  Remove = 'Remove',
}

const { Editor, Viewer, Owner, Remove } = DropdownOption;

const iconMap = new Map();
iconMap.set(Editor, 'fa-sharp fa-solid fa-user-pen');
iconMap.set(Viewer, 'fa-sharp fa-solid fa-user-magnifying-glass');
iconMap.set(Owner, 'fa-sharp fa-solid fa-user-shield');
iconMap.set(Remove, 'fa-sharp fa-regular fa-xmark');

export default function useDropdownOptionMap (options: DropdownOption[], disableRemove?: boolean) {
  const dropDownOptions = useMemo(() => {
    return options.reduce((acc, option) => {
      if (disableRemove && option === Remove) {
        return acc;
      }

      acc.push({
        value: option,
        displayText: option,
        icon: iconMap.get(option),
      });

      return acc;
    }, []);
  }, [options, disableRemove]);

  return dropDownOptions;
}
