import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetUsersByEmailQueryVariables = Types.Exact<{
  emails: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type GetUsersByEmailQuery = { __typename?: 'Query', getUsersByEmail: Array<{ __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, fullName: string | null, role: Types.UserRoles | null, profileImage: string | null, organizationId: string | null, createdAt: any | null }> };


export const GetUsersByEmailDocument = gql`
    query GetUsersByEmail($emails: [String!]!) {
  getUsersByEmail(emails: $emails) {
    id
    email
    firstName
    lastName
    fullName
    role
    profileImage
    organizationId
    createdAt
  }
}
    `;

/**
 * __useGetUsersByEmailQuery__
 *
 * To run a query within a React component, call `useGetUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByEmailQuery({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useGetUsersByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, options);
      }
export function useGetUsersByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, options);
        }
export function useGetUsersByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, options);
        }
export type GetUsersByEmailQueryHookResult = ReturnType<typeof useGetUsersByEmailQuery>;
export type GetUsersByEmailLazyQueryHookResult = ReturnType<typeof useGetUsersByEmailLazyQuery>;
export type GetUsersByEmailSuspenseQueryHookResult = ReturnType<typeof useGetUsersByEmailSuspenseQuery>;
export type GetUsersByEmailQueryResult = Apollo.QueryResult<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUsersByEmailQuery((req, res, ctx) => {
 *   const { emails } = req.variables;
 *   return res(
 *     ctx.data({ getUsersByEmail })
 *   )
 * })
 */
export const mockGetUsersByEmailQuery = (resolver: Parameters<typeof graphql.query<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>>[1]) =>
  graphql.query<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(
    'GetUsersByEmail',
    resolver
  )
