import { apolloClientSideClient } from '@/apollo/apolloClient';
import { gql } from '@apollo/client';

export default function DependencyApprovalWarning ({ objectId, className }: { objectId: string; className?: string }) {
  const currentObject = apolloClientSideClient.readFragment({
    id: `ModularObject:${objectId}`,
    fragment: gql`
      fragment currentObject on ModularObject {
        approvalsEnabled
        template {
          name
        }
      }
    `,
  }) ?? {};

  const { approvalsEnabled = true } = currentObject;

  if (!approvalsEnabled) return null;

  return (
    <div className={`${className}`}>
      <div className='flex gap-2 items-center'>
        <i className='justify-self-start text-center fa-sharp fa-solid fa-triangle-exclamation text-warning w-[18px]' />
        <div className='text-center text-gray-60'>
          <p>Date changes made as a result of moving dependencies are not subject to approvals.</p>
        </div>
      </div>
    </div>
  );
}
