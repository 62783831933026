import DisplayValue from '@/components/modules/DisplayValue';
import { useObjectCardContext } from '@/state/ObjectCard.context';

export default function RequirementLevel () {
  const { objectCardData } = useObjectCardContext();
  const lVal = objectCardData?.lValue;

  return (
    <div className='flex relative items-center'>
      <div className='relative items-center'>
        <DisplayValue className='relative items-center' value={lVal?.toString()} />
      </div>
    </div>
  );
}
