import type { DiffType, ModularObject } from '@/__generated__/types';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { OnOffToggle } from '@/components/modals/CollaboratorDiscovery/AccessSettings/ToggleOption';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import StatusTooltip from '@/components/tooltip/StatusTooltip';
import { GetTemplatesUsedByModularObjectsDocument } from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/sharp-solid-svg-icons';
import { useLockObjectStartTargetDatesMutation } from './LockObjectStartTargetDates.generated';

export enum StartOrTargetDate {
  start = 'start',
  target = 'target',
}

interface StartTargetDateLockProps {
  isObjectOwner: boolean;
  startOrTarget: StartOrTargetDate;
  objectCardData: ModularObject;
}

export default function StartTargetDateLock (
  { objectCardData, startOrTarget, isObjectOwner }: StartTargetDateLockProps,
): JSX.Element {
  const [lockObjectStartTargetDates] = useLockObjectStartTargetDatesMutation();
  const { approvals } = objectCardData;

  const pendingApprovals = approvals?.filter((approval) => approval.status === 'pending').find(
    (approval) => approval.requestedChanges.find((change) => change?.field === `${startOrTarget}DateLocked`),
  );

  const diff = {
    externalID: objectCardData?.id,
    externalType: 'modular_object',
    diff: {
      to: {
        [`${startOrTarget}DateLocked`]: !objectCardData?.[`${startOrTarget}DateLocked`],
      },
      displayNames: { [`${startOrTarget}DateLocked`]: `${startOrTarget}DateLocked` },
    },
    fromMigration: false,
    diffType: 'updated' as DiffType,
  };

  const handleLockChange = async () => {
    await lockObjectStartTargetDates({
      variables: { input: { diffs: [diff] } },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument, GetTemplatesUsedByModularObjectsDocument],
      onCompleted: ({ updateModularObject }) => {
        const [{ approvals }] = updateModularObject ?? [{}];
        const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(approvals);
        const isSaved = !fieldsPendingApprovalMap.startDateLocked;
        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved date lock'
          : 'Successfully requested approval for date lock';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: () => {
        addToastError('Failed to update date lock, please try again');
      },
    });
  };

  return (
    <span
      className='flex gap-x-1 items-center'
      onClick={(e) => {
        if (!isObjectOwner) return;
        e.preventDefault();
        e.stopPropagation();
        void handleLockChange();
      }}
    >
      <span>
        {pendingApprovals && <StatusTooltip status='waiting for approval' />}
      </span>
      {<OnOffToggle
        identifier={`object-${startOrTarget}-date-lock`}
        enabled={objectCardData?.[`${startOrTarget}DateLocked`]}
        disabled={!isObjectOwner}
        onState={
          <span className='flex justify-center items-center h-full'>
            <FontAwesomeIcon icon={faLock} className='text-[8px]' />
          </span>
        }
        offState={
          <span className='flex justify-center items-center h-full'>
            <FontAwesomeIcon icon={faLockOpen} className='text-[8px]' />
          </span>
        }
      />}
    </span>
  );
}
