import { identity } from 'ramda';
import { CollabDiscoveryActionTypes } from './CollabDiscovery.actions';

export interface CollaboratorDiscoveryState {
  addedUsers: [];
  userList: [];
  addedTeams: [];
  teamList: [];
}

export const initialState: CollaboratorDiscoveryState = {
  addedUsers: [],
  userList: [],
  addedTeams: [],
  teamList: [],
};

const { SET_USERS, ADD_USER, REMOVE_USER, SET_TEAMS, ADD_TEAM, REMOVE_TEAM } = CollabDiscoveryActionTypes;

const handlers = {
  [SET_USERS]: (state, { users }) => ({
    ...state,
    addedUsers: users?.addedUsers ?? state.addedUsers,
    userList: users?.userList ?? state.userList,
  }),
  [ADD_USER]: (state, { user }) => {
    const isUserNameOrEmailInList = u =>
      Boolean(
        u.email?.toLowerCase().includes(user) || u.fullName?.toLowerCase().includes(user),
      );

    const userToAdd = state.userList.find(isUserNameOrEmailInList) ?? user;

    return {
      ...state,
      addedUsers: [...state.addedUsers, userToAdd],
      userList: state.userList.filter(user => user.id !== userToAdd?.id),
    };
  },
  [REMOVE_USER]: (state, { id }) => ({
    ...state,
    addedUsers: state.addedUsers.filter(user => user.id !== id),
    userList: [...state.userList, state.addedUsers.find(user => user.id === id)],
  }),
  [SET_TEAMS]: (state, { teams }) => ({
    ...state,
    addedTeams: teams?.addedTeams ?? state.addedTeams,
    teamList: teams?.teamList ?? state.teamList,
  }),
  [ADD_TEAM]: (state, { team }) => ({
    ...state,
    addedTeams: [...state.addedTeams, team],
    teamList: state.teamList.filter(t => t.id !== team.id),
  }),
  [REMOVE_TEAM]: (state, { id }) => ({
    ...state,
    addedTeams: state.addedTeams.filter(team => team.id !== id),
    teamList: [...state.teamList, state.addedTeams.find(team => team.id === id)],
  }),
};

function reducer (state: CollaboratorDiscoveryState, { type, payload }): CollaboratorDiscoveryState {
  return (handlers[type] ?? identity)(state, payload);
}

export default reducer;
