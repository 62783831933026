import { Button } from '@/components';
import ProBadge from '@/designSystemComponents/Badge/ProBadge';
import { useGetTemplatesByIdQuery } from '@/graphql/template/getTemplatesById.generated';
import { useLoggedInSubscription } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';
import DisabledObjectMessage from './tooltip/DisabledObjectMessage';
import Popover from './tooltip/Popover';

interface PageHeaderProps {
  title: string;
  customOnAddText?: string;
  onClearFilters?: () => void;
}

export function PageHeader (props: PageHeaderProps) {
  const { title, customOnAddText, onClearFilters } = props;
  const { query } = useRouter();
  const currentSubscription = useLoggedInSubscription();
  const templateId = query?.templateId as string;
  const { openCreateNewObjectCardDrawer } = useObjectCardContext();
  const { data: templateData } = useGetTemplatesByIdQuery({ variables: { templateId }, skip: !templateId });
  const template = templateData?.getTemplates?.[0];
  const isObjectCreationDisabled = template?.type === 'task'
    ? Boolean(currentSubscription?.featureLimits?.OBJECTS_DRIVERS)
    : Boolean(currentSubscription?.featureLimits?.OBJECTS_BUILDS);

  const handleOnAdd = () => {
    if (isObjectCreationDisabled) return;
    openCreateNewObjectCardDrawer({ templateId });
  };

  return (
    <div className='flex items-center justify-between px-6 py-5 border-b @4xl:px-20 bg-zinc-50 h-header shrink-0'>
      <section className='flex gap-10 items-end'>
        <h1>
          {title && `${title.split(' ').slice(0, -1).join(' ')} ${pluralize.plural(title.split(' ').slice(-1)[0])}`
            .trim()}
        </h1>
        {Boolean(onClearFilters) && (
          <button className='font-bold text-primary font-effra' onClick={onClearFilters}>
            <span className='mr-2'>Clear All Filters</span>
            <Image src='/images/refresh.svg' alt='Refresh' height={12} width={12} />
          </button>
        )}
      </section>
      <section>
        <Popover
          popoverContent={isObjectCreationDisabled && <DisabledObjectMessage />}
          xOffset={10}
          placement='bottom-end'
        >
          <span className='flex items-center gap-[8px]'>
            {isObjectCreationDisabled && <ProBadge shouldExpand />}
            <Button
              id={`add-new-${title?.toLowerCase()}`}
              dataTestId={`add-new-${title?.toLowerCase()}`}
              onClick={handleOnAdd}
              disabled={isObjectCreationDisabled}
              className={cx(
                'p-4 font-bold text-white uppercase effra-xs',
                {
                  'bg-gray-500 cursor-default': isObjectCreationDisabled,
                  'bg-secondary': !isObjectCreationDisabled,
                },
              )}
            >
              <div className='flex gap-2 items-center'>
                <Image src='/images/plus.svg' alt='Plus' height={12} width={12} />
                <div>{customOnAddText || 'Add New'}</div>
              </div>
            </Button>
          </span>
        </Popover>
      </section>
    </div>
  );
}
