import Color from '@/components/types/Color.enum';
import cx from 'classnames';
import type { PropsWithChildren } from 'react';

export interface BaseBadgeProps {
  color?: Color;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const backgroundColors = {
  [Color.PRIMARY]: 'bg-primary',
  [Color.SECONDARY]: 'bg-secondary',
  [Color.BLACK]: 'bg-black',
  [Color.PINK]: 'bg-pink',
};

export default function BaseBadge (
  { children, className = '', color = Color.PRIMARY, ...rest }: PropsWithChildren<BaseBadgeProps>,
) {
  return (
    <div
      className={cx(
        'text-white shadow-sm px-[4px] rounded-[4px]',
        backgroundColors[color],
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
