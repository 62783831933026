import type { Diff, FromTo, HardwareIntegration, ModularObject } from '@/__generated__/types';
import { type RequirementFields, TASK_SUBTYPE_TO_MODULE_MAP, type TaskFields } from './constants';
import type { ObjectCardSubTaskFragment } from './getObjectCardSubTasks.generated';

export function addNewTask (
  openNewTaskModal: () => void,
  objectId,
): void {
  const integrations = [];

  if (objectId) {
    const milestoneIntegration: HardwareIntegration = {
      parentId: objectId, // Set the integration parent to the object the button is for
      parentType: 'modular_object',
      objectType: 'modular_object', // objectId will be filled in on save
      isPending: true,
      asTask: true,
    } as HardwareIntegration & { asTask: true };
    integrations.push(milestoneIntegration);
  }

  openNewTaskModal();
}

export function shapeNewRequirement (task: Partial<RequirementFields>, visibility = 'Private') {
  const subType = 'requirement';
  const moduleIdToName = TASK_SUBTYPE_TO_MODULE_MAP[subType];
  const data = Object.entries(task || {})
    .reduce((acc, [key, value]) => {
      const moduleId = moduleIdToName[key];
      if (moduleId && value) {
        acc[moduleId] = { value };
      }
      return acc;
    }, {});

  const templateId = TASK_SUBTYPE_TO_MODULE_MAP[subType].subType;
  return {
    templateId,
    data,
    visibility,
    name: task?.name,
    assigneeId: task?.assigneeId || undefined,
  } as ModularObject;
}

export function shapeNewTask (task: Partial<TaskFields>, visibility = 'Private'): ModularObject {
  const subType = (task?.subType ?? 'milestone').toLowerCase();
  const moduleIdToName = TASK_SUBTYPE_TO_MODULE_MAP[subType];
  const data = Object.entries(task || {})
    .reduce((acc, [key, value]) => {
      const moduleId = moduleIdToName[key];
      if (moduleId && value) {
        acc[moduleId] = { value };
      }
      return acc;
    }, {});

  const templateId = TASK_SUBTYPE_TO_MODULE_MAP[subType].subType;
  return {
    templateId,
    data,
    visibility,
    name: task?.name,
    startDate: task?.startDate || undefined,
    targetDate: task?.targetDate || undefined,
    assigneeId: task?.assigneeId || undefined,
  } as ModularObject;
}

export function shapeNewMilestoneDiff (task: Partial<TaskFields>, externalID, externalType): Diff {
  const diff = Object.entries(task || {})
    .reduce<FromTo>((acc, [key, value]) => {
      const subType = (task?.subType ?? 'milestone').toLowerCase();
      const moduleIdToName = TASK_SUBTYPE_TO_MODULE_MAP[subType];
      const moduleId = moduleIdToName[key];
      if (moduleId) {
        return {
          ...acc,
          to: {
            ...acc.to,
            name: task?.name,
            startDate: task?.startDate,
            targetDate: task?.targetDate,
            assigneeId: task?.assigneeId,
            data: { ...acc.to.data, [moduleId]: value },
            displayNames: { data: { ...acc.displayNames.data, [moduleId]: key } },
          },
        };
      }
      return acc;
    }, {
      to: {
        name: '',
        startDate: '',
        targetDate: '',
        assigneeId: '',
        data: {},
      },
      displayNames: { data: {} },
      from: undefined,
    });

  return { diff, externalID, externalType } as Diff;
}

export function flattenTasks (
  tasks: ObjectCardSubTaskFragment[],
): ModularObject[] {
  const tasksToReturn = [];

  tasks.forEach((task) => {
    tasksToReturn.push(task);

    if (task?.tasks?.edges.length > 0) {
      // @ts-expect-error - fix this later
      const childTasks = flattenTasks(task.tasks?.edges.map(({ node }) => node));
      if (childTasks?.length) {
        tasksToReturn.push(...childTasks);
      }
    }
  });

  return tasksToReturn;
}
