import { type ModularObject, type User } from '@/__generated__/types';
import { getTheBandBackTogether } from '@/components/common/ModularObject/Card/ObjectCard.util';
import { useListActionContext } from '@/components/common/Tasks/TaskSection/ListActions.context';
import { Label } from '@/components/form';
import FormContainer from '@/components/FormContainer/FormContainer';
import Spinner from '@/components/Icons/Spinner';
import UserComponent from '@/components/User/User';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { type Diff } from '@/models/diff.model';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { addModularObjects } from '@/state/slices/modularObjects.slice';
import { addShares } from '@/state/slices/shares.slice';
import { patchModularObject, postShares, putShares } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { difference, union } from 'ramda';
import { useMemo, useState } from 'react';
import { ListAction, type PermissionLevel } from '../../../constants';
import { useUserSelectionContext } from '../../UserSelection.context';

const actionToField: Record<string, string> = {
  'Update Assignee': 'assignee',
  'Update Owner': 'owner',
};

export default function UserConfirmation (): JSX.Element {
  const { reFetchObservableQueries } = useApolloClient();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { modularObjects, templates } = useAppSelector(selectRootState);
  const { selectedObjects, selectedAction } = useListActionContext();
  const { selectedUser, onClose } = useUserSelectionContext();
  const currentUser = useLoggedInUser();

  const allTasks: ModularObject[] = Object.values(selectedObjects ?? {}).reduce(
    (acc, val) => acc.concat(...Object.values(val)),
    [],
  );

  const { isOwner, needsApproval, notAllowed } = useMemo((): Record<PermissionLevel, ModularObject[]> => {
    const ownerTasks = allTasks?.filter((task) => task?.ownerId === currentUser.id);

    const needsApprovalTasks = allTasks?.filter((task) => {
      if (ownerTasks.map(({ id }) => id).includes(task.id)) return false;

      const share = task?.shares?.find(share => share.userId === currentUser.id);
      if (share?.role === 'Editor' && selectedAction === ListAction.UPDATE_ASSIGNEE) {
        // Check if the selected user is external/internal
        const isInternalUser = selectedUser?.organizationId === currentUser.organizationId;
        if (isInternalUser) {
          // Updatable, approval needed
          return true;
        }

        // Check if the selected user is a collaborator
        const isCollaborator = task?.shares?.some(share => share.userId === selectedUser?.id);
        if (isCollaborator) {
          // Updatable, approval needed
          return true;
        }
      }
      return false;
    });

    const notAllowedTaskIds = difference(allTasks, union(ownerTasks, needsApprovalTasks));

    return {
      isOwner: ownerTasks,
      needsApproval: needsApprovalTasks,
      notAllowed: notAllowedTaskIds,
    };
  }, [allTasks, currentUser, modularObjects, selectedUser]);

  const handleSubmit = async (e): Promise<void> => {
    setIsSubmitting(true);
    try {
      e.preventDefault();
      e.stopPropagation();
      const fieldToUpdate = actionToField[selectedAction];
      let diffs;
      if (fieldToUpdate === 'assignee') {
        try {
          const newShares = [];
          const sharesToUpdate = [];
          union(isOwner, needsApproval).forEach((task) => {
            const existingShare = task?.shares?.find(share => share.userId === selectedUser?.id);
            if (existingShare && existingShare?.role !== 'Editor') {
              sharesToUpdate.push({ ...existingShare, role: 'Editor' });
            } else {
              const newShare = {
                externalId: task?.id,
                externalType: 'modular_object',
                userId: selectedUser?.id,
                user: selectedUser,
                role: 'Editor',
              };
              newShares.push(newShare);
            }
          });

          if (newShares?.length) {
            postShares(newShares).then((newSharesResponse) => {
              dispatch(addShares(newSharesResponse));
            }).catch((err) => {
              console.error(err);
            });
          }
          if (sharesToUpdate?.length) {
            putShares(sharesToUpdate).then((updatedSharesResponse) => {
              dispatch(addShares(updatedSharesResponse));
            }).catch((err) => {
              console.error(err);
            });
          }
        } catch (e) {} // ignore error for now

        diffs = union(isOwner, needsApproval).map(task => {
          return {
            diff: {
              displayNames: {
                assigneeId: 'assigneeId',
              },
              to: {
                assigneeId: selectedUser?.id,
              },
            },
            externalID: task?.id,
            externalType: 'modular_object',
          };
        });
      } else if (fieldToUpdate === 'owner') {
        const diff: Diff = {
          externalID: '',
          externalType: 'modular_object',
          diff: {
            to: { ownerId: selectedUser?.id },
          },
        };

        diffs = union(isOwner, needsApproval).map(task => ({
          ...diff,
          externalID: task?.id,
          externalType: 'modular_object',
        }));
      }

      const response = await patchModularObject(diffs);
      response.forEach((task) => {
        const taskTemplate = templates[task?.templateId];
        task.data = getTheBandBackTogether(taskTemplate.modules, task?.data);
      });
      dispatch(addModularObjects(response));
      onClose();
      reFetchObservableQueries().catch(console.error);
    } catch (err) {
      console.error(err);
      onClose();
    }
  };

  return (
    <FormContainer
      headerText={<span className='text-primary'>Are you sure?</span>}
      handleSubmit={handleSubmit}
      onClose={onClose}
      content={
        <div className='flex flex-col gap-3 mt-4 max-w-xl'>
          <div>
            <Label>Selected {actionToField[selectedAction]}</Label>
            <div className='p-4 border w-[250px]'>
              <UserComponent user={selectedUser as Partial<User>} />
            </div>
          </div>
          {Boolean(isOwner?.length) && (
            <div className='font-medium'>
              {isOwner.length} item{isOwner.length > 1 ? 's' : ''} will be updated immediately to this{' '}
              {actionToField[selectedAction]}.
            </div>
          )}
          {Boolean(needsApproval?.length) && (
            <div>
              <div className='font-medium'>
                You are not the owner of {needsApproval.length} driver{needsApproval.length > 1 ? 's' : ''}.
              </div>
              <div>
                If needed, requests to approve the change of assignee will be sent to the owners of these drivers.
              </div>
            </div>
          )}
          {Boolean(notAllowed?.length) && selectedAction === ListAction.UPDATE_ASSIGNEE && (
            <>
              <div className='font-medium'>
                You are attempting to change the driver assignee to an external user that is not yet a collaborator.
              </div>
              <div>
                You do not have permission to add an external user as a collaborator on {notAllowed.length}{' '}
                driver{notAllowed.length > 1 ? 's' : ''}. Please contact the owner of these drivers to make this change.
              </div>
            </>
          )}
          {Boolean(notAllowed?.length) && selectedAction === ListAction.UPDATE_OWNER && (
            <>
              <div className='font-medium text-pink'>
                You are not the owner of {notAllowed.length} item{notAllowed.length > 1 ? 's' : ''}.
              </div>
              <div>
                You do not have permission to change the owner of these items. Please contact the owner of these items
                to make this change.
              </div>
            </>
          )}
        </div>
      }
      buttons={
        <>
          <button onClick={onClose} className='w-1/2 btn btn-primary-hollow'>Cancel</button>
          <button className='w-1/2 btn-primary' disabled={isSubmitting}>
            {isSubmitting
              ? (
                <div className='flex flex-col items-center grow'>
                  <div className='flex gap-2 items-center'>
                    <Spinner className='w-6 h-6 animate-spin' /> Updating
                  </div>
                </div>
              )
              : 'Yes'}
          </button>
        </>
      }
    />
  );
}
