import { KeyboardEventNames } from '@/util/constants';
import { Children, createElement, isValidElement, type RefObject, useRef } from 'react';

interface InputWithPillsProps {
  children: React.ReactNode;
  className?: string;
  ref?: RefObject<HTMLDivElement>;
  inputRef?: RefObject<HTMLInputElement>;
  placeholder?: string;
  searchTerm: string;
  onSearch: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function InputWithPills (
  {
    children,
    className =
      'flex flex-wrap gap-2 items-center p-2 w-full bg-white border resize-none border-gray-80 focus:border-b-primary:outline-none',
    ref,
    inputRef,
    placeholder = 'Add people or emails...',
    searchTerm,
    onInputChange,
    onSearch,
    onInputFocus,
    onInputBlur,
  }: Readonly<InputWithPillsProps>,
): JSX.Element {
  const lastPillAddedRef = useRef(null);

  function handleInputKeydown (e) {
    if (
      e.key === KeyboardEventNames.Backspace && searchTerm === '' &&
      lastPillAddedRef.current !== document.activeElement
    ) {
      e.preventDefault();
      lastPillAddedRef.current?.focus();
    }

    if (e.key === KeyboardEventNames.Enter && searchTerm !== '') {
      onSearch(e);
      e.preventDefault();

      inputRef.current.focus();
    }
  }

  return (
    <div ref={ref} className={className}>
      {Children.map(children, (child, index) => {
        if (index === Children.count(children) - 1) {
          return isValidElement(child) ? createElement(child.type, { ...child.props, ref: lastPillAddedRef }) : child;
        }
        return child;
      })}
      <input
        type='text'
        ref={inputRef}
        placeholder={placeholder}
        className='bg-transparent focus:outline-none grow placeholder:text-neutral-400'
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        value={searchTerm}
        onChange={onInputChange}
        onKeyDown={handleInputKeydown}
      />
    </div>
  );
}
