import { useUpdateAttributeValue } from '@/hooks/useUpdateAttributeValue';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { RichText, type RichTextProps } from '../RichText/RichText';

interface HookFormInputProps extends React.ComponentProps<'textarea'> {
  name?: string;
  registration?: Record<string, unknown>;
  className?: string;
  disabled?: boolean;
  isEditable?: boolean;
  shouldShowToolbar?: boolean;
  onImageUpload?: (file: File) => Promise<string>;
}

export default function HookFormTextArea (
  { name, registration, className, placeholder, isEditable, shouldShowToolbar, onImageUpload }: HookFormInputProps,
): JSX.Element {
  const { register, setValue, getValues, getFieldState } = useFormContext();
  const { onChange: _, ...rest } = register(name, { ...(registration ?? {}) });
  const value = getValues(name);
  const { isDirty } = getFieldState(name);

  const content = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value ?? '';
    }
  }, [value]);

  const { updateValue } = useUpdateAttributeValue();

  const handleChange: RichTextProps['onChange'] = ({ editor }) => {
    const content = JSON.stringify(editor.getJSON());
    setValue(name, content, { shouldDirty: true });
  };

  const handleBlur = useCallback(async () => {
    if (!isDirty) return;
    await updateValue({ newFieldValue: value, attributeName: name });
  }, [isDirty, name, updateValue, value]);

  return (
    <div
      data-testid='hook-form-text-area-wrapper'
      className={cx(
        'flex flex-col  flex-1 [&>div]:flex [&>div]:flex-1 list-disc',
      )}
      id={name}
      {...rest}
    >
      <RichText
        shouldShowToolbar={shouldShowToolbar}
        content={content}
        isEditable={isEditable}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${className}`}
        onImageUpload={onImageUpload}
      />
    </div>
  );
}
