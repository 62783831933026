import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeleteTeamSharesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type DeleteTeamSharesMutation = { __typename?: 'Mutation', deleteTeamShares: Array<string> };


export const DeleteTeamSharesDocument = gql`
    mutation DeleteTeamShares($ids: [UUID!]!) {
  deleteTeamShares(ids: $ids)
}
    `;
export type DeleteTeamSharesMutationFn = Apollo.MutationFunction<DeleteTeamSharesMutation, DeleteTeamSharesMutationVariables>;

/**
 * __useDeleteTeamSharesMutation__
 *
 * To run a mutation, you first call `useDeleteTeamSharesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamSharesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamSharesMutation, { data, loading, error }] = useDeleteTeamSharesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTeamSharesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamSharesMutation, DeleteTeamSharesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamSharesMutation, DeleteTeamSharesMutationVariables>(DeleteTeamSharesDocument, options);
      }
export type DeleteTeamSharesMutationHookResult = ReturnType<typeof useDeleteTeamSharesMutation>;
export type DeleteTeamSharesMutationResult = Apollo.MutationResult<DeleteTeamSharesMutation>;
export type DeleteTeamSharesMutationOptions = Apollo.BaseMutationOptions<DeleteTeamSharesMutation, DeleteTeamSharesMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteTeamSharesMutation((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ deleteTeamShares })
 *   )
 * })
 */
export const mockDeleteTeamSharesMutation = (resolver: Parameters<typeof graphql.mutation<DeleteTeamSharesMutation, DeleteTeamSharesMutationVariables>>[1]) =>
  graphql.mutation<DeleteTeamSharesMutation, DeleteTeamSharesMutationVariables>(
    'DeleteTeamShares',
    resolver
  )
