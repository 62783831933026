import {
  useGetTemplatesUsedByModularObjectsQuery,
} from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import getIcon from '@/util/getIcon';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';
import { sort } from 'ramda';
import { useMemo } from 'react';
import SidenavSection from '../components/SidenavSection';
import SidenavTab from '../components/SidenavTab';

export default function ObjectTabs (): JSX.Element {
  const router = useRouter();
  const { data: templateData, loading, previousData: previousTemplateData } =
    useGetTemplatesUsedByModularObjectsQuery();
  const usedTemplates = loading ? previousTemplateData?.getTemplates : templateData?.getTemplates;

  const filteredTemplates = useMemo(
    () => usedTemplates?.filter((t) => t?.name && t?.id && !['task', 'driver'].includes(t?.type)) || [],
    [
      usedTemplates,
    ],
  );

  const activeTemplates = useMemo(() =>
    sort(
      (a, b) => a.name.localeCompare(b.name),
      filteredTemplates,
    ), [filteredTemplates]);

  return (
    <SidenavSection title='Builds'>
      {activeTemplates.map((template) => (
        <SidenavTab
          key={`nav-modular-object-${template.id}`}
          name={template?.name &&
            `${template.name.split(' ').slice(0, -1).join(' ')} ${
              pluralize.plural(template.name.split(' ').slice(-1)[0])
            }`
              .trim()}
          icon={getIcon(
            template?.name?.toLowerCase(),
            '@expanded/sidenav:text-[12px] @expanded/sidenav:height-[12px] text-[16px] height-[16px]',
          )}
          href={`/templates/${template.name}/${template.id}`}
          isActive={router.pathname === '/templates/[name]/[templateId]' && router?.query?.templateId === template.id}
        />
      ))}
    </SidenavSection>
  );
}
