import type { Link } from '@/__generated__/types';
import { useEditContext } from '@/components/cards';
import { GetModularObjectHistoryByIdDocument } from '@/components/cards/UpdatesColumn/getModularObjectDiffs.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { type FileModel, type UserModel } from '@/models';
import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { findShareById, findUserById } from '@/util/lookup.functions';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { useMemo } from 'react';
import { TIMELINE_FAREWELL, TIMELINE_GREETING } from '../constants';
import { type HistoryObject } from '../types';
import DiffEventHeader from './DiffEventHeader';
import File from './File';
import { useRevertDiffMutation } from './revertDiff.generated';
import TimelineChanges from './TimelineChanges';
import TimelineEventProvider from './TimelineEvent.context';
import TimelineLink from './TimelineLink';
import TimelinePoint from './TimelinePoint';
import { TimelineUndo } from './TimelineUndo';

interface TimelineEventProps {
  historyObject?: HistoryObject;
  isCurrent?: boolean;
}

export default function TimelineEvent ({ historyObject, isCurrent }: TimelineEventProps): JSX.Element {
  const [revertDiff] = useRevertDiffMutation();
  const { isOwner } = useEditContext();

  const state = useAppSelector(selectRootState);
  const currentUser = useLoggedInUser();

  const mapUserById = (id: string): UserModel => {
    if (!id) return null;
    return findUserById(id, state);
  };

  const mapFiles = (files: FileModel[]): React.ReactNode => {
    if (!files) return [];

    return files?.map((file) => {
      return <File key={file?.id} file={file} />;
    });
  };

  const mapLinks = (links: Link[]): React.ReactNode => {
    if (!links) return [];

    return links?.map((link) => {
      return <TimelineLink key={link?.id} link={link} />;
    });
  };

  const OBJECT_FIELDS = {
    modular_object: {
      addedFiles: mapFiles,
      removedFiles: mapFiles,
      addedLinks: mapLinks,
      removedLinks: mapLinks,
    },
  };

  const mapChange = (change, path) => {
    const changeMapper = get(OBJECT_FIELDS, path, null);
    if (typeof changeMapper === 'function') {
      return changeMapper(change);
    }
    return change?.value ?? change;
  };

  const getMappedChanges = (changes) => {
    return changes?.map((change) => {
      try {
        const changeType = change?.type;
        const fromValue = change?.from?.value ?? change?.from;
        if (
          (changeType.toLowerCase().includes('date') && !changeType.toLowerCase().includes('range')) ||
          changeType.toLowerCase().includes('launch')
        ) {
          return {
            ...change,
            type: changeType,
            from: fromValue ? dayjs(fromValue).format('MMM DD, YYYY') : null,
            to: change.to ? dayjs(change.to).format('MMM DD, YYYY') : null,
          };
        }
        return {
          ...change,
          type: changeType,
          from: mapChange(change.from, change.path),
          to: mapChange(change.to, change.path),
        };
      } catch (e) {
        console.info('Calling map function failed', change.path, e);
      }
      return change;
    });
  };

  const mappedHistoryObject = useMemo(() => {
    const owner = findUserById(historyObject.ownerID, state);
    const collaboratorsAdded = historyObject.collaboratorIdsAdded?.map((collaborator) => {
      return findShareById(historyObject.externalID, collaborator, state);
    });

    return {
      ...historyObject,
      owner,
      collaboratorsAdded,
      changes: getMappedChanges(historyObject.changes),
      requestedBy: mapUserById(historyObject.requestedById),
    };
  }, [historyObject, state]);

  const undoDiff = () => {
    return revertDiff({
      variables: {
        id: historyObject.id,
      },
      refetchQueries: [
        GetModularObjectHistoryByIdDocument,
        GetTopLevelModularObjectsDocument,
      ],
    });
  };

  const isUndoDisabled = historyObject?.action !== 'updated';

  const tooltipMessage = isUndoDisabled ?
    (historyObject?.action === 'dependencyUpdate' ?
      'This change was triggered by an update to a dependency.\n View the dependency to undo this change.' :
      'This action cannot be undone') :
    '';

  const shouldShowUndoButton = historyObject?.action !== 'created' && isCurrent && isOwner;

  return (
    <TimelineEventProvider currentUser={currentUser} historyObject={mappedHistoryObject}>
      <div className='flex justify-between'>
        <div className='flex flex-col text-black/40'>
          {/* If the first timeline event for an object */}
          {historyObject?.action === 'created' && <TimelinePoint noPadding>{TIMELINE_GREETING}</TimelinePoint>}
          <DiffEventHeader />
          <TimelineChanges />
          {isCurrent && <TimelinePoint>{TIMELINE_FAREWELL}</TimelinePoint>}
        </div>
        {shouldShowUndoButton && (
          <TimelineUndo undo={undoDiff} tooltipMessage={tooltipMessage} isDisabled={isUndoDisabled} />
        )}
      </div>
    </TimelineEventProvider>
  );
}
