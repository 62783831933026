interface InputPillProps {
  key: string;
  className: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  ref: React.RefObject<HTMLButtonElement>;
}

export function InputPill ({ key, className, onKeyDown, onClick, children, ref }: InputPillProps) {
  return (
    <button
      key={key}
      ref={ref}
      className={className}
      onKeyDown={onKeyDown}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
