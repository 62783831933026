import type { Link as LinkType } from '@/__generated__/types';
import Link from 'next/link';

interface FileProps {
  link: LinkType;
}

export default function TimelineLink ({ link }: FileProps): JSX.Element {
  return (
    <div key={link?.id}>
      <Link
        href={link?.url}
        className='text-primary'
        target='_blank'
      >
        {link?.title || link?.url}
      </Link>
    </div>
  );
}
