import { useLoggedInUser } from '@/hooks/useLoggedInUser';

interface DisabledObjectMessageProps {
  objectId?: string;
  organizationId?: string;
}

export default function DisabledObjectMessage ({ objectId, organizationId }: DisabledObjectMessageProps): JSX.Element {
  const currentUser = useLoggedInUser();

  const isInternal = objectId && organizationId === currentUser?.organizationId;

  return (
    <div className='flex gap-2 items-start p-4 bg-white border border-gray-300 shadow-md w-[300px] min-h-[80px] h-fit'>
      <div className='text-center text-black'>
        {isInternal
          ? (
            <>
              <div className='font-effra'>Build or Driver Limit Reached</div>
              <div>
                Your plan has used all of the builds or drivers available. Please upgrade to create more build or
                drivers.
              </div>
            </>
          ) :
          (
            <>
              <div className='font-effra'>Build or Driver Deactivated</div>
              <div>
                This build or driver has been deactivated because the owner has too many builds or drivers for their
                plan. Ask them to upgrade to collaborate.
              </div>
            </>
          )}
      </div>
    </div>
  );
}
