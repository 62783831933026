import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetObjectCardSubTasksQueryVariables = Types.Exact<{
  modularObjectId: Types.Scalars['UUID']['input'];
  nameContains: Types.InputMaybe<Types.Scalars['String']['input']>;
  pageInfoInput: Types.InputMaybe<Types.TaskPageInfoInput>;
}>;


export type GetObjectCardSubTasksQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, tasks: { __typename?: 'TaskConnections', pageInfo: { __typename?: 'TaskPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, pageCount: number, totalTasks: number }, edges: Array<{ __typename?: 'TaskEdge', node: { __typename?: 'ModularObject', id: string, assigneeId: string | null, data: any | null, name: string | null, ownerId: string | null, startDate: any | null, status: Types.TaskStatus | null, statusModuleId: string | null, targetDate: any | null, assignee: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null, tasks: { __typename?: 'TaskConnections', edges: Array<{ __typename?: 'TaskEdge', node: { __typename?: 'ModularObject', id: string, assigneeId: string | null, data: any | null, name: string | null, ownerId: string | null, startDate: any | null, status: Types.TaskStatus | null, statusModuleId: string | null, targetDate: any | null, parent: { __typename?: 'ModularObject', name: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, modules: any | null, name: string | null, subType: Types.TemplateSubTypes | null } | null } }> } | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, modules: any | null, name: string | null } | null } }> } | null }> | null };

export type ObjectCardSubTaskFragment = { __typename?: 'ModularObject', id: string, assigneeId: string | null, data: any | null, name: string | null, ownerId: string | null, startDate: any | null, status: Types.TaskStatus | null, statusModuleId: string | null, targetDate: any | null, assignee: { __typename?: 'User', id: string, email: string | null, firstName: string | null, lastName: string | null, profileImage: string | null, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null, tasks: { __typename?: 'TaskConnections', edges: Array<{ __typename?: 'TaskEdge', node: { __typename?: 'ModularObject', id: string, assigneeId: string | null, data: any | null, name: string | null, ownerId: string | null, startDate: any | null, status: Types.TaskStatus | null, statusModuleId: string | null, targetDate: any | null, parent: { __typename?: 'ModularObject', name: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, modules: any | null, name: string | null, subType: Types.TemplateSubTypes | null } | null } }> } | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, modules: any | null, name: string | null } | null };

export const ObjectCardSubTaskFragmentDoc = gql`
    fragment ObjectCardSubTask on ModularObject {
  id
  assigneeId
  assignee {
    id
    email
    firstName
    lastName
    profileImage
    organizationId
  }
  data
  name
  ownerId
  parent {
    id
    name
  }
  startDate
  status
  statusModuleId
  targetDate
  shares {
    id
    userId
    role
  }
  tasks {
    edges {
      node {
        id
        assigneeId
        data
        name
        ownerId
        parent {
          name
        }
        startDate
        status
        statusModuleId
        targetDate
        shares {
          id
          userId
          role
        }
        template {
          id
          type
          modules
          name
          subType
        }
      }
    }
  }
  template {
    id
    type
    modules
    name
  }
}
    `;
export const GetObjectCardSubTasksDocument = gql`
    query GetObjectCardSubTasks($modularObjectId: UUID!, $nameContains: String, $pageInfoInput: TaskPageInfoInput) {
  getModularObjectByIDs(ids: [$modularObjectId]) {
    id
    tasks(
      input: {includeTaskRollup: true, nameContains: $nameContains, pageInfo: $pageInfoInput}
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        totalTasks
      }
      edges {
        node {
          ...ObjectCardSubTask
        }
      }
    }
  }
}
    ${ObjectCardSubTaskFragmentDoc}`;

/**
 * __useGetObjectCardSubTasksQuery__
 *
 * To run a query within a React component, call `useGetObjectCardSubTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectCardSubTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectCardSubTasksQuery({
 *   variables: {
 *      modularObjectId: // value for 'modularObjectId'
 *      nameContains: // value for 'nameContains'
 *      pageInfoInput: // value for 'pageInfoInput'
 *   },
 * });
 */
export function useGetObjectCardSubTasksQuery(baseOptions: Apollo.QueryHookOptions<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>(GetObjectCardSubTasksDocument, options);
      }
export function useGetObjectCardSubTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>(GetObjectCardSubTasksDocument, options);
        }
export function useGetObjectCardSubTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>(GetObjectCardSubTasksDocument, options);
        }
export type GetObjectCardSubTasksQueryHookResult = ReturnType<typeof useGetObjectCardSubTasksQuery>;
export type GetObjectCardSubTasksLazyQueryHookResult = ReturnType<typeof useGetObjectCardSubTasksLazyQuery>;
export type GetObjectCardSubTasksSuspenseQueryHookResult = ReturnType<typeof useGetObjectCardSubTasksSuspenseQuery>;
export type GetObjectCardSubTasksQueryResult = Apollo.QueryResult<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetObjectCardSubTasksQuery((req, res, ctx) => {
 *   const { modularObjectId, nameContains, pageInfoInput } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetObjectCardSubTasksQuery = (resolver: Parameters<typeof graphql.query<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>>[1]) =>
  graphql.query<GetObjectCardSubTasksQuery, GetObjectCardSubTasksQueryVariables>(
    'GetObjectCardSubTasks',
    resolver
  )
