import classNames from 'classnames';
import pluralize from 'pluralize';
import { useState } from 'react';

import { Button } from '@/components/Button/Button';
import ProBadge from '@/designSystemComponents/Badge/ProBadge';
import { useLoggedInSubscription } from '@/hooks/useLoggedInUser';

export interface CreateNewObjectListRowProps {
  icon: JSX.Element;
  label: string;
  onCreateClick?: () => void;
  onPreviewClick?: () => void;
}

export default function CreateNewObjectListRow ({
  icon,
  label,
  onCreateClick,
  onPreviewClick,
}: Readonly<CreateNewObjectListRowProps>): JSX.Element {
  const subscription = useLoggedInSubscription();
  const [hovered, setHovered] = useState(false);

  const shouldRestrictRequirements = subscription?.type === 'basic';
  const shouldRestrictThisRow = shouldRestrictRequirements && label.toLowerCase().includes('requirement');

  return (
    <div
      className='flex justify-between p-2 rounded-md hover:bg-gray-100'
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
      onFocus={() => {
        setHovered(true);
      }}
      onBlur={() => {
        setHovered(false);
      }}
    >
      <div className='flex gap-4 items-center'>
        {icon}
        <div className='text-lg font-effra'>
          {pluralize.singular(label)}
        </div>
      </div>
      <div className='flex gap-4 items-center'>
        {
          // Integrate team indicated this feature was removed from this epic, however it wasn't removed in the
          // Linear issue so I built it on accident, sorry!
          // hovered && <Button
          //   className='py-3 px-4 text-gray-700 border-none hover:border-none btn-ghost h-[unset] w-[unset]'
          //   innerClassName='gap-1 leading-3'
          //   onClick={onPreviewClick}>
          //     <FontAwesomeIcon icon={faEye} className='self-center shrink-0 mt-[-2px]' />
          //     Preview Template
          // </Button>
        }
        {shouldRestrictThisRow && <ProBadge shouldExpand />}
        <Button
          className={classNames('h-[unset] w-[unset] px-4 py-3', {
            'btn-ghost': !hovered,
            'btn-primary': hovered,
            'border-primary': hovered,
            border: hovered,
            'cursor-not-allowed': shouldRestrictThisRow,
          })}
          onClick={onCreateClick}
          disabled={shouldRestrictThisRow}
        >
          Create New {pluralize.singular(label)}
        </Button>
      </div>
    </div>
  );
}
