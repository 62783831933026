import type { Document } from '@/__generated__/types';
import { useEditContext } from '@/components/cards';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import StatusTooltip from '@/components/tooltip/StatusTooltip';
import UserComponent from '@/components/User/User';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { downloadFile } from '@/util/downloadFile';
import getIcon from '@/util/getIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToBracket, faArrowUpRightFromSquare } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useCallback, useMemo, useRef } from 'react';
import { GetObjectCardObjectDocument } from '../../Card/GetObjectCardObject.generated';
import { buildUpdateObjectDiffPayloadForDocument } from '../../utils';
import { ConfirmDeleteFileModal } from '../ConfirmDeleteFileModal';
import { useDeleteFilesMutation } from '../graphql/deleteFiles.generated';
import { useDeleteLinksMutation } from '../graphql/deleteLinks.generated';
import { useRemoveFileFromObjectMutation } from '../RemoveFileFromObject.generated';
import DocumentRowIcon from './DocumentRowIcon';

interface DocumentRowProps {
  document: Document;
  isPendingApproval?: boolean;
}

export default function DocumentRow ({ document, isPendingApproval }: DocumentRowProps) {
  const downloaderRef = useRef<HTMLAnchorElement>();

  const loggedInUser = useLoggedInUser();
  const { objectCardData } = useObjectCardContext();
  const { canUserEdit } = useEditContext();
  const { showModal, closeModal } = useModal();

  const [deleteFiles] = useDeleteFilesMutation();
  const [deleteLinks] = useDeleteLinksMutation();

  const [removeFileFromObject] = useRemoveFileFromObjectMutation();

  const removeDocument = useCallback(async () => {
    try {
      if (document.type === 'file') {
        await deleteFiles({
          variables: {
            input: [document?.externalId],
          },
        });
      } else {
        await deleteLinks({
          variables: {
            ids: [document?.externalId],
          },
          refetchQueries: [GetObjectCardObjectDocument],
          onCompleted: () => {
            addToastSuccess(`Successfully removed ${document.type}`);
          },
          onError: () => {
            addToastError(`Failed to remove ${document.type}, please try again`);
          },
        });
      }
    } catch (e) {
      throw new Error(e);
    }

    const payloadType = document.type === 'file' ? 'removedFiles' : 'removedLinks';

    if (document.type === 'file') {
      try {
        await removeFileFromObject({
          variables: {
            input: {
              diffs: [buildUpdateObjectDiffPayloadForDocument({
                objectId: objectCardData.id,
                document,
                payloadType,
              })],
            },
          },
          refetchQueries: [GetObjectCardObjectDocument],
          onCompleted: async (data) => {
            const latestFiles = data.updateModularObject[0].documents ?? [];

            const isSaved = !latestFiles.some((f) => f.id === document.id);

            const SUCCESS_MESSAGE = isSaved
              ? `Successfully removed ${document.type}`
              : `Successfully requested approval for removing ${document.type}`;

            addToastSuccess(SUCCESS_MESSAGE);
          },
          onError: () => {
            addToastError(`Failed to remove ${document.type}, please try again`);
          },
        });
      } catch (err) {
        console.error(err);
        addToastError(err.error ?? err);
      }
    }
  }, [document, objectCardData.id, removeFileFromObject, deleteFiles, deleteLinks]);

  const handleDownloadClick = useCallback(async (e) => {
    e.preventDefault();
    try {
      await downloadFile(document, downloaderRef);
    } catch (err) {
      addToastError(`Error downloading file: ${err.message as string}`);
    }
  }, [document]);

  const handleRemoveClick = useCallback(() => {
    showModal(
      <ConfirmDeleteFileModal
        closeModal={closeModal}
        handleConfirm={removeDocument}
        fileName={document.name || document.url}
      />,
    );
  }, [closeModal, document.name, document.url, removeDocument, showModal]);

  const fileName = useMemo(() => {
    return !document.name ? document.url : document.name;
  }, [document.name, document.url]);

  const documentTypeIcon = document.type === 'file' ? faArrowDownToBracket : faArrowUpRightFromSquare;

  const linkAttributes = useMemo(() =>
    document.type === 'file' ?
      {
        href: '',
        onClick: handleDownloadClick,
      } :
      {
        href: document.url,
        target: '_blank',
      }, [document.type, document.url, handleDownloadClick]);

  return (
    <div
      data-testid='document-row'
      id={document.id}
      className='flex items-center transition-colors group px-[24px] h-[40px]'
    >
      <div className='flex justify-end basis-[58px]'>
        {!isPendingApproval ?
          (
            <Link {...linkAttributes} className='hover:opacity-60 pr-[16px]'>
              <FontAwesomeIcon icon={documentTypeIcon} />
            </Link>
          ) :
          (
            <div className='pr-[15px]'>
              <StatusTooltip
                status={loggedInUser?.id === objectCardData?.createdById ? 'for review' : 'waiting for approval'}
              />
            </div>
          )}
      </div>
      <div className={cx('flex flex-[3_0_0%] gap-[6px] min-w-0', { 'opacity-40': isPendingApproval })}>
        <div className='flex justify-center items-center basis-[14px] min-w-[14px]'>
          <DocumentRowIcon document={document} />
        </div>
        <div className='truncate pr-[16px] shrink-1'>
          {fileName}
        </div>
      </div>
      <div
        className={cx('flex flex-[2_0_0%] capitalize gap-[6px] items-center min-w-0', {
          'opacity-40': isPendingApproval,
        })}
      >
        {getIcon(document.parentType.toLowerCase())}
        <div className='truncate pr-[16px]'>
          {document.parentName}
        </div>
      </div>
      <div className={cx('flex-[2_0_0%] min-w-0 capitalize', { 'opacity-40': isPendingApproval })}>
        {dayjs(document.createdAt).format('MMM D, YYYY')}
      </div>
      <div className='flex flex-[2_0_0%] min-w-0'>
        <UserComponent user={document.createdBy} noUserText='Unknown User' />
      </div>
      {canUserEdit && !isPendingApproval ?
        (
          <button
            data-testid='remove-file'
            type='button'
            onClick={handleRemoveClick}
            className='w-4 opacity-0 transition-opacity group-hover:opacity-100 link text-zinc-500'
          >
            <i className='fa-sharp fa-regular fa-xmark' />
          </button>
        ) :
        <div className='w-4' />}
      <a ref={downloaderRef} className='hidden' />
    </div>
  );
}
