import { type MutableRefObject, useEffect, useState } from 'react';

export enum ScrollDirection {
  up = 'up',
  down = 'down',
}

interface UseScrollDirectionProps {
  el: MutableRefObject<HTMLElement>;
  threshold?: number;
}

const useScrollDirection = ({ el, threshold = 100 }: UseScrollDirectionProps): ScrollDirection => {
  const [scrollDir, setScrollDir] = useState(ScrollDirection.up);

  useEffect(() => {
    let previousScrollYPosition = el?.current?.scrollTop || 0;

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

    const isScrollingUp = (currentScrollYPosition: number) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

    const updateScrollDirection = (e) => {
      const currentScrollYPosition = e.target?.scrollTop;

      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
          ? ScrollDirection.down
          : ScrollDirection.up;

        setScrollDir(newScrollDirection);
        previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
      }
    };

    const onScroll = (e) => window.requestAnimationFrame(() => updateScrollDirection(e));

    el?.current?.addEventListener('scroll', onScroll);

    return () => el?.current?.removeEventListener('scroll', onScroll);
  }, [el]);

  return scrollDir;
};

export default useScrollDirection;
