import { API_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { ApolloLink, HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getCookie } from 'cookies-next';
import { createClient, type WebSocket } from 'graphql-ws';
import { v4 as uuidv4 } from 'uuid';

// Utilize memoization for headers
let cachedHeaders: Headers | null = null;
let lastToken: string | null = null;
let lastImpersonationEmail: string | null = null;

function generateHeaders (headers: Headers = new Headers()) {
  const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
  const impersonationEmail = typeof window !== 'undefined' ? localStorage.getItem('impersonation') : null;

  // Check if headers can be reused
  if (cachedHeaders && token === lastToken && impersonationEmail === lastImpersonationEmail) {
    return cachedHeaders;
  }

  headers = new Headers();

  if (impersonationEmail) {
    headers.set('x-impersonate', impersonationEmail);
  }

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  // Cache the headers and their dependencies
  cachedHeaders = headers;
  lastToken = token;
  lastImpersonationEmail = impersonationEmail;

  return headers;
}

// Define a utility to throttle logs
let lastLogTime = 0;
const LOG_INTERVAL = 1000; // 1 second

function throttleLog (message: string, data?: unknown) {
  const now = Date.now();
  if (now - lastLogTime > LOG_INTERVAL) {
    console.info(message, data);
    lastLogTime = now;
  }
}

export const apolloLink = new HttpLink({
  uri: `/graphql`,
  credentials: 'include',
  fetch: (uri, options) => {
    if (!options.headers) {
      options.headers = {};
    }
    generateHeaders().forEach((value, key) => {
      options.headers[key] = value;
    });

    return fetch(`${API_URL()}${uri}`, options);
  },
});

export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    if (!headers) {
      headers = {};
    }
    generateHeaders().forEach((value, key) => {
      headers[key] = value;
    });
    return {
      headers,
    };
  });

  return forward(operation);
});

const clientId = uuidv4();

export const subscriptionLink = new GraphQLWsLink(createClient({
  url: `${env('API_WS_PROTOCOL')}://${env('NEXT_PUBLIC_API_DOMAIN')}/graphql`,
  shouldRetry: () => true,
  retryAttempts: 3,
  retryWait: (retries) => {
    return new Promise((resolve) => setTimeout(resolve, retries * 1000));
  },
  connectionParams: {
    headers: generateHeaders(),
    'client-x-id': clientId,
  },
  on: {
    closed: () => {
      if (typeof window === 'undefined') return;
      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) return;
      throttleLog('[websocket] connection closed');
    },
    opened: () => {
      if (typeof window === 'undefined') return;
      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) return;
      throttleLog('[websocket] connection opened');
    },
    connected: async (socket: WebSocket) => {
      if (typeof window === 'undefined') return;
      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) {
        await socket.close(4205, 'Client Restart');
        return;
      }
      throttleLog('[websocket] connection established');
    },
    connecting: () => {
      if (typeof window === 'undefined') return;
      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) return;
      throttleLog('[websocket] connecting');
    },
    error: (error) => {
      if (typeof window === 'undefined') return;
      throttleLog('[websocket] error', error);
    },
    message: (message) => {
      if (message.type !== 'next' || typeof window === 'undefined') return;
      throttleLog('[websocket] message', message);
    },
  },
}));

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  subscriptionLink,
  apolloLink,
);
