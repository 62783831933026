import { useGetLoggedInUserQuery } from '@/state/getLoggedInUser.generated';
import { ADMIN_ROLE, MANAGER_ROLE, SUPER_ROLE, USER_ROLE } from '@/util/constants';
import { env } from '@/util/env.utils';
import { getCookie } from 'cookies-next';

export const useLoggedInUser = () => {
  const jwt = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
  const { data } = useGetLoggedInUserQuery({ skip: !jwt });

  return data?.getUser?.user;
};

export const useLoggedInSubscription = () => {
  const jwt = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
  const { data } = useGetLoggedInUserQuery({ skip: !jwt });

  return data?.getUser?.subscription;
};

export const useLoggedInUserRole = () => {
  const user = useLoggedInUser();

  return {
    isSuper: user?.role === SUPER_ROLE,
    isAdmin: user?.role === ADMIN_ROLE,
    isManager: user?.role === MANAGER_ROLE,
    isUser: user?.role === USER_ROLE,
  };
};
