import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type TeamMembersQueryVariables = Types.Exact<{
  input: Types.TeamMembersInput;
}>;


export type TeamMembersQuery = { __typename?: 'Query', teamMembers: { __typename?: 'TeamMemberConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null }, edges: Array<{ __typename?: 'TeamMember', id: string, createdAt: any, user: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null, email: string | null, title: string | null, profileImage: string | null, organization: { __typename?: 'Organization', id: string, name: string | null } | null } } | null> | null } };


export const TeamMembersDocument = gql`
    query TeamMembers($input: TeamMembersInput!) {
  teamMembers(input: $input) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      id
      createdAt
      user {
        id
        firstName
        lastName
        email
        title
        organization {
          id
          name
        }
        profileImage
      }
    }
  }
}
    `;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
      }
export function useTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export function useTeamMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersSuspenseQueryHookResult = ReturnType<typeof useTeamMembersSuspenseQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTeamMembersQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ teamMembers })
 *   )
 * })
 */
export const mockTeamMembersQuery = (resolver: Parameters<typeof graphql.query<TeamMembersQuery, TeamMembersQueryVariables>>[1]) =>
  graphql.query<TeamMembersQuery, TeamMembersQueryVariables>(
    'TeamMembers',
    resolver
  )
