import { MILESTONE_TEMPLATE_ID } from '@/components/common/Tasks/TaskSection/constants';
import RapidTaskCreation from '@/components/common/Tasks/TaskSection/RapidTaskCreation';
import { OffSetPagination } from '@/components/OffSetPagination/OffSetPagination';
import { PageSizeSelectionDropdown } from '@/components/OffSetPagination/PageSizeSelectionDropdown';
import { useOffSetPagination } from '@/components/OffSetPagination/useOffSetPagination';
import { useAppDispatch } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { setModularObjectModal } from '@/state/slices/session.slice';
import { createAndDownloadFile } from '@/util/downloadFile';
import { getModuleByName } from '@/util/lookup.functions';
import cx from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { getTheBandBackTogether } from '../../ModularObject/Card/ObjectCard.util';
import { useGetObjectCardSubTasksLazyQuery, useGetObjectCardSubTasksQuery } from './getObjectCardSubTasks.generated';
import HeaderName from './HeaderName';
import { useListActionContext } from './ListActions.context';
import LoadingSpinner from './LoadingSpinner';
import TaskColumnHeader from './TaskColumnHeader';
import TaskList from './TaskList';
import { flattenTasks } from './utils';

interface TaskSectionContentProps {
  openCSVUploader: () => void;
}

export default function TaskSectionContent ({ openCSVUploader }: TaskSectionContentProps): JSX.Element {
  const {
    objectCardData,
    activeModularObjectId,
  } = useObjectCardContext();

  const hasSubtasks = objectCardData.tasks?.edges.length > 0;
  const { selectedObjects, setSelectedObjects, setDefaultSelectedObjects } = useListActionContext();
  const dispatch = useAppDispatch();
  const [filterTerm, setFilterTerm] = useState<string>(null);
  const [parentFilter, setParentFilter] = useState<string>(null);
  const [startDateFilter, setStartDateFilter] = useState<string>(null);
  const [targetDateFilter, setTargetDateFilter] = useState<string>(null);
  const { onPageChange, selectedPage, onPageSizeChange, selectedPageSize } = useOffSetPagination({});
  const [getSubTasks] = useGetObjectCardSubTasksLazyQuery();
  const { data, loading } = useGetObjectCardSubTasksQuery({
    variables: {
      modularObjectId: activeModularObjectId,
      nameContains: filterTerm,
      pageInfoInput: {
        pageSize: selectedPageSize,
        selectedPage,
      },
    },
  });

  const handleFilterTermChange = useCallback(
    debounce((filterString: string) => {
      setFilterTerm(filterString);
    }, 1000),
    [],
  );

  const tasks = useMemo(() => {
    return data?.getModularObjectByIDs?.[0]?.tasks?.edges.map(({ node }) => node) ?? [];
  }, [data?.getModularObjectByIDs]);
  const pageInfo = data?.getModularObjectByIDs?.[0]?.tasks?.pageInfo;

  const handleCheckAll = (event): void => {
    const { checked } = event.target;
    if (checked) {
      const allTasksMap = tasks.reduce((acc, task) => {
        acc[task.id] = task;
        return acc;
      }, {});

      setSelectedObjects({
        ...selectedObjects,
        tasks: allTasksMap,
      });
    } else {
      setDefaultSelectedObjects();
    }
  };

  const exportTasks = async (): Promise<void> => {
    const csvHeaders = 'Name,Type,Parent Name,Start Date,Target Date,Status,Shall Statement\n';
    const { data: getSubTasksData } = await getSubTasks({
      variables: {
        modularObjectId: activeModularObjectId,
        nameContains: null,
        pageInfoInput: {
          pageSize: data?.getModularObjectByIDs?.[0]?.tasks?.pageInfo?.totalTasks,
          selectedPage: 1,
        },
      },
    });

    const allTasks = getSubTasksData?.getModularObjectByIDs?.[0]?.tasks?.edges.map(({ node }) => node) ?? [];
    const flattenedTasks = flattenTasks(allTasks);

    const csvContent = flattenedTasks?.map((task) => {
      const status = task?.status;
      const shallStatement = getModuleByName(
        getTheBandBackTogether(task?.template?.modules, task?.data),
        'shallStatement',
      )?.value;

      let shallStatementParsed = '';

      try {
        if (shallStatement) {
          shallStatementParsed = JSON.parse(shallStatement).content.map((c) =>
            c?.content?.map((cc) => cc?.text ?? '').join(' ')
          ).join('\n');
        }
      } catch (e) {
        shallStatementParsed = shallStatement;
      }

      const row = [
        task.name,
        task?.template?.name ?? '',
        task?.parent?.name ?? '',
        task.startDate ?
          new Date(task.startDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }) :
          '',
        task.targetDate ?
          new Date(task.targetDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }) :
          '',
        status ?? '',
        shallStatementParsed ?? '',
      ];

      return row.map(field => (`"${field}"`)).join(',');
    }).join('\n');

    createAndDownloadFile(csvHeaders + csvContent, 'text/csv', 'task-export.csv');
  };

  return (
    <>
      <TaskColumnHeader
        setFilterTerm={handleFilterTermChange}
        handleExportTasks={exportTasks}
        openCSVUploader={openCSVUploader}
      />
      <div
        className={cx('flex items-start gap-3 py-3 border-b border-neutral-200 @container', {
          'pl-[41px] pr-6': hasSubtasks,
          'px-6': !hasSubtasks,
        })}
      >
        <HeaderName
          className='flex shrink'
          text={
            <input
              type='checkbox'
              onChange={handleCheckAll}
              className='input-checkbox custom-checkbox checked:bg-primary'
            />
          }
        />
        <HeaderName
          className='flex basis-[60px]'
          text='type'
        />
        <HeaderName
          className='flex justify-center basis-[36px]'
          text='status'
        />
        <HeaderName
          className='flex grow shrink'
          text='name'
        />
        <HeaderName
          className='flex cursor-pointer basis-[72px] link text-primary'
          text='start date'
          sortKey={startDateFilter}
          onClick={() => {
            setParentFilter(null);
            setStartDateFilter(startDateFilter === 'asc' ? 'dsc' : 'asc');
            setTargetDateFilter(null);
          }}
        />
        <HeaderName
          className='flex cursor-pointer basis-[72px] link text-primary'
          text='target date'
          sortKey={targetDateFilter}
          onClick={() => {
            setParentFilter(null);
            setTargetDateFilter(targetDateFilter === 'asc' ? 'dsc' : 'asc');
            setStartDateFilter(null);
          }}
        />
        <HeaderName
          className='flex cursor-pointer basis-[100px]'
          text='parent'
          sortKey={parentFilter}
          onClick={() => {
            setParentFilter(parentFilter === 'asc' ? 'dsc' : 'asc');
            setStartDateFilter(null);
            setTargetDateFilter(null);
          }}
        />
        <HeaderName
          className='w-[5%] basis-[28px] truncate'
          text='assignee'
        />
      </div>
      <div className='flex w-full'>
        <div className='flex flex-col w-full'>
          {loading ? <LoadingSpinner isLoading /> : (
            <div>
              <TaskList
                tasks={tasks}
                filterTerm={filterTerm}
                parentFilter={parentFilter}
                startDateFilter={startDateFilter}
                targetDateFilter={targetDateFilter}
                setOpenTask={() => (
                  dispatch(setModularObjectModal({
                    isOpen: true,
                  }))
                )}
                setCurrentTask={(m) => (
                  dispatch(setModularObjectModal({
                    modularObjectId: m.id,
                    templateId: MILESTONE_TEMPLATE_ID,
                  }))
                )}
              />
              <div className='flex relative gap-2 justify-center my-[4px] h-[24px]'>
                <OffSetPagination
                  onPageChange={onPageChange}
                  selectedPage={selectedPage}
                  pageCount={pageInfo?.pageCount}
                />
                <div className='absolute top-0 min-w-[38px] right-[18px]'>
                  <PageSizeSelectionDropdown
                    onPageSizeChange={onPageSizeChange}
                    initialPageSize={selectedPageSize}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='flex py-4'>
            <RapidTaskCreation />
          </div>
        </div>
      </div>
    </>
  );
}
