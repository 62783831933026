import type { HardwareIntegration } from '@/__generated__/types';
import { useAddChildMutation } from '@/components/common/Nesting/AddChild.generated';
import {
  GetChildrenForNestingColumnDocument,
  type GetChildrenForNestingColumnQuery,
} from '@/components/common/Nesting/GetChildrenForNestingColumn.generated';
import { useRemoveChildMutation } from '@/components/common/Nesting/RemoveChildMutation.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useMemo } from 'react';

export const useNestingMutations = (objectId: string) => {
  const [addChild] = useAddChildMutation({
    update: (cache, { data }) => {
      const newChildren = data?.createHardwareIntegration;

      const existingData: GetChildrenForNestingColumnQuery = cache.readQuery({
        query: GetChildrenForNestingColumnDocument,
        variables: { modularObjectId: objectId },
      });

      cache.writeQuery({
        query: GetChildrenForNestingColumnDocument,
        variables: { id: objectId },
        data: {
          getModularObjectByIDs: [
            {
              ...existingData.getModularObjectByIDs[0],
              children: [...existingData.getModularObjectByIDs[0].children, ...newChildren],
            },
          ],
        },
      });
    },
    onCompleted: (data) => {
      const isApproval = data?.createHardwareIntegration?.every((integration) =>
        integration.__typename === 'HardwareIntegrationApproval'
      );
      const isIntegration = data?.createHardwareIntegration?.every((integration) =>
        integration.__typename === 'HardwareIntegration'
      );

      if (isApproval) {
        return addToastSuccess('Request to nest object(s) sent for approval.');
      }
      if (isIntegration) {
        return addToastSuccess('Successfully nested object(s)');
      }
      return addToastSuccess('Successfully nested object(s) and requested approval to nest object(s)');
    },
    onError: () => addToastError('Failed to nest object(s), please try again'),
  });

  const [removeChild] = useRemoveChildMutation({
    update: (cache, { data }) => {
      const removedIntegration = data?.deleteHardwareIntegration;

      const existingData: GetChildrenForNestingColumnQuery = cache.readQuery({
        query: GetChildrenForNestingColumnDocument,
        variables: { modularObjectId: objectId },
      });

      const remainingChildren = existingData.getModularObjectByIDs[0].children.filter(
        (child) => child.id !== (removedIntegration as HardwareIntegration).objectId,
      );

      cache.writeQuery({
        query: GetChildrenForNestingColumnDocument,
        variables: { modularObjectId: objectId },
        data: {
          getModularObjectByIDs: [
            {
              ...existingData.getModularObjectByIDs[0],
              children: remainingChildren,
            },
          ],
        },
      });
    },
  });

  return useMemo(() => ({ addChild, removeChild }), [addChild, removeChild]);
};
