import cx from 'classnames';
import { type ReactNode, useEffect, useRef } from 'react';

interface CardSectionProps {
  title?: string;
  children: ReactNode;
  className?: string;
  notice?: ReactNode;
}

export default function CardSection ({ children, notice, title, className }: CardSectionProps): JSX.Element {
  const elementId = `${title.toLowerCase()}-section`;

  const el = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1);
      },
      { threshold: [1] },
    );

    observer.observe(el.current);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div
        ref={el}
        className='sticky py-8 font-medium bg-white px-[24px] top-[-2px] font-effra text-[16px] data-[stuck]:z-[11]'
      >
        {title}
        {notice}
      </div>
      <div id={elementId} data-testid={elementId} className={cx('flex flex-col bg-white shadow-md mb-6', className)}>
        {/* <span> */}
        {children}
        {/* </span> */}
      </div>
    </>
  );
}
