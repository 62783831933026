enum Color {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  WHITE = 'white',
  BLACK = 'black',
  PINK = 'pink',
  GRAY_70 = 'gray-70',
}

export default Color;
