import SidenavSection from '@/components/SideNavigation/components/SidenavSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/sharp-solid-svg-icons';
import { useRouter } from 'next/router';
import SidenavTab from '../components/SidenavTab';

export default function DriversTabs (): JSX.Element {
  const router = useRouter();

  return (
    <SidenavSection title='Drivers'>
      <SidenavTab
        name='All Drivers'
        href='/schedule?scheduleView=drivers'
        icon={<FontAwesomeIcon icon={faCircleArrowRight} />}
        isActive={router.pathname === '/schedule' && router.query.scheduleView === 'drivers'}
        className='py-1'
      />
    </SidenavSection>
  );
}
