import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ApprovalsOnObjectCardFragmentFragmentDoc } from '../../Card/ApprovalsOnObjectCardFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdateObjectCardDatesMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type UpdateObjectCardDatesMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, startDate: any | null, targetDate: any | null, approvals: Array<{ __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, status: Types.ApprovalStatus, referenceType: string, referenceName: string, referenceParentName: string, requestedBy: { __typename?: 'User', id: string }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, fieldId: string, to: string, from: string } | null> }> | null }> };


export const UpdateObjectCardDatesDocument = gql`
    mutation UpdateObjectCardDates($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    startDate
    targetDate
    approvals {
      ...ApprovalsOnObjectCardFragment
    }
  }
}
    ${ApprovalsOnObjectCardFragmentFragmentDoc}`;
export type UpdateObjectCardDatesMutationFn = Apollo.MutationFunction<UpdateObjectCardDatesMutation, UpdateObjectCardDatesMutationVariables>;

/**
 * __useUpdateObjectCardDatesMutation__
 *
 * To run a mutation, you first call `useUpdateObjectCardDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectCardDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectCardDatesMutation, { data, loading, error }] = useUpdateObjectCardDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateObjectCardDatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjectCardDatesMutation, UpdateObjectCardDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateObjectCardDatesMutation, UpdateObjectCardDatesMutationVariables>(UpdateObjectCardDatesDocument, options);
      }
export type UpdateObjectCardDatesMutationHookResult = ReturnType<typeof useUpdateObjectCardDatesMutation>;
export type UpdateObjectCardDatesMutationResult = Apollo.MutationResult<UpdateObjectCardDatesMutation>;
export type UpdateObjectCardDatesMutationOptions = Apollo.BaseMutationOptions<UpdateObjectCardDatesMutation, UpdateObjectCardDatesMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateObjectCardDatesMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockUpdateObjectCardDatesMutation = (resolver: Parameters<typeof graphql.mutation<UpdateObjectCardDatesMutation, UpdateObjectCardDatesMutationVariables>>[1]) =>
  graphql.mutation<UpdateObjectCardDatesMutation, UpdateObjectCardDatesMutationVariables>(
    'UpdateObjectCardDates',
    resolver
  )
