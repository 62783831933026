import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { faJira } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEllipsis, faTrash, faXmark } from '@fortawesome/sharp-regular-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import cx from 'classnames';
import { Fragment } from 'react';

interface MenuButtonProps {
  handleDuplicate: () => void;
  handleDelete: () => void;
  handleJiraTaskCreate: () => void;
  handleJiraTaskDelete: () => void;
  canDelete: boolean;
  canCreateJiraTask: boolean;
  canDeleteJiraTask: boolean;
  isOwner: boolean;
  shouldShowDuplicateButton: boolean;
}

export default function MenuButton (
  {
    handleDuplicate,
    handleDelete,
    handleJiraTaskCreate,
    handleJiraTaskDelete,
    canDelete,
    canCreateJiraTask,
    canDeleteJiraTask,
    isOwner,
    shouldShowDuplicateButton,
  }: MenuButtonProps,
): JSX.Element {
  return (
    <Menu as='div' className='flex relative items-center'>
      <InfoTooltip
        message='More'
        tooltipClassName='effra-8'
        tooltipContentClassName='px-[6px] py-[4px]'
        placement='bottom'
      >
        <Menu.Button className='flex relative justify-center items-center p-3 transition-colors text-neutral-500 rounded-[2px] h-[24px] w-[24px] hover:text-neutral-700 hover:bg-gray-90'>
          <FontAwesomeIcon icon={faEllipsis} className='h-[16px]' />
        </Menu.Button>
      </InfoTooltip>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='flex absolute top-0 right-0 flex-col min-w-min bg-white divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg translate-y-12 focus:outline-none w-[10rem] z-[200]'>
          {shouldShowDuplicateButton && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type='button'
                  onClick={handleDuplicate}
                  className={cx(
                    'group w-full items-center pl-6 pr-3 py-3 text-left transition-all flex gap-3',
                    {
                      'bg-neutral-100 text-neutral-700': active,
                      'text-neutral-500': !active,
                    },
                  )}
                >
                  <FontAwesomeIcon icon={faCopy} className='h-[16px]' />
                  <div>Duplicate</div>
                </button>
              )}
            </Menu.Item>
          )}
          {canCreateJiraTask && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type='button'
                  onClick={handleJiraTaskCreate}
                  className={cx(
                    'group w-full items-center pl-6 pr-3 py-3 text-left transition-all flex gap-3',
                    {
                      'bg-neutral-100 text-neutral-700': active,
                      'text-neutral-500': !active,
                    },
                  )}
                >
                  <FontAwesomeIcon icon={faJira} className='h-[16px]' />
                  <div>Push to Jira</div>
                </button>
              )}
            </Menu.Item>
          )}

          {canDeleteJiraTask && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type='button'
                  onClick={handleJiraTaskDelete}
                  className={cx(
                    'group w-full items-center pl-6 pr-3 py-3 text-left transition-all flex gap-3',
                    {
                      'bg-neutral-100 text-red-600': active,
                      'text-red-500': !active,
                    },
                  )}
                >
                  <FontAwesomeIcon icon={faXmark} className='h-[16px]' />
                  <div>Unsync Jira</div>
                </button>
              )}
            </Menu.Item>
          )}
          {isOwner && (
            <Menu.Item disabled={!canDelete}>
              {({ active }) => (
                <button
                  type='button'
                  onClick={handleDelete}
                  className={cx(
                    'group w-full items-center pl-6 pr-3 py-3 text-left transition-all flex gap-3',
                    {
                      'bg-neutral-100 text-red-600': active,
                      'text-red-500': !active,
                    },
                  )}
                >
                  <FontAwesomeIcon icon={faTrash} className='h-[16px]' />
                  <div>Delete</div>
                </button>
              )}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
