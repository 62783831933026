import type { Share } from '@/__generated__/types';
import { addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { useCallback, useEffect } from 'react';
import useRemoveCollaborators from './hooks/useRemoveCollaborators';

interface RemoveButtonProps {
  selected: Share[];
  closeModal: () => void;
  disabled: boolean;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
}

export default function RemoveButton ({
  selected,
  closeModal,
  disabled,
  setSuccessMessage,
  setErrorMessage,
}: RemoveButtonProps) {
  const { handleRemoveCollaborators, error } = useRemoveCollaborators(selected);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error, setErrorMessage]);

  const clickHandler = useCallback(async () => {
    try {
      const data = await handleRemoveCollaborators();
      const numCollaboratorsRemoved = data.data?.deleteShares?.length;
      if (numCollaboratorsRemoved) {
        setSuccessMessage(`${numCollaboratorsRemoved} collaborators removed successfully`);
      }
      closeModal();
      addToastSuccess('Collaborators removed successfully');
    } catch (error) {
      console.error(error);
    }
  }, [handleRemoveCollaborators, closeModal, setSuccessMessage]);

  return (
    <Button
      figmaProps={{ style: 'fill', size: 'small' }}
      onClick={clickHandler}
      disabled={disabled}
    >
      remove
    </Button>
  );
}
