import type { User } from '@/__generated__/types';
import LoadingSpinner from '@/components/common/Tasks/TaskSection/LoadingSpinner';
import { useScrollWithShadow } from '@/hooks/useScrollWithShadow';
import cx from 'classnames';

interface CollaboratorListContainerProps {
  collaborators: Array<Partial<User>>;
  isLoading: boolean;
  children: React.ReactNode;
}

export default function CollaboratorListContainer (
  { collaborators, isLoading, children }: CollaboratorListContainerProps,
) {
  const { boxShadow: { showTop, showBottom }, onScrollHandler } = useScrollWithShadow();

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <section className='overflow-hidden relative z-5'>
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -top-8 bg-white blur', {
          'hidden': !showTop || collaborators.length < 6,
        })}
      />
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -bottom-8 bg-white blur', {
          'hidden': !showBottom || collaborators.length <= 7,
        })}
      />
      <div
        className={cx('flex flex-col gap-2 max-h-[328px]', {
          'overflow-y-scroll': collaborators.length > 7,
        })}
        onScroll={onScrollHandler}
      >
        {children}
      </div>
    </section>
  );
}
