import type { ModularObject } from '@/__generated__/types';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { difference, union } from 'ramda';
import { useMemo } from 'react';
import { useListActionContext } from '../../ListActions.context';
import { type PermissionLevel } from '../constants';
import DeleteConfirmation from './DeleteConfirmation';
import UpdateConfirmation from './UpdateConfirmation';

interface ConfirmationMessageProps {
  onClose: () => void;
}

const rolePermissionsByAction = {
  Delete: {
    needsApproval: [],
    notAllowed: ['Editor', 'Viewer'],
  },
  'Mark as Complete': {
    needsApproval: ['Editor'],
    notAllowed: ['Viewer'],
  },
  'Mark as Waived': {
    needsApproval: ['Editor'],
    notAllowed: ['Viewer'],
  },
  'Mark as To Do': {
    needsApproval: ['Editor'],
    notAllowed: ['Viewer'],
  },
  'Mark as In Progress': {
    needsApproval: ['Editor'],
    notAllowed: ['Viewer'],
  },
};

export default function ConfirmationMessage ({ onClose }: ConfirmationMessageProps): JSX.Element {
  const currentUser = useLoggedInUser();
  const { selectedObjects, selectedAction } = useListActionContext();

  const permissionMap = useMemo((): Record<PermissionLevel, ModularObject[]> => {
    const checkedObjects = [];
    const ownerItems = [];
    const needsApprovalItems = [];

    Object.values(selectedObjects).forEach((objects: ModularObject) => {
      Object.values(objects).forEach((object) => {
        checkedObjects.push(object);

        if (object.ownerId === currentUser.id) {
          ownerItems.push(object);
        } else {
          const share = object.shares.find((share) => share.userId === currentUser.id);
          if ((rolePermissionsByAction[selectedAction]?.needsApproval ?? []).includes(share?.role)) {
            if (object.approvalsEnabled) {
              needsApprovalItems.push(object);
            } else {
              ownerItems.push(object);
            }
          }
        }
      });
    });

    const notAllowedItems = difference(checkedObjects, union(ownerItems, needsApprovalItems));

    return {
      isOwner: ownerItems,
      needsApproval: needsApprovalItems,
      notAllowed: notAllowedItems,
    };
  }, [selectedObjects, currentUser, selectedAction]);

  return (
    {
      Delete: <DeleteConfirmation permissionMap={permissionMap} onClose={onClose} />,
      'Mark as Complete': <UpdateConfirmation permissionMap={permissionMap} onClose={onClose} />,
      'Mark as Waived': <UpdateConfirmation permissionMap={permissionMap} onClose={onClose} />,
      'Mark as To Do': <UpdateConfirmation permissionMap={permissionMap} onClose={onClose} />,
      'Mark as In Progress': <UpdateConfirmation permissionMap={permissionMap} onClose={onClose} />,
    }[selectedAction]
  );
}
