import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import { FullUserFragmentDoc } from '../fragments/user.fragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type UpdateUserPasswordMutationVariables = Types.Exact<{
  input: Types.UpdateUserPasswordInput;
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword: { __typename?: 'User', forcePasswordReset: boolean | null, id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null } };

export type UpdateUserTosAcknowledgementMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateUserTosAcknowledgementMutation = { __typename?: 'Mutation', updateUserTOSAcknowledgement: { __typename?: 'User', id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null } };


export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
  updateUserPassword(input: $input) {
    ...FullUser
    forcePasswordReset
  }
}
    ${FullUserFragmentDoc}`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const UpdateUserTosAcknowledgementDocument = gql`
    mutation UpdateUserTOSAcknowledgement {
  updateUserTOSAcknowledgement {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type UpdateUserTosAcknowledgementMutationFn = Apollo.MutationFunction<UpdateUserTosAcknowledgementMutation, UpdateUserTosAcknowledgementMutationVariables>;

/**
 * __useUpdateUserTosAcknowledgementMutation__
 *
 * To run a mutation, you first call `useUpdateUserTosAcknowledgementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTosAcknowledgementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTosAcknowledgementMutation, { data, loading, error }] = useUpdateUserTosAcknowledgementMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUserTosAcknowledgementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTosAcknowledgementMutation, UpdateUserTosAcknowledgementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTosAcknowledgementMutation, UpdateUserTosAcknowledgementMutationVariables>(UpdateUserTosAcknowledgementDocument, options);
      }
export type UpdateUserTosAcknowledgementMutationHookResult = ReturnType<typeof useUpdateUserTosAcknowledgementMutation>;
export type UpdateUserTosAcknowledgementMutationResult = Apollo.MutationResult<UpdateUserTosAcknowledgementMutation>;
export type UpdateUserTosAcknowledgementMutationOptions = Apollo.BaseMutationOptions<UpdateUserTosAcknowledgementMutation, UpdateUserTosAcknowledgementMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateUserPasswordMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateUserPassword })
 *   )
 * })
 */
export const mockUpdateUserPasswordMutation = (resolver: Parameters<typeof graphql.mutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>>[1]) =>
  graphql.mutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
    'UpdateUserPassword',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateUserTosAcknowledgementMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ updateUserTOSAcknowledgement })
 *   )
 * })
 */
export const mockUpdateUserTosAcknowledgementMutation = (resolver: Parameters<typeof graphql.mutation<UpdateUserTosAcknowledgementMutation, UpdateUserTosAcknowledgementMutationVariables>>[1]) =>
  graphql.mutation<UpdateUserTosAcknowledgementMutation, UpdateUserTosAcknowledgementMutationVariables>(
    'UpdateUserTOSAcknowledgement',
    resolver
  )
