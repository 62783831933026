import { Button } from '@/designSystemComponents/Button';
import metrics from '@/util/metrics';
import { useRouter } from 'next/router';
import Color from '../../types/Color.enum';
import useSubscriptionInfo from './useSubscriptionInfo';

export default function SubscriptionInfo (): JSX.Element {
  const router = useRouter();

  const {
    planName,
    buildCountDisplay,
    driverCountDisplay,
    userCountDisplay,
    shouldHidePricingInfo,
    remainingTrialDays,
    isTrial,
    isBasic,
  } = useSubscriptionInfo();

  if (shouldHidePricingInfo) {
    return null;
  }

  const gotoPlanPageViaTrialCountdown = async () => {
    metrics.track(
      'sidenav - nav to plan page via trial countdown',
      {
        remainingTrialDays,
      },
    );

    await router.push('/settings/plan');
  };

  const gotoPlanPage = async () => {
    metrics.track('sidenav - nav to plan page via upgrade now', {
      planName,
    });
    await router.push('/settings/plan');
  };

  return (
    <div className='flex-col gap-[22px] text-neutral-200 effra-10 hidden @expanded/sidenav:flex uppercase'>
      <div className='flex flex-col gap-[8px]'>
        <div>{planName}</div>
        <div>Builds {buildCountDisplay}</div>
        <div>Drivers {driverCountDisplay}</div>
        <div>Users {userCountDisplay}</div>
      </div>
      {isTrial && (
        <Button
          onClick={gotoPlanPageViaTrialCountdown}
          color={Color.PINK}
          figmaProps={{ style: 'fill', size: 'small' }}
          className='rounded-[2px] !h-[24px]'
        >
          {remainingTrialDays} days left
        </Button>
      )}
      {isBasic && (
        <Button
          onClick={gotoPlanPage}
          color={Color.PINK}
          figmaProps={{ style: 'fill', size: 'small' }}
          className='rounded-[2px] !h-[24px]'
        >
          Upgrade Now
        </Button>
      )}
    </div>
  );
}
