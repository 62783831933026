import Spinner from '@/components/Icons/Spinner';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { RichText } from '@/components/RichText/RichText';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import type { Editor, JSONContent } from '@tiptap/react';
import { type PropsWithChildren } from 'react';
import { CommentSubmitButton } from './CommentSubmitButton';

export interface CommentFormProps {
  initialContent?: string;
  handleSubmitComment: (content: JSONContent) => void;
  isSubmitting: boolean;
  onImageUpload?: (file: File) => Promise<string>;
}

export const CommentForm = (
  { initialContent, handleSubmitComment, isSubmitting, onImageUpload }: PropsWithChildren<CommentFormProps>,
) => {
  const loggedInUser = useLoggedInUser();

  return (
    <div className='flex'>
      <div className='mr-4'>
        <ProfileImage user={loggedInUser} />
      </div>
      <div className='flex flex-col flex-1 p-5 border border-solid focus-within:shadow-[0px_1px_1px_0px] focus-within:shadow-primary'>
        <RichText
          shouldShowToolbar
          isEditable
          content={initialContent}
          placeholder='Post a comment to all collaborators...'
          className='focus:outline-none min-h-[50px]'
          onImageUpload={onImageUpload}
        >
          {(editor: Editor) => {
            return (
              <div className='ml-auto w-20 pt-[8px]'>
                <CommentSubmitButton
                  editor={editor}
                  handleSubmitComment={handleSubmitComment}
                  isSubmitting={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : 'Send'}
                </CommentSubmitButton>
              </div>
            );
          }}
        </RichText>
      </div>
    </div>
  );
};
