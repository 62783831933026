import { NoOverlapGapBehaviorIcon } from '@/components/Icons/GapBehaviorIcons';
import { useGetTemplatesByIdQuery } from '@/graphql/template/getTemplatesById.generated';
import { useLoggedInSubscription, useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import { getParentObject } from '@/util/lookup.functions';
import {
  faCircleArrowRight,
  faClock,
  faGear,
  faListTree,
  faMessageLines,
  faPaperclipVertical,
  faSparkle,
} from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import useShouldAnimate from '../../../hooks/useShouldAnimate';
import HeaderTab from './HeaderTab';

interface HeaderLowerProps {
  isCompressedHeader?: boolean;
}

export default function HeaderLower ({ isCompressedHeader }: Readonly<HeaderLowerProps>): JSX.Element {
  const state = useAppSelector(selectRootState);
  const currentSubscription = useLoggedInSubscription();
  const isUpdatesColumnDisabled = 'OBJECT_CARD_SECTION_UPDATES' in (currentSubscription?.featureLimits || {});
  const { objectCardData } = useObjectCardContext();
  const loggedInUser = useLoggedInUser();

  const isObjectEditor = (objectCardData?.shares)?.some((share) => {
    return share?.userId === loggedInUser?.id && share?.role?.toUpperCase() === 'EDITOR';
  });
  const isObjectOwner = objectCardData?.ownerId === loggedInUser?.id;
  const shouldShowCommentSection = isObjectEditor || isObjectOwner;
  const isTask = objectCardData?.template?.type === 'task';

  const [parentObject] = getParentObject('modular_object', objectCardData?.id, state);
  const { data: templateData } = useGetTemplatesByIdQuery({ variables: { templateId: parentObject?.templateId } });
  const parentTemplate = templateData?.getTemplates?.[0];

  const isSubtask = parentTemplate?.type === TemplateType.Task;

  const shouldShowTasks = !isSubtask;

  const shouldAnimate = useShouldAnimate();

  return (
    <div className='relative -z-10'>
      <div
        className={cx('gap-[16px] flex overflow-x-scroll no-scrollbar transition-all duration-500 transform-gpu', {
          'animate-object-change': shouldAnimate,
          'pb-[6px] px-[24px]': isCompressedHeader,
          'p-[24px]': !isCompressedHeader,
        })}
      >
        <HeaderTab icon={faSparkle} name='Details' />
        <HeaderTab icon={faGear} name='Attributes' />
        <HeaderTab icon={faPaperclipVertical} name='Files & Links' />
        {!isTask && <HeaderTab icon={faListTree} name='Nesting' />}
        {shouldShowTasks && <HeaderTab icon={faCircleArrowRight} name={isTask ? 'Nested Drivers' : 'Drivers'} />}
        <HeaderTab
          name='Dependencies'
          RenderIcon={() => <NoOverlapGapBehaviorIcon height='12px' width='16px' />}
        />

        {shouldShowCommentSection && <HeaderTab icon={faMessageLines} name='Comments' />}
        <HeaderTab icon={faClock} name='Updates' disabled={isUpdatesColumnDisabled} proFeature />
      </div>
      {/* Overflow Gradient Fades */}
      <>
        <div className='absolute top-0 left-0 h-full bg-gradient-to-l from-transparent to-white w-[24px]' />
        <div className='absolute top-0 right-0 h-full bg-gradient-to-r from-transparent to-white w-[24px]' />
      </>
    </div>
  );
}
