import type { CreateExternalLinkInput, ShareInput, ShareRole, Team, TeamShareInput, User } from '@/__generated__/types';
import { apolloClientSideClient } from '@/apollo/apolloClient';
import { useGetUsersByOrganizationQuery } from '@/components/Company/UserTable/getUsersByOrganization.generated';
import { ConfirmExternalCollaborators } from '@/components/modals/CollaboratorDiscovery/CollaboratorList';
import CollaboratorRoleDropdown from '@/components/modals/CollaboratorDiscovery/CollaboratorRoleDropDown';
import EntitySearchBar from '@/components/modals/CollaboratorDiscovery/EntitySearchBar/EntitySearchBar';
import { DropdownOption } from '@/components/modals/CollaboratorDiscovery/hooks/useDropdownOptionMap';
import useSearchableUserList from '@/components/modals/CollaboratorDiscovery/hooks/useSearchableUserList/useSearchableUserList';
import { useAddNewCollaboratorsMutation } from '@/components/modals/CollaboratorDiscovery/mutations/addShares.generated';
import { GetCollaboratorListDocument } from '@/components/modals/CollaboratorDiscovery/queries/getCollaboratorList.generated';
import { useTeamsForDisplayQuery } from '@/components/SideNavigation/TabSections/Teams/TeamsForDisplay.generated';
import { addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { GetLoggedInUserDocument } from '@/state/getLoggedInUser.generated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/sharp-solid-svg-icons';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../../LoadingSpinner';
import { useGetUsersByEmailLazyQuery } from './GetUsersByEmail.query.generated';

interface AddCollaboratorsModalProps {
  selectedObjectIds: string[];
  closeModal: () => void;
}

export default function AddCollaboratorsModal ({ selectedObjectIds, closeModal }: AddCollaboratorsModalProps) {
  const { getUser: { user: loggedInUser } } = apolloClientSideClient.readQuery({
    query: GetLoggedInUserDocument,
  }) ?? {};

  const [showExternalUserConfirmation, setShowExternalUserConfirmation] = useState(false);
  const [showExternalTeamConfirmation, setShowExternalTeamConfirmation] = useState(false);
  const [addCollaborators, { error, data, loading: loadingAddCollaborators }] = useAddNewCollaboratorsMutation({
    optimisticResponse: ({ input, teamShares: teamSharesInput, externalLinkInput }) => ({
      __typename: 'Mutation',
      createExternalLink: null,
      addShares: input.shares.map(share => ({
        __typename: 'Share',
        id: uuidv4(), // Temporary ID for optimistic update
        ...share,
      })),
      addTeamShares: {
        __typename: 'TeamShareResults',
        teamShares: Array.isArray(teamSharesInput) ?
          teamSharesInput.map(share => ({
            __typename: 'TeamShare',
            id: uuidv4(), // Temporary ID for optimistic update
            ...share,
          })) :
          [],
        shares: [],
      },
    }),
    refetchQueries: [
      {
        query: GetCollaboratorListDocument,
        variables: { ids: selectedObjectIds },
      },
    ],
  });

  const errorMessage = useMemo(() => error?.message, [error]);
  const successMessage = useMemo(() => {
    if (data?.addShares?.length) {
      return `${data?.addShares?.length} collaborators added successfully`;
    }
    return null;
  }, [data]);

  const searchableListContext = useSearchableUserList();
  const {
    setUsers,
    users,
    addedUsers,
    setTeams,
    addedTeams,
  } = searchableListContext;

  const numberOfUserShares = useMemo((): number => {
    const numberOfObjects = selectedObjectIds.length;
    const numberOfIndividualUsers = addedUsers.length;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const numberOfTeamUsers = addedTeams.reduce((acc, curr: any): number => {
      const numberOfExternalMembers = curr.externalTeamMemberCount;
      const numberOfInternalMembers = curr.internalTeamMemberCount;

      return acc + numberOfExternalMembers + numberOfInternalMembers;
    }, 0);

    return (numberOfIndividualUsers + numberOfTeamUsers) * numberOfObjects;
  }, [addedUsers, addedTeams, selectedObjectIds]);

  const shareWarning = useMemo((): boolean => {
    return numberOfUserShares > 800;
  }, [numberOfUserShares]);

  useGetUsersByOrganizationQuery({
    fetchPolicy: 'network-only',
    variables: {
      orgID: loggedInUser?.organizationId,
    },
    onCompleted: (data) => {
      setUsers({
        userList: data.getUsersByOrganization,
      });
    },
  });

  useTeamsForDisplayQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTeams({
        teamList: data.teams.edges,
      });
    },
  });

  const [role, setRole] = useState('Viewer');

  const [getUserByEmail] = useGetUsersByEmailLazyQuery({
    onCompleted: (data) => {
      const [externalUser] = data.getUsersByEmail;

      setUsers({
        userList: [
          ...users,
          {
            ...externalUser,
            isExternal: true,
          },
        ],
      });
    },
  });

  const confirmExternalCollaborators = async (): Promise<void> => {
    // Check if adding external users or teams
    const isAddingExternalUsers = addedUsers.some((user: User & { isExternal: boolean }) => user.isExternal);
    const isAddingExternalTeams = addedTeams.some((team: Team) => team.externalTeamMemberCount > 0);
    if (isAddingExternalUsers || isAddingExternalTeams) {
      setShowExternalUserConfirmation(isAddingExternalUsers);
      setShowExternalTeamConfirmation(isAddingExternalTeams);
    } else {
      await handleAddCollaborators();
    }
  };

  const handleAddCollaborators = async () => {
    // Form the shares and team shares, do not worry about whether or not the shares already exist,
    // the backend will handle that

    const anonymousShares: CreateExternalLinkInput[] = addedUsers.filter((user: User & { isAnonymousUser: boolean }) =>
      user.isAnonymousUser
    ).reduce(
      (acc: CreateExternalLinkInput[], user: User) => {
        selectedObjectIds.forEach((id: string) => {
          acc.push({
            emailAddress: user.email,
            objectID: id,
          });
        });
        return acc;
      },
      [],
    );

    const shares: ShareInput[] = addedUsers.reduce((acc: ShareInput[], user: User & { isAnonymousUser: boolean }) => {
      if (!user.isAnonymousUser) {
        selectedObjectIds.forEach((id: string) => {
          acc.push({
            id: null,
            externalId: id,
            externalType: 'modular_object',
            role: role as ShareRole,
            user: {
              id: user.id,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName,
              profileImage: user.profileImage,
              title: user.title,
              organizationId: user.organizationId ?? user.organization.id,
            },
            userId: user.id,
          });
        });
      }
      return acc;
    }, []);

    const teamShares: TeamShareInput[] = addedTeams.reduce((acc: TeamShareInput[], team: Team) => {
      selectedObjectIds.forEach((id: string) => {
        acc.push({
          defaultRole: role as ShareRole,
          modularObjectId: id,
          teamId: team.id,
        });
      });
      return acc;
    }, []);

    await addCollaborators({
      variables: {
        input: {
          shares,
          allowPartialSuccess: false,
        },
        teamShares,
        externalLinkInput: anonymousShares,
      },
      onCompleted: () => {
        closeModal();
        addToastSuccess('Collaborators added successfully');
      },
    });
  };

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='text-2xl font-bold flex gap-2'>
        Add Collaborators
        <LoadingSpinner isLoading={loadingAddCollaborators} className='w-8 h-8' />
      </div>
      {!(showExternalUserConfirmation || showExternalTeamConfirmation) && (
        <>
          <div className='flex gap-4'>
            <EntitySearchBar
              {...searchableListContext}
              disableAnonymousUsers={false}
              disableExternalUsers={false}
              getUserByEmail={getUserByEmail}
            />
            <CollaboratorRoleDropdown
              value={DropdownOption.Viewer}
              onChange={setRole}
              options={[DropdownOption.Editor, DropdownOption.Viewer]}
            />
            <Button
              figmaProps={{ size: 'small', style: 'fill' }}
              className='flex-1 rounded-[2px] grow-0'
              onClick={confirmExternalCollaborators}
              disabled={shareWarning}
            >
              Add
            </Button>
          </div>
          {shareWarning &&
            (
              <div className='text-pink'>
                <div className='font-medium'>
                  <FontAwesomeIcon icon={faXmark} className='mr-2' /> Wow, that&apos;s a lot!
                </div>
                <div>
                  You can&apos;t share that much at once. Try removing some collaborators or selecting fewer items.
                </div>
              </div>
            )}
          {Boolean(successMessage) &&
            (
              <div className='text-tertiary'>
                <FontAwesomeIcon icon={faCheck} className='mr-2' />
                {successMessage}
              </div>
            )}
          {Boolean(errorMessage) &&
            (
              <div className='whitespace-pre-line text-pink first-line:font-medium'>
                <FontAwesomeIcon icon={faXmark} className='mr-2' />
                {errorMessage}
              </div>
            )}
        </>
      )}
      {(showExternalTeamConfirmation || showExternalUserConfirmation) && (
        <ConfirmExternalCollaborators
          showExternalUserConfirmation={showExternalUserConfirmation}
          showExternalTeamConfirmation={showExternalTeamConfirmation}
          setExternalUserAddition={setShowExternalUserConfirmation}
          setExternalTeamAddition={setShowExternalTeamConfirmation}
          submitExternalCollaborators={handleAddCollaborators}
        />
      )}
    </div>
  );
}
