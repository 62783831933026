import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetModularObjectNamesByIDsQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type GetModularObjectNamesByIDsQuery = { __typename?: 'Query', getModularObjectByIDs: Array<{ __typename?: 'ModularObject', id: string, name: string | null }> | null };


export const GetModularObjectNamesByIDsDocument = gql`
    query GetModularObjectNamesByIDs($ids: [UUID!]!) {
  getModularObjectByIDs(ids: $ids) {
    id
    name
  }
}
    `;

/**
 * __useGetModularObjectNamesByIDsQuery__
 *
 * To run a query within a React component, call `useGetModularObjectNamesByIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectNamesByIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectNamesByIDsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetModularObjectNamesByIDsQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>(GetModularObjectNamesByIDsDocument, options);
      }
export function useGetModularObjectNamesByIDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>(GetModularObjectNamesByIDsDocument, options);
        }
export function useGetModularObjectNamesByIDsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>(GetModularObjectNamesByIDsDocument, options);
        }
export type GetModularObjectNamesByIDsQueryHookResult = ReturnType<typeof useGetModularObjectNamesByIDsQuery>;
export type GetModularObjectNamesByIDsLazyQueryHookResult = ReturnType<typeof useGetModularObjectNamesByIDsLazyQuery>;
export type GetModularObjectNamesByIDsSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectNamesByIDsSuspenseQuery>;
export type GetModularObjectNamesByIDsQueryResult = Apollo.QueryResult<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectNamesByIDsQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectByIDs })
 *   )
 * })
 */
export const mockGetModularObjectNamesByIDsQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>>[1]) =>
  graphql.query<GetModularObjectNamesByIDsQuery, GetModularObjectNamesByIDsQueryVariables>(
    'GetModularObjectNamesByIDs',
    resolver
  )
