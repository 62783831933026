import { useFlags } from 'launchdarkly-react-client-sdk';
import Script from 'next/script';
import { useEffect } from 'react';

// Zendesk API Docs
// https://developer.zendesk.com/api-reference/widget-messaging/web/core/

export default function ZendeskChatWidget ({ showChat }: { showChat: boolean }): JSX.Element {
  const { zendeskChat } = useFlags();

  useEffect(() => {
    if ((!zendeskChat || !showChat) && window.zE) {
      window.zE('messenger:set', 'cookies', false);
    } else if (zendeskChat && showChat && window.zE) {
      window.zE('messenger:set', 'cookies', true);
    }
  }, [zendeskChat, showChat, window.zE]);

  if (!zendeskChat || !showChat) return null;

  return (
    <Script
      id='ze-snippet'
      src='https://static.zdassets.com/ekr/snippet.js?key=1390e21d-5361-49db-ac5c-5c8cacfd71d0'
    />
  );
}
