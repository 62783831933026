import { Button } from '@/components/Button/Button';
import type { CreateModularObjectCardFragmentFragment } from '@/components/common/CreateNewObjectCard/CreateModularObject.generated';
import { addNewTask } from '@/components/common/Tasks/TaskSection/utils';
import ProBadge from '@/designSystemComponents/Badge/ProBadge';
import { useModal } from '@/hooks/useModal';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { useState } from 'react';

interface CreateNewTaskRowProps {
  icon: JSX.Element;
  taskName: string;
  hardwareId: string;
  isRequirementRestricted?: boolean;
  afterObjectCreationSave?: (modularObject: CreateModularObjectCardFragmentFragment) => void;
}
export default function CreateNewTaskRow ({
  icon,
  taskName,
  hardwareId,
  isRequirementRestricted,
  afterObjectCreationSave,
}: Readonly<CreateNewTaskRowProps>): JSX.Element {
  const { templates } = useAppSelector(state => state);
  const { closeModal } = useModal();
  const { openCreateNewObjectCardDrawer } = useObjectCardContext();

  const templateId = Object.values(templates).find(template => template?.name === taskName)?.id;

  const handleAddNewTask = (): void => {
    addNewTask(() =>
      openCreateNewObjectCardDrawer({
        templateId,
        createdFromId: hardwareId,
        createdFromTemplateId: templateId,
        asTask: true,
        afterObjectCreationSave,
      }), hardwareId);
  };

  const [hovered, setHovered] = useState(false);

  const isDisabled = isRequirementRestricted && templateId === '9deecd59-41ce-4c70-83eb-5d720c9f0c6a';

  return (
    <div
      className='flex justify-between px-2 rounded-md hover:bg-gray-100'
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
      onFocus={() => {
        setHovered(true);
      }}
      onBlur={() => {
        setHovered(false);
      }}
    >
      <div className='flex gap-4 items-center'>
        {icon}
        <div className='text-lg font-effra'>
          {pluralize.singular(taskName)}
        </div>
      </div>
      <div className='flex'>
        {isDisabled && <ProBadge shouldExpand />}
        <Button
          disabled={isDisabled}
          className={classNames('h-[unset] w-[unset] px-4 py-3', {
            'btn-ghost': !hovered,
            'btn-primary': hovered,
            'border-primary': hovered,
            border: hovered,
          })}
          onClick={() => {
            closeModal();
            handleAddNewTask();
          }}
        >
          Create New {pluralize.singular(taskName)}
        </Button>
      </div>
    </div>
  );
}
