import ModalHeader from './ModalHeader';

export interface ModalProps {
  children: React.ReactNode;
  title: string;
  header?: typeof ModalHeader;
  testId?: string;
}

export default function Modal ({ children, title, header = ModalHeader, testId = 'modal' }: ModalProps) {
  return (
    <div data-testid={testId} className='w-full'>
      {header({ title })}
      {children}
    </div>
  );
}
