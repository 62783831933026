import { useEditContext } from '@/components';
import { Button } from '@/designSystemComponents/Button';

interface FileLinkButtonProps {
  onClick: () => void;
  isLoading: boolean;
  text: string;
}
export default function FileLinkButton ({ isLoading, onClick, text }: FileLinkButtonProps): JSX.Element {
  const { canUserEdit } = useEditContext();

  if (!canUserEdit) return null;

  return (
    <Button
      isLoading={isLoading}
      className='w-[100px] !h-[32px] !font-medium text-[0.75rem] !px-4 !py-3'
      figmaProps={{ size: 'small', style: 'outline' }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
