import {
  type GetCollaboratorListQuery,
  useGetCollaboratorListQuery,
} from '@/components/modals/CollaboratorDiscovery/queries/getCollaboratorList.generated';
import { not, pipe } from 'ramda';
import { useCallback, useState } from 'react';

function isCurrentUserSelected (user) {
  return ({ user: selectedUser }) => selectedUser?.id === user.id;
}

export function useConfigureCollaboratorQueue (collaboratorData: GetCollaboratorListQuery, loggedInUserId: string) {
  const [selected, setSelected] = useState([]);

  const objectsWithShares = collaboratorData?.getModularObjectByIDs ?? [];

  const shares = objectsWithShares.flatMap(({ id, shares }) =>
    shares
      .filter(share => share.teams?.length === 0) // Exclude users in team shares
      .map(share => ({ ...share, externalId: id, externalType: 'modular_object' }))
  );

  const teamShares = objectsWithShares.reduce((acc, curr) => {
    if (curr.owner?.id === loggedInUserId && curr.teamShares.length > 0) {
      acc.push(...curr.teamShares);
    }
    return acc;
  }, []);

  // Get unique collaborators from the shares array
  const collaborators = shares
    .map(({ user }) => user)
    .filter((user, index, self) =>
      // For each user, find its first occurrence in the array
      // If the current index matches the first occurrence, keep it (it's unique)
      // This effectively removes duplicates while preserving order
      self.findIndex(u => JSON.stringify(u) === JSON.stringify(user)) === index
    );

  const teams = teamShares
    .map(({ team }) => team)
    .filter((team, index, self) => self.findIndex(t => JSON.stringify(t) === JSON.stringify(team)) === index);

  const addCollaborator = useCallback((user) => {
    const isUserSelected = isCurrentUserSelected(user);
    setSelected((prev) => {
      const newSelectedCollabs = prev.find(isUserSelected)
        ? prev.filter(pipe(isUserSelected, not))
        : [...prev, ...shares.filter(isUserSelected)];

      return newSelectedCollabs;
    });
  }, [shares]);

  const addTeam = useCallback((team) => {
    setSelected((prev) => {
      const teamIsSelected = prev.some((selectedItem) => selectedItem.team?.id === team.id);

      if (teamIsSelected) {
        // Unselect the selected team shares if they are for this team
        const updatedShares = prev.filter((selectedItem) => selectedItem.team?.id !== team.id);
        return updatedShares;
      } else {
        // Add the team shares for this team
        const teamSharesToSelect = teamShares.filter((share) => share.team.id === team.id);
        return [...prev, ...teamSharesToSelect];
      }
    });
  }, [teamShares]);

  return { selected, collaborators, teams, addCollaborator, addTeam };
}

export default function useCollaboratorRemovalQueue (selectedObjectIds, loggedInUserId) {
  const { data: collaboratorData, loading: isLoading } = useGetCollaboratorListQuery({
    fetchPolicy: 'network-only',
    variables: {
      ids: selectedObjectIds,
    },
  });

  const config = useConfigureCollaboratorQueue(collaboratorData, loggedInUserId);

  return { ...config, isLoading };
}
