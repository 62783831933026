import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetTemplatesQueryVariables = Types.Exact<{
  input: Types.GetTemplatesInput;
}>;


export type GetTemplatesQuery = { __typename?: 'Query', getTemplates: Array<{ __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null, createdAt: any | null, createdById: string | null, deletedAt: any | null, description: string | null, imagePath: string | null, modifiedAt: any | null, ownerId: string | null, pageColumns: Array<string | null> | null, subType: Types.TemplateSubTypes | null, visibility: string | null, isIntegratable: boolean | null, modules: any | null, version: number | null }> };


export const GetTemplatesDocument = gql`
    query GetTemplates($input: GetTemplatesInput!) {
  getTemplates(input: $input) {
    id
    name
    type
    createdAt
    createdById
    deletedAt
    description
    imagePath
    modifiedAt
    ownerId
    pageColumns
    subType
    type
    visibility
    isIntegratable
    modules
    version
  }
}
    `;

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
      }
export function useGetTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
        }
export function useGetTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesSuspenseQueryHookResult = ReturnType<typeof useGetTemplatesSuspenseQuery>;
export type GetTemplatesQueryResult = Apollo.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTemplatesQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ getTemplates })
 *   )
 * })
 */
export const mockGetTemplatesQuery = (resolver: Parameters<typeof graphql.query<GetTemplatesQuery, GetTemplatesQueryVariables>>[1]) =>
  graphql.query<GetTemplatesQuery, GetTemplatesQueryVariables>(
    'GetTemplates',
    resolver
  )
