import cx from 'classnames';

interface TimelinePointProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  noDot?: boolean;
  noPadding?: boolean;
  fadeLine?: boolean;
  color?: string;
  topLine?: boolean;
  contentClassName?: string;
}

export default function TimelinePoint (
  { children, icon, noDot, noPadding, fadeLine, color, topLine, contentClassName }: TimelinePointProps,
): JSX.Element {
  return (
    <div className='flex gap-4' data-testid='timeline-point'>
      <div className='flex flex-col justify-center w-10 center shrink-0'>
        {Boolean(icon) && (
          <>
            {/* Line */}
            <div className='justify-center grow center'>
              <div
                className={cx(
                  'w-[1px] h-full mx-auto',
                  { 'bg-neutral-200': topLine },
                )}
              />
            </div>
            {/* Icon */}
            <div className={cx('h-10 w-10 rounded-full flex items-center justify-center', color)}>{icon}</div>
          </>
        )}
        {/* Dot */}
        {!noDot && (
          <div data-testid='timeline-dot' className={cx('h-2 w-2 rounded-full bg-neutral-200 mx-auto', color)} />
        )}
        {/* Line */}
        <div className='justify-center grow center'>
          <div
            className={cx('w-[1px] h-full mx-auto', {
              'bg-neutral-200': !fadeLine,
              'bg-gradient-to-b from-neutral-200 to-transparent': fadeLine,
            })}
          />
        </div>
      </div>
      <div
        className={cx(
          'grow',
          {
            'pb-4': !noPadding && !icon,
            '-translate-y-2': !icon,
            'flex items-center py-5': Boolean(icon),
          },
          contentClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
}
