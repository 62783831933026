import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeleteLinksMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input'];
}>;


export type DeleteLinksMutation = { __typename?: 'Mutation', deleteLinks: Array<{ __typename?: 'Link', id: string }> };


export const DeleteLinksDocument = gql`
    mutation DeleteLinks($ids: [UUID!]!) {
  deleteLinks(ids: $ids) {
    id
  }
}
    `;
export type DeleteLinksMutationFn = Apollo.MutationFunction<DeleteLinksMutation, DeleteLinksMutationVariables>;

/**
 * __useDeleteLinksMutation__
 *
 * To run a mutation, you first call `useDeleteLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinksMutation, { data, loading, error }] = useDeleteLinksMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteLinksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLinksMutation, DeleteLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLinksMutation, DeleteLinksMutationVariables>(DeleteLinksDocument, options);
      }
export type DeleteLinksMutationHookResult = ReturnType<typeof useDeleteLinksMutation>;
export type DeleteLinksMutationResult = Apollo.MutationResult<DeleteLinksMutation>;
export type DeleteLinksMutationOptions = Apollo.BaseMutationOptions<DeleteLinksMutation, DeleteLinksMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteLinksMutation((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ deleteLinks })
 *   )
 * })
 */
export const mockDeleteLinksMutation = (resolver: Parameters<typeof graphql.mutation<DeleteLinksMutation, DeleteLinksMutationVariables>>[1]) =>
  graphql.mutation<DeleteLinksMutation, DeleteLinksMutationVariables>(
    'DeleteLinks',
    resolver
  )
