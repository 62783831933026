import type { DiffInput, Document, File } from '@/__generated__/types';
import { type Module } from '@/models/template.model';
import { NIL } from 'uuid';

export const buildUpdateObjectDiffPayload = (
  { objectId, fieldName, newFieldValue }: { objectId: string; fieldName: string; newFieldValue: string },
): DiffInput => {
  const payload: DiffInput = {
    externalID: objectId,
    externalType: 'modular_object',
    diff: { to: { [fieldName]: newFieldValue }, displayNames: { [fieldName]: fieldName } },
    fromMigration: false,
    diffType: 'updated',
  };

  return payload;
};

export const buildUpdateObjectDiffPayloadForDocument = (
  { objectId, payloadType, document }: {
    objectId: string;
    payloadType: 'addedFiles' | 'removedFiles' | 'addedLinks' | 'removedLinks';
    document: Document;
  },
): DiffInput => {
  switch (payloadType) {
    case 'removedFiles':
      return {
        externalID: objectId,
        externalType: 'modular_object',
        diff: {
          to: { [payloadType]: [document], files: [{}] },
          displayNames: { files: [{ isRemoved: 'isRemoved' }] },
        },
        fromMigration: false,
        diffType: 'updated',
      };
    case 'removedLinks':
      return {
        externalID: objectId,
        externalType: 'modular_object',
        diff: {
          to: { [payloadType]: [document], files: [{}] },
          displayNames: { links: [{ isRemoved: 'isRemoved' }] },
        },
        fromMigration: false,
        diffType: 'updated',
      };
    case 'addedFiles':
      return {
        externalID: objectId,
        externalType: 'modular_object',
        diff: {
          to: { [payloadType]: [document], files: [{}] },
          displayNames: { files: [{ isRemoved: 'isRemoved' }] },
        },
        fromMigration: false,
        diffType: 'updated',
      };
    default:
      return {
        externalID: objectId,
        externalType: 'modular_object',
        diff: {
          to: { [payloadType]: [document], files: [{}] },
          displayNames: { files: 'files' },
        },
        fromMigration: false,
        diffType: 'updated',
      };
  }
};

export const buildUpdateObjectDiffPayloadForTemplateImage = (
  { objectId, file }: {
    objectId: string;
    file: File;
  },
): DiffInput => {
  return {
    externalID: objectId,
    externalType: 'modular_object',
    diff: {
      to: { addedFiles: [file], files: [{}], imageId: file.id },
      displayNames: { files: 'files', imageId: 'imageId' },
    },
    fromMigration: false,
    diffType: 'updated',
  };
};

export const buildUpdateObjectDiffPayloadForAttributes = (
  { objectId, fieldId, fieldName, newFieldValue }: {
    objectId: string;
    fieldId: string;
    fieldName: string;
    newFieldValue: string;
  },
): DiffInput => {
  const payload: DiffInput = {
    externalID: objectId,
    externalType: 'modular_object',
    diff: { to: { data: { [fieldId]: newFieldValue } }, displayNames: { data: { [fieldId]: fieldName } } },
    fromMigration: false,
    diffType: 'updated',
  };

  return payload;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dehydrateRepeaterModule = (data: Module): any => {
  const dehydratedRepeaterModule = Object.values(dehydrateModularObject(data.modules))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce((prev, module: any) => {
      prev[module?.name] = module?.value;
      return prev;
    }, {});

  return {
    order: data.order,
    value: dehydratedRepeaterModule,
  };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flattenDehydratedModularObjectData = (data: Record<string, any>): Record<string, string> => {
  return Object.values(data || {})
    .reduce((acc: Record<string, string>, { name, value }): Record<string, string> => {
      acc[name] = value;
      return acc;
    }, {});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dehydrateModularObject = (data = {}, flatten?: boolean): any => {
  const newModularData = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(data || {}).forEach(([id, module]: [string, any]) => {
    if (!module) return;
    let value = '';
    if (module?.value) {
      value = module.value;
    } else if (module?.valueId && module.valueId !== NIL) {
      value = module.valueId;
    }

    if (module.type === 'files') {
      return;
    }

    if (module.type === 'repeater') {
      newModularData[id] = {
        name: module.name,
        values: {},
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const repeaterModules = Object.entries(module?.modules || {}).reduce((prev, [key, value]: [string, any]) => {
        prev[key] = dehydrateRepeaterModule(value);
        return prev;
      }, {});

      newModularData[id].values = repeaterModules;

      return newModularData;
    }

    if (module.modules) {
      const modulesObject = dehydrateModularObject(module.modules);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.entries(modulesObject || {}).forEach(([iid, imodule]: [string, any]) => {
        newModularData[iid] = imodule;
      });
    } else {
      newModularData[id] = {
        name: module.name,
        value,
      };
    }
  });

  if (flatten) {
    return flattenDehydratedModularObjectData(newModularData);
  }
  return newModularData;
};

/**
 * Used for flattening the modules object to a flat map of the modules leaves
 * @param modules template modules object
 * @param flattened Flat map of the modules leaves
 * @returns flattened map of the modules leaves
 */
export const flattenModuleLeaves = (modules, flattened = {}) => {
  Object.keys(modules).forEach((id) => {
    const _module = modules[id];

    if (Object.keys(_module).includes('modules')) {
      flattenModuleLeaves(_module.modules, flattened);
    } else {
      flattened[id] = _module;
    }
  });

  return flattened;
};
