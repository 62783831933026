import type * as Types from '../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ApprovalsOnObjectCardFragmentFragmentDoc } from '../../../Card/ApprovalsOnObjectCardFragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type LockObjectStartTargetDatesMutationVariables = Types.Exact<{
  input: Types.UpdateModularObjectInput;
}>;


export type LockObjectStartTargetDatesMutation = { __typename?: 'Mutation', updateModularObject: Array<{ __typename?: 'ModularObject', id: string, startDateLocked: boolean | null, targetDateLocked: boolean | null, approvals: Array<{ __typename?: 'Approval', id: string, approvalType: Types.ApprovalTypes, status: Types.ApprovalStatus, referenceType: string, referenceName: string, referenceParentName: string, requestedBy: { __typename?: 'User', id: string }, requestedChanges: Array<{ __typename?: 'ApprovalRequestChange', field: string, fieldId: string, to: string, from: string } | null> }> | null }> };


export const LockObjectStartTargetDatesDocument = gql`
    mutation LockObjectStartTargetDates($input: UpdateModularObjectInput!) {
  updateModularObject(input: $input) {
    id
    startDateLocked
    targetDateLocked
    approvals {
      ...ApprovalsOnObjectCardFragment
    }
  }
}
    ${ApprovalsOnObjectCardFragmentFragmentDoc}`;
export type LockObjectStartTargetDatesMutationFn = Apollo.MutationFunction<LockObjectStartTargetDatesMutation, LockObjectStartTargetDatesMutationVariables>;

/**
 * __useLockObjectStartTargetDatesMutation__
 *
 * To run a mutation, you first call `useLockObjectStartTargetDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockObjectStartTargetDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockObjectStartTargetDatesMutation, { data, loading, error }] = useLockObjectStartTargetDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLockObjectStartTargetDatesMutation(baseOptions?: Apollo.MutationHookOptions<LockObjectStartTargetDatesMutation, LockObjectStartTargetDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LockObjectStartTargetDatesMutation, LockObjectStartTargetDatesMutationVariables>(LockObjectStartTargetDatesDocument, options);
      }
export type LockObjectStartTargetDatesMutationHookResult = ReturnType<typeof useLockObjectStartTargetDatesMutation>;
export type LockObjectStartTargetDatesMutationResult = Apollo.MutationResult<LockObjectStartTargetDatesMutation>;
export type LockObjectStartTargetDatesMutationOptions = Apollo.BaseMutationOptions<LockObjectStartTargetDatesMutation, LockObjectStartTargetDatesMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLockObjectStartTargetDatesMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateModularObject })
 *   )
 * })
 */
export const mockLockObjectStartTargetDatesMutation = (resolver: Parameters<typeof graphql.mutation<LockObjectStartTargetDatesMutation, LockObjectStartTargetDatesMutationVariables>>[1]) =>
  graphql.mutation<LockObjectStartTargetDatesMutation, LockObjectStartTargetDatesMutationVariables>(
    'LockObjectStartTargetDates',
    resolver
  )
