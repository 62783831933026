import { Comment } from '@/components/CommentSection/Comment';
import { CommentForm } from '@/components/CommentSection/CommentForm/CommentForm';
import {
  GetCommentsForObjectIdDocument,
  useGetCommentsForObjectIdQuery,
} from '@/components/CommentSection/getCommentsForObjectId.generated';
import { uploadNewFile } from '@/components/common/FilesLinks/utils';
import { addToast } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import getAppUrl from '@/util/getAppUrl';
import { postFiles } from '@/util/requests.functions';
import type { JSONContent } from '@tiptap/react';
import { useCallback } from 'react';
import { v4, v5 } from 'uuid';
import { useObjectContext } from '../../Card/Object.context';
import { useCreateCommentOnModularObjectMutation } from './createCommentForModularObject.generated';

export const CommentSection = () => {
  const user = useLoggedInUser();

  const { activeModularObject } = useObjectContext();
  const modularObjectId = activeModularObject?.id;

  const [createComment, { loading }] = useCreateCommentOnModularObjectMutation();
  const { data } = useGetCommentsForObjectIdQuery({
    onError: (err) => {
      console.error(err);
    },
    variables: { modularObjectId },
    pollInterval: 5000,
    skip: !modularObjectId,
  });

  const handleSubmitComment = useCallback((body: JSONContent) => {
    return createComment({
      variables: {
        createCommentOnModularObjectInput: {
          body,
          modularObjectId,
          parsingVersion: 'json',
        },
      },
      refetchQueries: [GetCommentsForObjectIdDocument],
      onCompleted: () => {
        addToast('Comment Submitted!');
      },
    });
  }, [modularObjectId]);

  const handleImageUpload = useCallback(async (blob) => {
    // Upload to storage
    const uploadResponse = await uploadNewFile(blob);
    const responseFile = uploadResponse?.file;

    // Create new file row in database
    const newFileId = v5(`${responseFile.name}-${v4()}`, user?.id);
    const newFile = {
      ...uploadResponse.file,
      id: newFileId,
      externalId: modularObjectId,
      externalType: 'modular_object',
      showInFiles: false,
    };

    const fileResponse = await postFiles([newFile]);
    const imageId = fileResponse[0].id;

    return `${getAppUrl()}/api/images/${imageId}`;
  }, [modularObjectId, user?.id]);

  return (
    <div className='flex flex-col p-7' data-testid='comments section'>
      {data?.getModularObjectByID?.comments?.map((comment) => (
        <div key={comment.id} className='flex flex-1 mb-6 last-of-type:mb-0'>
          <Comment comment={comment} />
        </div>
      ))}
      <div className='mt-8'>
        <CommentForm
          isSubmitting={loading}
          handleSubmitComment={handleSubmitComment}
          onImageUpload={handleImageUpload}
        />
      </div>
    </div>
  );
};
