import { type Share, type Template, type User } from '@/__generated__/types';
import { addToastError } from '@/components/Toast/utils';
import { removeModularObjects } from '@/state/slices/modularObjects.slice';
import { closeModularObjectModal } from '@/state/slices/session.slice';
import { addShares, removeShares } from '@/state/slices/shares.slice';
import { type TemplateState } from '@/state/slices/templates.slice';
import { type AppDispatch, type RootState } from '@/state/store';
import { any, append, curry, eqProps, unless } from 'ramda';
import { useEffect, useState } from 'react';
import { env } from './env.utils';
import { getModularObjectTemplateName } from './lookup.functions';
import { deleteFiles, deleteShares, postFiles, postShares, putShares } from './requests.functions';

export function getUserName (user: User): string {
  if (!user) {
    return;
  }
  return `${user.firstName} ${user.lastName}`;
}

export function doNotRenderInProduction (): boolean {
  return env('NEXT_PUBLIC_STAGE') !== 'production';
}

export async function copyToClipboard (text: string): Promise<boolean> {
  if (!navigator?.clipboard) {
    return false;
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
}

export const useIsServerSide = (): boolean => {
  const [isServerSide, setIsServerSide] = useState(true);

  useEffect(() => {
    setIsServerSide(false);
  }, [setIsServerSide]);

  return isServerSide;
};

export async function handleSubmitSharesNew (
  dispatch: AppDispatch,
  externalId: string,
  currentUserId: string,
  shares: Array<Share & { isNew: boolean; role: string; isUpdated: boolean }>,
): Promise<void> {
  const addedShares = shares?.filter((s) => s.isNew);
  const removedShares = shares?.filter((s) => s.role as string === 'Remove');
  const updatedShares = shares?.filter((s) => s.role as string !== 'Remove' && !s.isNew && s.isUpdated);

  if (addedShares.length) {
    const response = await postShares(addedShares.map((share) => ({
      ...share,
      externalId,
    })));
    dispatch(addShares(response));
  }
  if (removedShares.length) {
    await deleteShares(removedShares);
    dispatch(removeShares(Object.values(removedShares || {})));
    const isCurrentUserRemoved = removedShares.filter((share) => share.userId === currentUserId);
    if (isCurrentUserRemoved.length) {
      dispatch(closeModularObjectModal());
      dispatch(removeModularObjects([{ id: externalId }]));
    }
  }
  if (updatedShares.length) {
    await putShares(updatedShares);
    dispatch(addShares(updatedShares));
  }
}

export async function handleSubmitFiles (
  objectId,
  objectType,
  addedFiles,
  removedFiles,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any[]>> {
  const updatedFiles = {
    new: [],
    removed: [],
  };

  if (addedFiles?.length) {
    try {
      // Map the external object onto the new files
      const newFiles = addedFiles.map((file) => ({
        ...file,
        externalId: objectId,
        externalType: objectType,
      }));
      updatedFiles.new = await postFiles(newFiles);
    } catch (err) {
      console.error(err);
      addToastError(err.error ?? err);
    }
  }

  if (removedFiles?.length) {
    try {
      const filesToRemove = removedFiles.map((file) => ({
        ...file,
        externalId: objectId,
        externalType: objectType,
      }));
      await deleteFiles(filesToRemove);
      updatedFiles.removed = filesToRemove;
    } catch (err) {
      console.error(err);
      addToastError(err.error ?? err);
    }
  }

  return updatedFiles;
}

export function mapExternalTypeToDisplay (externalID: string, state: RootState): string {
  return getModularObjectTemplateName(externalID, state);
}

export function titleCase (string: string): string {
  if (!string) return;
  const str = string.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export function getAppendIfNotExists (key: string) {
  return curry((obj, arr) =>
    unless(
      any(eqProps(key, obj)), // check if the obj id exists in the array
      append(obj), // append obj if it doesn't
    )(arr)
  );
}

export function getUserTemplates (templates: Template[]): TemplateState {
  const templatesByName = {};

  for (const template of templates) {
    if (!templatesByName[template.name]) {
      templatesByName[template.name] = template;
    } else {
      if (template.visibility === 'Company') {
        // Overwrite the global templates with the Company template
        templatesByName[template.name] = template;
      }
    }
  }

  const userTemplates = {};

  Object.keys(templatesByName).forEach(name => {
    const template = templatesByName[name];
    userTemplates[template.id] = template;
  });

  return userTemplates;
}

export const getUserStatus = (user: Pick<User, 'deletedAt' | 'acknowledgements'>) => {
  if (!user) return;
  let status;
  if (user?.deletedAt) {
    status = 'Deactivated';
  } else if (user?.acknowledgements?.termsOfUse) {
    status = 'Active';
  } else {
    status = 'Invited';
  }

  return status;
};

export const getTopLevelDomain = (email: string): string => {
  return email.split('@')[1]?.toLowerCase();
};
