import { RichText } from '@/components/RichText/RichText';
import { useCallback, useMemo } from 'react';
import { type Change } from '../types';
import TimelinePoint from './TimelinePoint';

export interface TimelineChangeProps {
  change: Change;
  noDot?: boolean;
  changeClassName?: string;
}

export default function TimelineChange ({ change, noDot, changeClassName }: TimelineChangeProps): JSX.Element {
  const { from, to, type } = useMemo(() => {
    return {
      from: (typeof change.from !== 'string' && Object.keys(change.from ?? {}).length === 0) ? [] : change.from,
      to: (typeof change.to !== 'string' && Object.keys(change.to ?? {}).length === 0) ? [] : change.to,
      type: change.type,
    };
  }, [change]);

  const getContentComponent = useCallback((content) => {
    try {
      const parsedContent = JSON.parse(content);
      if (parsedContent?.content) {
        return <RichText content={parsedContent} isEditable={false} />;
      } else {
        return content;
      }
    } catch (e) {
      return content;
    }
  }, []);

  return (
    <TimelinePoint noDot={noDot} contentClassName={changeClassName}>
      <div className='first-letter:capitalize'>{type}</div>
      <div className='flex gap-2 text-lg'>
        <span className='truncate max-w-[300px]'>{getContentComponent(from)}</span>
        {from !== '' && from !== null && from !== undefined && from.length > 0 && '➔'}
        <span className='text-black truncate max-w-[300px]'>{getContentComponent(to) ?? '—'}</span>
      </div>
    </TimelinePoint>
  );
}
