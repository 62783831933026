import { Loader } from '@/components/loader';

export default function LoadingSpinner (
  { isLoading, className }: { isLoading: boolean; className?: string },
): JSX.Element {
  return isLoading ?
    (
      <div className='flex justify-center item-center'>
        <Loader className={`m-auto w-20 h-20 ${className}`} />
      </div>
    ) :
    null;
}
