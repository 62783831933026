import { Button } from '@/designSystemComponents/Button';

export interface ConfirmFixedDependencyObjectUpdateModalProps {
  templateName: string;
  closeModal: () => void;
  handleConfirm: () => Promise<void>;
}

export const ConfirmFixedDependencyObjectUpdateModal = (
  { templateName, closeModal, handleConfirm }: ConfirmFixedDependencyObjectUpdateModalProps,
) => {
  return (
    <div className='flex flex-col flex-1 gap-5'>
      <h3 className='mb-0 text-3xl font-black capitalize font-effra text-secondary'>Are you sure?</h3>
      <p>
        This {templateName}{' '}
        is connected to another item via a fixed dependency. Changing its dates could automatically adjust the dates of
        dependent items.
      </p>
      <div className='flex gap-5'>
        <Button figmaProps={{ style: 'outline', size: 'large' }} className='flex-1' onClick={closeModal}>
          Cancel
        </Button>

        <Button figmaProps={{ style: 'fill', size: 'large' }} className='flex-1' onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
