import type { Team, User } from '@/__generated__/types';
import { Button } from '@/designSystemComponents/Button';
import { memo } from 'react';
import { useCollabDiscoveryContext } from './CollabDiscovery.context';
import CollaboratorRoleDropdown from './CollaboratorRoleDropDown';
import EntitySearchBar from './EntitySearchBar/EntitySearchBar';
import { DropdownOption } from './hooks/useDropdownOptionMap';

type ExtendedUser = User & {
  isExternal?: boolean;
  isAnonymousUser?: boolean;
};

function CollaboratorDiscoverySearch () {
  const { Editor, Viewer } = DropdownOption;
  const collaboratorContext = useCollabDiscoveryContext();

  const {
    currentUserRole,
    handleSubmitSharedUsers,
    setRole,
    setExternalUserAddition,
    addedUsers,
    addedTeams,
    setExternalTeamAddition,
  } = collaboratorContext;

  if (currentUserRole === Viewer || !currentUserRole) return null;

  function handleSubmitUsers () {
    const isAddingExternalUsers = addedUsers.some((user: ExtendedUser) => user.isExternal || user.isAnonymousUser);
    const isAddingExternalTeams = addedTeams.some((team: Team) => team.externalTeamMemberCount > 0);
    if (isAddingExternalUsers || isAddingExternalTeams) {
      setExternalUserAddition(isAddingExternalUsers);
      setExternalTeamAddition(isAddingExternalTeams);
    } else {
      handleSubmitSharedUsers();
    }
  }
  return (
    <div id='collab-discovery-and-sharing-tools' className='flex gap-4 pb-8'>
      <EntitySearchBar {...collaboratorContext} disableAnonymousUsers={false} disableExternalUsers={false} />
      <CollaboratorRoleDropdown
        value={DropdownOption.Viewer}
        onChange={setRole}
        options={[Editor, Viewer]}
      />
      <Button
        className='w-[70px] !h-[32px] rounded-[2px]'
        figmaProps={{
          size: 'small',
          style: 'fill',
        }}
        onClick={handleSubmitUsers}
      >
        share
      </Button>
    </div>
  );
}

export default memo(CollaboratorDiscoverySearch);
