import type { Dependency, DependencyBlockType } from '@/__generated__/types';
import RadioInput from '@/components/form/RadioInput/RadioInput';
import { addToastError } from '@/components/Toast/utils';
import { patchDependencies, postDependencies } from '@/util/requests.functions';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import * as fixedGapAnimation from '../gapGifs/fixed.json';
import * as gapAnimation from '../gapGifs/gap.json';
import * as noOverlapAnimation from '../gapGifs/no-overlap.json';
import * as noGapAnimation from '../gapGifs/none.json';

import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

import Alert from '@/components/Alert/Alert';
import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import DependencyApprovalWarning from '@/components/modals/CollaboratorDiscovery/AccessSettings/DependencyApprovalWarning';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import ProBadge from '@/designSystemComponents/Badge/ProBadge';
import { useLoggedInSubscription } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { ERROR_MESSAGES } from '@/util/constants';
import metrics from '@/util/metrics';
import { faLightbulb, faTriangleExclamation } from '@fortawesome/sharp-regular-svg-icons';
import toObject from 'dayjs/plugin/toObject';
import { type GetGapDataQuery } from '../getGapData.generated';
import { useGetModularObjectNamesByIDsQuery } from './getModularObjectNamesByIDs.generated';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

dayjs.extend(toObject);

const gapTypes = [
  {
    title: 'No Overlap',
    value: 'noOverlap',
    icon: 'fa-slack-shift',
    description: 'Flexible gap until dates overlap',
    isProFeature: true,
  },
  {
    title: 'Fixed',
    value: 'strict',
    icon: 'fa-locked-shift-1',
    description: 'Gap distance remains rigid when dependencies shift',
    isProFeature: true,
  },
  {
    title: 'None',
    value: 'none',
    icon: 'fa-no-shift',
    description: 'Changes to dates have no effect on dependencies',
    isProFeature: false,
  },
];

interface ListItemProps {
  checkedValue: string;
  title: string;
  description: string;
  value: string;
  icon: string;
  isProFeatureRestricted?: boolean;
  onCheckedCallback: () => void;
}

function ListItem (
  { checkedValue, title, description, value, icon, onCheckedCallback, isProFeatureRestricted }: ListItemProps,
) {
  return (
    <div className='flex gap-2 justify-start items-center w-[315px]'>
      <RadioInput
        name='dependency-modal-item-choice'
        className='w-6 h-6'
        label={
          <span
            className={cx('flex gap-3 items-center ml-2', {
              'cursor-not-allowed text-gray-30': isProFeatureRestricted,
            })}
          >
            <i className={`fa-kit ${icon} text-[10px]`} />
            {title}
          </span>
        }
        value={value}
        checkedValue={checkedValue}
        onCheckedCallback={onCheckedCallback}
        disabled={isProFeatureRestricted}
      />

      <div
        className={cx('font-normal leading-none text-black effra-12', {
          'text-gray-30 shrink': isProFeatureRestricted,
        })}
      >
        {description}
      </div>
      {isProFeatureRestricted && <ProBadge shouldExpand />}
    </div>
  );
}

interface UserInputWidgetProps {
  title: string;
  description: string | JSX.Element;
}
function UserInputWidget ({ children, title, description }: PropsWithChildren<UserInputWidgetProps>) {
  return (
    <div className='flex flex-col gap-3' data-testid='user-input-widget'>
      <div className='leading-loose text-black effra-24'>{title}</div>
      <div className='self-stretch text-black effra-12'>
        {description}
      </div>
      <div className='inline-flex gap-4 justify-start items-center'>
        {children}
      </div>
    </div>
  );
}

const createIterationKey = (key: string) => uuidv5(key, uuidv4());

export interface EditableDependency {
  id: string;
  daysToEdit: number;
  monthsToEdit: number;
  yearsToEdit: number;
  blockTypeToEdit: DependencyBlockType;
}

interface ObjectConfigScreenProps extends StepContentProps {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  currentObjectId: string;
  blockedById: string;
  isBlockingDependency: boolean;
  dependencyIdToEdit?: Readonly<EditableDependency>;
  gapDataResponse: GetGapDataQuery;
  closeModal: () => void;
}

interface GapData {
  value: number;
  sign: number;
  stringValue?: string;
}

export default function ObjectConfigScreen ({
  resetSteps,
  selectedIds,
  currentObjectId,
  isBlockingDependency,
  dependencyIdToEdit,
  gapDataResponse,
  closeModal,
}: Readonly<ObjectConfigScreenProps>) {
  const { scrollToId } = useObjectCardScrollContext();
  const {
    daysToEdit,
    monthsToEdit,
    yearsToEdit,
    blockTypeToEdit,
  } = dependencyIdToEdit ?? {};
  const isEditMode = Boolean(dependencyIdToEdit);
  const isAddingMultipleDependencies = selectedIds.length > 1;

  const { objectCardData } = useObjectCardContext();
  const apolloClient = useApolloClient();
  const subscription = useLoggedInSubscription();

  const { data: modularObjectNamesResponse } = useGetModularObjectNamesByIDsQuery({
    variables: {
      ids: selectedIds,
    },
    skip: !selectedIds.length,
  });

  const modularObjectNames = useMemo(() => {
    if (!modularObjectNamesResponse?.getModularObjectByIDs?.length) {
      return 'Unknown';
    }

    if (modularObjectNamesResponse?.getModularObjectByIDs?.length === 1) {
      return modularObjectNamesResponse.getModularObjectByIDs[0].name;
    }

    return `${modularObjectNamesResponse?.getModularObjectByIDs[0].name} (plus ${
      modularObjectNamesResponse?.getModularObjectByIDs?.length - 1
    } items)`;
  }, [
    modularObjectNamesResponse,
  ]);

  const [gapDays, setGapDays] = useState<GapData>({
    value: daysToEdit ?? 0,
    sign: daysToEdit > 0 ? 1 : -1,
  });
  const [gapMonths, setGapMonths] = useState<GapData>({
    value: monthsToEdit ?? 0,
    sign: monthsToEdit > 0 ? 1 : -1,
  });
  const [gapYears, setGapYears] = useState<GapData>({
    value: yearsToEdit ?? 0,
    sign: yearsToEdit > 0 ? 1 : -1,
  });

  const [
    selectedGapType,
    setSelectedGapType,
  ] = useState<DependencyBlockType>(blockTypeToEdit ?? 'noOverlap');

  useEffect(() => {
    if (subscription?.type === 'basic') {
      setSelectedGapType('none');
    }
  }, [subscription?.type]);

  const getInitialGapValue = (data: GetGapDataQuery, selectedGapType: DependencyBlockType) => {
    const [gapValues] = data?.getDependencyGapCalcs ?? [];

    if (!selectedGapType) {
      selectedGapType = 'none';
    }

    const gapData = gapValues?.[selectedGapType];
    const initialGapDays = {
      value: gapData?.gapDays ?? 0,
      sign: gapData?.gapDays > 0 ? 1 : -1,
    };

    const initialGapMonths = {
      value: gapData?.gapMonths ?? 0,
      sign: gapData?.gapMonths > 0 ? 1 : -1,
    };

    const initialGapYears = {
      value: gapData?.gapYears ?? 0,
      sign: gapData?.gapYears > 0 ? 1 : -1,
    };

    return {
      gapDays: initialGapDays,
      gapMonths: initialGapMonths,
      gapYears: initialGapYears,
    };
  };

  const setInitialGapValues = (data: GetGapDataQuery, selectedGapType: DependencyBlockType) => {
    const { gapDays, gapMonths, gapYears } = getInitialGapValue(data, selectedGapType);
    setGapDays(gapDays);
    setGapMonths(gapMonths);
    setGapYears(gapYears);
  };

  // ENG-5749: Gap data is now passed-in and only needs to be set
  // on the first render.
  useEffect(() => {
    const shouldSetInitialGap = currentObjectId && Boolean(
      !isEditMode &&
        !isAddingMultipleDependencies,
    );
    if (shouldSetInitialGap) {
      setInitialGapValues(gapDataResponse, selectedGapType);
    }
  }, []);

  const getPostDependenciesInput = () => {
    const gapData = gapDataResponse?.getDependencyGapCalcs ?? [];
    // When adding multiple dependencies, the user cannot set the gap
    if (isAddingMultipleDependencies) {
      return gapData.map((dependency) => {
        const gapTypeVals = selectedGapType === 'noOverlap' ? dependency?.noOverlap : dependency?.strict;

        return {
          gapDays: gapTypeVals.gapDays,
          gapMonths: gapTypeVals.gapMonths,
          gapYears: gapTypeVals.gapYears,
          modularObjectId: dependency.modularObjectId,
          blockedById: dependency.blockedById,
          blockType: selectedGapType,
        };
      }) as Partial<Dependency[]>;
    }

    return [{
      gapDays: gapDays.value,
      gapMonths: gapMonths.value,
      gapYears: gapYears.value,
      modularObjectId: gapData[0].modularObjectId,
      blockedById: gapData[0].blockedById,
      blockType: selectedGapType,
    }] as Partial<Dependency[]>;
  };

  const animationType = useMemo(() => {
    if (selectedGapType === 'none') return noGapAnimation;
    if (selectedGapType === 'strict') return fixedGapAnimation;
    if (selectedGapType === 'noOverlap') return noOverlapAnimation;
  }, [selectedGapType]);

  const handleAddDependencies = async () => {
    const postDependenciesInput = getPostDependenciesInput();

    metrics.track('dependency modal - add dependencies', { amountSelected: postDependenciesInput?.length });

    try {
      const response = await postDependencies(
        postDependenciesInput,
        objectCardData?.id,
      );
      // Handle errors that come back during the successful request, because partial success is possible in this request
      if (response.errors) {
        response.errors.forEach(error => {
          addToastError(error);
        });
      }
    } catch (e) {
      if (e.code === 409) { // Conflict error. Server provides details in the message.
        return addToastError(e.error);
      }
      switch (e.error) {
        case ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.MATCHER:
          return addToastError(
            `Cannot add dependency. ${ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        case ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.MATCHER:
          return addToastError(
            `Cannot add dependency. ${ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        default:
          addToastError('Cannot add dependency. Please try again later.');
      }
    }

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'getModularObjectByID' });
      },
    });

    // Refetches the queries for the gantt if observable
    apolloClient.reFetchObservableQueries().catch(console.error);

    scrollToId('dependencies-section');
    closeModal();
  };

  const handleEditDependencies = async () => {
    metrics.track('dependency modal - edit dependencies');

    try {
      await patchDependencies([{
        id: dependencyIdToEdit.id,
        gapDays: gapDays.value,
        gapMonths: gapMonths.value,
        gapYears: gapYears.value,
        blockType: selectedGapType,
      }] as Partial<Dependency[]>, objectCardData?.id);
    } catch (e) {
      if (e.code === 409) { // Conflict error. Server provides details in the message.
        return addToastError(e.error);
      }
      switch (e.error) {
        case ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.MATCHER:
          return addToastError(
            `Cannot edit dependency. ${ERROR_MESSAGES.DEPENDENCIES.CYCLE_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        case ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.MATCHER:
          return addToastError(
            `Cannot edit dependency. ${ERROR_MESSAGES.DEPENDENCIES.ZERO_GAP_DETECTION.HUMAN_FRIENDLY_MSG}`,
          );
        default:
          addToastError('Cannot edit dependency. Please try again later.');
      }
    }

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'getModularObjectByID' });
      },
    });
    // Refetches the queries for the gantt if observable
    apolloClient.reFetchObservableQueries().catch(console.error);

    scrollToId('dependencies-section');
    closeModal();
  };

  const handleDependencySave = isEditMode ? handleEditDependencies : handleAddDependencies;

  const gapToDisplay = (gap: GapData): string => {
    // gap.stringValue must be both defined and empty in order to enable
    // an empty display value
    if (gap.stringValue === '') return '';
    return Math.abs(gap.value).toString();
  };

  return (
    <>
      <div className='flex flex-col gap-10 w-full'>
        {/* row 1 */}
        {!isAddingMultipleDependencies && (
          <div className='flex flex-1'>
            <div className='flex flex-1'>
              <div className='flex flex-col flex-1 gap-[8px]'>
                <UserInputWidget
                  title='Set gap'
                  description={
                    <p>
                      Adjust the distance between <strong>{modularObjectNames}</strong> and{' '}
                      <strong>{objectCardData?.name}</strong>.
                    </p>
                  }
                >
                  <i className='text-sm fa-kit fa-locked-shift' />
                  <div className='inline-flex gap-2 items-center px-2 h-12 bg-white'>
                    <input
                      type='number'
                      value={gapToDisplay(gapYears)}
                      disabled={selectedGapType === 'none'}
                      placeholder=' ' // this space needs to stay here, or styling will break
                      onChange={e => {
                        setGapYears({
                          value: +e.target.value * gapYears.sign || 0,
                          sign: gapYears.sign,
                          stringValue: e.target.value.trim(),
                        });
                      }}
                      onBlur={e => {
                        setGapYears({
                          value: +e.target.value * gapYears.sign || 0,
                          sign: gapYears.sign,
                          // stringValue is intentionally left undefined to prevent the gap from being displayed as empty
                        });
                      }}
                      className='text-sm font-medium leading-none text-black font-effra input-text w-[54px]'
                    />
                    <div className='font-normal leading-3 text-stone-500 text-[10px] font-effra'>Years</div>
                  </div>
                  <div className='inline-flex gap-2 items-center px-2 h-12 bg-white'>
                    <input
                      type='number'
                      value={gapToDisplay(gapMonths)}
                      disabled={selectedGapType === 'none'}
                      placeholder=' ' // this space needs to stay here, or styling will break
                      onChange={e => {
                        setGapMonths({
                          value: +e.target.value * gapMonths.sign || 0,
                          sign: gapMonths.sign,
                          stringValue: e.target.value.trim(),
                        });
                      }}
                      onBlur={e => {
                        setGapMonths({
                          value: +e.target.value * gapMonths.sign || 0,
                          sign: gapMonths.sign,
                          // stringValue is intentionally left undefined to prevent the gap from being displayed as empty
                        });
                      }}
                      className='text-sm font-medium leading-none text-black font-effra input-text w-[54px]'
                    />
                    <div className='font-normal leading-3 text-stone-500 text-[10px] font-effra'>Months</div>
                  </div>
                  <div className='inline-flex gap-2 items-center px-2 h-12 bg-white'>
                    <input
                      type='number'
                      value={gapToDisplay(gapDays)}
                      disabled={selectedGapType === 'none'}
                      placeholder=' ' // this space needs to stay here, or styling will break
                      onChange={e => {
                        setGapDays({
                          value: +e.target.value * gapDays.sign || 0,
                          sign: gapDays.sign,
                          stringValue: e.target.value.trim(),
                        });
                      }}
                      onBlur={e => {
                        setGapDays({
                          value: +e.target.value * gapDays.sign || 0,
                          sign: gapDays.sign,
                          // stringValue is intentionally left undefined to prevent the gap from being displayed as empty
                        });
                      }}
                      className='text-sm font-medium leading-none text-black font-effra input-text w-[54px]'
                    />
                    <div className='font-normal leading-3 text-stone-500 text-[10px] font-effra'>Days</div>
                  </div>
                </UserInputWidget>
                <div className='pr-[16px] box-border'>
                  <Alert icon={selectedGapType === 'none' ? faTriangleExclamation : faLightbulb}>
                    {selectedGapType === 'none'
                      ? 'Gap input is disabled when gap behavior is set to None'
                      : 'Changes made to the gap will move dates accordingly.'}
                  </Alert>
                </div>
              </div>
            </div>
            <div className='flex-1 max-h-[170px]'>
              <Lottie animationData={gapAnimation} className='h-full' />
            </div>
          </div>
        )}

        {/* row 2 */}
        <div className='flex'>
          <div className='flex-1'>
            <UserInputWidget
              title='Select gap behavior'
              description='Select how item dates change when influenced by a dependency.'
            >
              <div className='flex flex-col gap-2'>
                {gapTypes.map(gapType => (
                  <ListItem
                    key={createIterationKey('custom-dependency-modal-input')}
                    checkedValue={selectedGapType}
                    onCheckedCallback={() => {
                      setSelectedGapType(gapType.value as DependencyBlockType);

                      if (isEditMode) return;

                      setInitialGapValues(gapDataResponse, gapType.value as DependencyBlockType);
                    }}
                    {...{
                      ...gapType,
                      isProFeatureRestricted: gapType.isProFeature && (
                        gapType.value === 'strict' && 'DEPENDENCIES_FIXED' in (subscription?.featureLimits ?? {}) ||
                        gapType.value === 'noOverlap' &&
                          'DEPENDENCIES_NO_OVERLAP' in (subscription?.featureLimits ?? {})
                      ),
                    }}
                  />
                ))}
                <p
                  className={cx('pt-1', {
                    'hidden': selectedIds.length < 2,
                  })}
                >
                  The same gap behavior will be applied to all selected items. You can change this later.
                </p>
              </div>
            </UserInputWidget>
          </div>
          <div className='flex-1 max-h-[228px]'>
            <Lottie animationData={animationType} className='h-full' />
          </div>
        </div>
      </div>

      <DependencyApprovalWarning objectId={objectCardData?.id} className='p-2 mt-6' />
      <div className='flex gap-8 pt-8'>
        {!dependencyIdToEdit && (
          <button className='w-full btn-ghost h-[36px]' onClick={resetSteps}>
            back
          </button>
        )}
        <button
          className='w-full btn-primary h-[36px]'
          onClick={handleDependencySave}
        >
          {dependencyIdToEdit
            ? 'save changes'
            : `add ${selectedIds.length > 1 ? 'dependencies' : 'dependency'} & save`}
        </button>
      </div>
    </>
  );
}
