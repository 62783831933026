import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type RemoveChildMutationVariables = Types.Exact<{
  childObjectId: Types.Scalars['UUID']['input'];
}>;


export type RemoveChildMutation = { __typename?: 'Mutation', deleteHardwareIntegration: { __typename: 'HardwareIntegration', id: string, objectId: string } | { __typename: 'HardwareIntegrationApproval', id: string } | null };


export const RemoveChildDocument = gql`
    mutation RemoveChild($childObjectId: UUID!) {
  deleteHardwareIntegration(modularObjectId: $childObjectId) {
    ... on HardwareIntegration {
      id
      objectId
      __typename
    }
    ... on HardwareIntegrationApproval {
      id
      __typename
    }
  }
}
    `;
export type RemoveChildMutationFn = Apollo.MutationFunction<RemoveChildMutation, RemoveChildMutationVariables>;

/**
 * __useRemoveChildMutation__
 *
 * To run a mutation, you first call `useRemoveChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChildMutation, { data, loading, error }] = useRemoveChildMutation({
 *   variables: {
 *      childObjectId: // value for 'childObjectId'
 *   },
 * });
 */
export function useRemoveChildMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChildMutation, RemoveChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChildMutation, RemoveChildMutationVariables>(RemoveChildDocument, options);
      }
export type RemoveChildMutationHookResult = ReturnType<typeof useRemoveChildMutation>;
export type RemoveChildMutationResult = Apollo.MutationResult<RemoveChildMutation>;
export type RemoveChildMutationOptions = Apollo.BaseMutationOptions<RemoveChildMutation, RemoveChildMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemoveChildMutation((req, res, ctx) => {
 *   const { childObjectId } = req.variables;
 *   return res(
 *     ctx.data({ deleteHardwareIntegration })
 *   )
 * })
 */
export const mockRemoveChildMutation = (resolver: Parameters<typeof graphql.mutation<RemoveChildMutation, RemoveChildMutationVariables>>[1]) =>
  graphql.mutation<RemoveChildMutation, RemoveChildMutationVariables>(
    'RemoveChild',
    resolver
  )
