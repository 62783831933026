import cx from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useCallback, useMemo, useState } from 'react';
import { type Change } from '../types';
import TimelineChange from './TimelineChange';
import { useTimelineEventContext } from './TimelineEvent.context';
import TimelinePoint from './TimelinePoint';
import UserName from './UserName';

import { useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
dayjs.extend(utc);

const CHANGE_LIMIT = 3;

export default function TimelineChanges (): JSX.Element {
  const { users: usersState } = useAppSelector(selectRootState);
  const { historyObject, currentUser } = useTimelineEventContext();

  const [hideOverflow, setHideOverflow] = useState(true);

  const isRequest = Boolean(historyObject?.action === 'approved' || historyObject?.action === 'denied');

  const toggleOverflow = useCallback(() => {
    setHideOverflow(prev => !prev);
  }, [setHideOverflow]);

  const collaboratorChange = useMemo((): Change[] => {
    if (historyObject.collaboratorsAdded?.length) {
      return [{
        type: historyObject.collaboratorsAdded?.length > 1
          ? 'Collaborators Added'
          : 'Collaborator Added',
        to: historyObject?.collaboratorsAdded?.map((collaborator, idx) => (
          <UserName
            key={collaborator?.id ?? `collaborator-${idx}`}
            user={usersState[collaborator?.userId]}
            currentUser={currentUser}
            suffix={((historyObject?.collaboratorsAdded?.length ?? 0) > idx + 1) ? ', ' : ''}
          />
        )),
      }];
    }
    return [];
  }, [historyObject]);

  const ownershipChange = useMemo((): Change[] => {
    if (historyObject.ownershipChanged) {
      return [{
        type: 'Ownership Changed',
        to: <UserName user={historyObject.ownershipChanged?.to} currentUser={currentUser} />,
      }];
    }
    return [];
  }, [historyObject]);

  const changes = useMemo(() => [
    ...collaboratorChange,
    ...ownershipChange,
    ...historyObject?.changes,
  ], [historyObject, collaboratorChange, ownershipChange]);

  const overflowChangesText = useMemo(() => {
    const numChangesOverLimit = changes?.length - CHANGE_LIMIT;
    return `${numChangesOverLimit} more ${numChangesOverLimit === 1 ? 'change' : 'changes'}`;
  }, [changes]);

  const overflowButtonText = hideOverflow ? `Show ${overflowChangesText}` : 'Hide';

  return (
    <>
      {isRequest && (
        <TimelinePoint noPadding>
          <div>
            <span>Requested by</span>
            <UserName user={historyObject.requestedBy} currentUser={currentUser} />
            <span>on {dayjs(historyObject.requestedOn).local().format('M/D/YY [at] h:mma')}</span>
          </div>
        </TimelinePoint>
      )}
      {changes?.length
        ? (
          <div className={cx({ 'group no-padding no-dot': isRequest })} data-testid='timeline-changes'>
            {changes.slice(0, CHANGE_LIMIT).map(
              (change, idx) => <TimelineChange key={change.type} change={change} noDot={isRequest && idx === 0} />,
            )}
            {/* If length of historyObject.changes > 3, show collapse trigger: */}
            {changes?.length > CHANGE_LIMIT && (
              <>
                <div
                  className={cx({ hidden: hideOverflow, block: !hideOverflow })}
                  data-testid='timeline-changes-change-list'
                >
                  {changes.slice(CHANGE_LIMIT).map(
                    (change) => <TimelineChange key={change.type} change={change} />,
                  )}
                </div>
                <TimelinePoint noDot fadeLine={hideOverflow}>
                  <button type='button' className='text-primary' onClick={toggleOverflow}>{overflowButtonText}</button>
                </TimelinePoint>
              </>
            )}
          </div>
        )
        : <TimelinePoint noDot />}
    </>
  );
}
