import generateChangeLog from '@/state/epics/diffObjectsForTimeline/diffObjects';

// Note: Copied this work from src/state/epics/diffObjectsForTimeline/diffObjectsForTimeline.epic.ts
// Should evaluate and refactor to not need to reshape data on front-end where possible
export function cleanupDiffsForUpdatesColumn (diffs) {
  const formattedHistory = diffs.map((diff) => {
    if (diff.diffType === 'created') {
      return {
        id: diff.id,
        externalID: diff.externalID,
        ownerID: diff.createdBy,
        date: diff.createdAt,
        action: diff.diffType,
        changes: [],
      };
    }
    const { from, to, displayNames } = diff.diff;
    const changes = generateChangeLog(from, to, displayNames, diff.externalType);

    const {
      requestedById,
      requestedOn,
      requestedDiffId,
      addedFiles,
      removedFiles,
      addedLinks,
      removedLinks,
      addedShares,
      removedShares,
    } = diff.diff.to;
    return {
      id: diff.id,
      externalID: diff.externalID,
      ownerID: diff.createdBy,
      date: diff.createdAt,
      action: diff.diffType,
      triggeredByID: diff.triggeredByID,
      requestedById,
      requestedOn,
      requestedDiffId,
      addedFiles,
      removedFiles,
      collaboratorIdsAdded: addedShares,
      collaboratorIdsRemoved: removedShares,
      changes,
      addedLinks,
      removedLinks,
    };
  });

  formattedHistory.sort((a, b) => {
    const dateDiff = new Date(a.date).getTime() - new Date(b.date).getTime();
    if (dateDiff === 0) {
      // If timestamps are the same, sort by action type
      // (migrated diffs for approved/denied/requested actions will have the same date rn)
      return b.action.localeCompare(a.action);
    }
    return dateDiff;
  });
  return formattedHistory;
}
