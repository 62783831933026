import type { ModularObject } from '@/__generated__/types';
import { useDeleteModularObjectsMutation } from '@/components/common/ModularObject/Card/deleteModularObjects.generated';
import FormContainer from '@/components/FormContainer/FormContainer';
import Spinner from '@/components/Icons/Spinner';
import { GetModularObjectsByTemplateIdDocument } from '@/graphql/modularObject/getModularObjectsByTemplateId.generated';
import { useState } from 'react';
import { useListActionContext } from '../../ListActions.context';
import { type ActionMessageProps } from '../constants';
import DeleteMessage from './DeleteMessage';

export default function DeleteConfirmation (
  { permissionMap, onClose }: Readonly<ActionMessageProps>,
): JSX.Element {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');
  const { isOwner } = permissionMap;
  const isNotOwnerOfSelectedTasks = !isOwner.length && !isDeleting;
  const { unregisterObjectChildren } = useListActionContext();
  const [deleteModularObjects] = useDeleteModularObjectsMutation({
    refetchQueries: [GetModularObjectsByTemplateIdDocument],
    optimisticResponse: {
      deleteModularObjects: isOwner.map(obj => obj.id),
    },
    update: (cache, { data }) => {
      if (!data?.deleteModularObjects) return;

      data.deleteModularObjects.forEach((id: string) => {
        cache.evict({ id: cache.identify({ __typename: 'ModularObject', id }) });
        cache.gc();
      });

      cache.gc();
    },
  });

  const handleSubmit = async (e): Promise<void> => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      const result = await deleteModularObjects({
        variables: {
          input: {
            ids: isOwner.map(obj => obj.id),
            shouldDeleteTasks: true,
          },
        },
      });

      const deletedObjectIds = result.data?.deleteModularObjects;
      if (deletedObjectIds) {
        isOwner.forEach((obj: ModularObject) => {
          if (deletedObjectIds.includes(obj.id) && unregisterObjectChildren && obj.parent) {
            unregisterObjectChildren(obj.parent.id, obj.id);
          }
        });
        onClose();
      } else {
        throw new Error('Failed to delete objects');
      }
    } catch (err) {
      console.error(err);
      setError(`Error: ${err.message ?? err.error?.integrations}`);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <FormContainer
      handleSubmit={handleSubmit}
      onClose={onClose}
      headerText={
        <span className='text-primary'>
          {isNotOwnerOfSelectedTasks ? 'Oops! You can\'t do that.' : 'Are you sure?'}
        </span>
      }
      width={isNotOwnerOfSelectedTasks ? 'unset' : '500px'}
      content={<DeleteMessage permissionMap={permissionMap} />}
      buttons={
        <div className='flex flex-col gap-1 grow'>
          <div className='flex gap-3'>
            <button onClick={onClose} className='w-1/2 btn btn-primary-hollow'>
              Cancel
            </button>
            {!isNotOwnerOfSelectedTasks && (
              <button className='w-1/2 btn-primary' disabled={isDeleting}>
                {isDeleting
                  ? (
                    <div className='flex flex-col items-center grow'>
                      <div className='flex gap-2 items-center'>
                        <Spinner className='w-6 h-6 animate-spin' /> Deleting
                      </div>
                    </div>
                  )
                  : 'Yes'}
              </button>
            )}
          </div>
          {Boolean(error) && <div className='text-red-500'>{error}</div>}
        </div>
      }
    />
  );
}
