import { type Change } from '@/components/history/types';
import { BOOLEAN_FIELDS, DATE_FIELDS, IGNORE_FIELDS } from '@/components/tables/ApprovalRow.utils';
import { camelCaseToDisplayText } from '@/util/camelCaseToDisplayText';
import { VEHICLE_MILESTONES_MAP } from '@/util/constants';
import dayjs from 'dayjs';
import { get, isEqual } from 'lodash';
import { is, not } from 'ramda';

const isObject = is(Object);
const isArray = is(Array);

const transformBoolean = (value: boolean) => (value ? 'Yes' : 'No');

const getName = (from, to) => {
  const fromStatusEvent = get(VEHICLE_MILESTONES_MAP, from?.statusType);
  const toStatusEvent = get(VEHICLE_MILESTONES_MAP, to?.statusType);
  return `${
    (
      from?.name ||
      from?.engine?.name ||
      (fromStatusEvent ? `${fromStatusEvent},` : null)
    ) as string || (
      to?.name ||
      to?.engine?.name ||
      (toStatusEvent ? `${toStatusEvent},` : null)
    ) as string ||
    'Unnamed'
  } `;
};

// ! Adding key exceptions here only since they still needs to be ignored for other objects
const KEY_EXCEPTIONS = ['missionId', 'ownerId'];

export default function generateChangeLog (original, changed, displayNames, path?: string, prefix?: string): Change[] {
  const additionalChanges = [];

  if (Object.hasOwn(changed || {}, 'parentID') && Object.hasOwn(changed || {}, 'parentType')) {
    additionalChanges.push({
      type: 'Integrated on',
      from: [original?.parentType, original?.parentID],
      to: [changed?.parentType, changed?.parentID],
      path: 'spacecraft.parentID',
    });
  }

  if (Object.hasOwn(changed || {}, 'customFields')) {
    const originalKeys = Object.keys(original?.customFields || {});
    const changedKeys = Object.keys(changed?.customFields || {});

    const newRemovals = Object.entries(original?.customFields ?? {})
      .filter(([key, _]) => !changedKeys.includes(key));
    const newAdditions = Object.entries(changed?.customFields ?? {})
      .filter(([key, _]) => !originalKeys.includes(key));

    newAdditions.forEach(([_, value]: [string, { name: string }]) => {
      additionalChanges.push({
        type: `Added Custom Field - ${value?.name}`,
        from: null,
        to: `Field Addition Notification`,
        path: 'modular_object.customFields',
      });
    });

    newRemovals.forEach(([_, value]: [string, { name: string }]) => {
      additionalChanges.push({
        type: `Removed Custom Field - ${value?.name}`,
        from: null,
        to: `Field Removal Notification`,
        path: 'modular_object.customFields',
      });
    });
  }

  // console.log('changed', changed);

  if (Object.hasOwn(changed || {}, 'addedFiles')) {
    additionalChanges.push({
      type: 'Added files',
      from: null,
      to: changed?.addedFiles,
      path: 'modular_object.addedFiles',
    });
  }

  if (Object.hasOwn(changed || {}, 'removedFiles')) {
    additionalChanges.push({
      type: 'Removed files',
      from: changed?.removedFiles,
      to: null,
      path: 'modular_object.removedFiles',
    });
  }

  if (Object.hasOwn(changed || {}, 'addedLinks')) {
    additionalChanges.push({
      type: 'Added links',
      from: null,
      to: changed?.addedLinks,
      path: 'modular_object.addedLinks',
    });
  }

  if (Object.hasOwn(changed || {}, 'removedLinks')) {
    additionalChanges.push({
      type: 'Removed links',
      from: changed?.removedLinks,
      to: null,
      path: 'modular_object.removedLinks',
    });
  }

  const changes = Object.keys(changed || {}).reduce((acc, key) => {
    const newPath = path ? `${path}.${key}` : key;

    if ((key in IGNORE_FIELDS && !KEY_EXCEPTIONS.includes(key))) return acc;

    const from = original?.[key];
    const to = changed[key];
    const displayName = displayNames?.[key] ?? (not(isObject(displayNames)) ? displayNames : null) ??
      camelCaseToDisplayText(key);
    const valuesAreEqual = isEqual(from, to);
    if (valuesAreEqual) return acc;

    const type = displayName;
    if (isObject(to) && not(isArray(to))) {
      const objDiff = generateChangeLog(from, to, displayName, newPath, prefix);
      return [...acc, ...objDiff];
    }
    if (isArray(to)) {
      // try to get change object for each item in array
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const diffs = to.reduce((arr: Change[], item: any, idx): Change[] => {
        const fromItem = from?.[idx];
        const name = getName(fromItem, item);
        const nextDisplayNames = displayName?.[idx];
        const objDiff = generateChangeLog(from?.[idx], item, nextDisplayNames, newPath, name);
        return [...arr, ...objDiff];
      }, []) as Change[];
      return [...acc, ...diffs];
    }

    if (get(BOOLEAN_FIELDS, newPath)) {
      return [...acc, { type, from: transformBoolean(from), to: transformBoolean(to), path: newPath }];
    }

    if (DATE_FIELDS.includes(newPath.split('.').pop()) || DATE_FIELDS.includes(from?.name)) {
      const formattedFrom = from?.name && from?.value
        ? dayjs(from?.value ?? from).format('MMM DD, YYYY')
        : from && !from?.name
        ? dayjs(from).format('MMM DD, YYYY')
        : null;
      const formattedTo = to ? dayjs(to).format('MMM DD, YYYY') : null;
      return [...acc, { type, from: formattedFrom, to: formattedTo, path: newPath }];
    }
    const formattedFrom = isObject(from) ? from?.value : from;
    const formattedTo = isObject(to) ? to?.value : to;
    return [...acc, { type, from: formattedFrom, to: formattedTo, path: newPath }];
  }, []);
  return [...changes, ...additionalChanges];
}

// ! use this when data is more standardized
// function convertToTitleCase (str: string): string {
//   const result = str.replace(/([A-Z])/g, ' $1');
//   return result.charAt(0).toUpperCase() + result.slice(1);
// }

// const parsedOriginal = removeIgnoredKeys(original);
// const parsedChanged = removeIgnoredKeys(changed);
// formatChangeLog(generateChangeLog(parsedOriginal, parsedChanged))
