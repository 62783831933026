import type { DiffType, ModularObject } from '@/__generated__/types';
import { useEditContext } from '@/components/cards';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import DatePickerInput, { ParentRangeColor } from '@/components/form/DatePickerInput/DatePickerInput';
import { ConfirmFixedDependencyObjectUpdateModal } from '@/components/modals/ConfirmFixedDependencyObjectUpdateModal/ConfirmFixedDependencyObjectUpdateModal';
import Assignee from '@/components/modules/Assignee';
import DisplayValueController from '@/components/modules/DisplayValueController';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { GetTemplatesUsedByModularObjectsDocument } from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { buildUpdateObjectDiffPayload } from '../../utils';
import DetailItem from './DetailItem';
import Estimation from './Estimation';
import { useGetTotalResourceCostQuery } from './getTotalResourceCost.generated';
import ObjectDuration from './ObjectDuration/ObjectDuration';
import ParentSelectButtonWrapper from './ParentSelectButtonWrapper';
import RequirementLevel from './RequirementLevel';
import TotalResourceCost from './TotalResourceCost';
import { useUpdateObjectCardDatesMutation } from './UpdateObjectCardDates.generated';
import { useUpdateObjectCardStartDateMutation } from './UpdateObjectCardStartDate.generated';
import { useUpdateObjectCardTargetDateMutation } from './UpdateObjectCardTargetDate.generated';
import StartTargetDateLock, { StartOrTargetDate } from './ObjectDates/StartTargetDateLock';
import { ERROR_MESSAGES } from '@/util/constants';

const START_DATE_FIELD_NAME = 'startDate';
const TARGET_DATE_FIELD_NAME = 'targetDate';

function getDuration (startDate: Date | null, targetDate: Date | null) {
  if (startDate && targetDate) {
    const start = dayjs(startDate);
    const target = dayjs(targetDate);

    const newDuration = target.utc().diff(start.utc(), 'day');

    return newDuration || null;
  }

  return null;
}

function getDateValueFromObjectCard(objectCardDataDate) {
  return objectCardDataDate
      ? new Date(objectCardDataDate)
      : null;
}

export default function DetailsSection (): JSX.Element {
  const durationRef = useRef<HTMLInputElement>(null);

  const { canUserEdit } = useEditContext();
  const { objectCardData, activeModularObjectId } = useObjectCardContext();
  const loggedInUser = useLoggedInUser();
  const { canDeleteJiraTask, updateJiraTicket } = usePrismaticContext();
  const { showModal, closeModal } = useModal();

  const resourceCostUnit: string = loggedInUser?.organization?.resourceCostUnit || 'resource cost';
  const isTask = objectCardData.template?.type?.toLowerCase() === 'task';
  const isReq = objectCardData?.template?.name?.toLowerCase() === 'requirements';

  const [lastSavedStartDate, setLastSavedStartDate] = useState<string>(objectCardData.startDate);
  const [lastSavedTargetDate, setLastSavedTargetDate] = useState<string>(objectCardData.targetDate);
  const [isDependencyDurationConfirmed, setDependencyDurationConfirmed] = useState<boolean>(false);

  const [updateStartDate] = useUpdateObjectCardStartDateMutation();
  const [updateTargetDate] = useUpdateObjectCardTargetDateMutation();
  const [updateObjectDates] = useUpdateObjectCardDatesMutation();

  const isObjectOwner = objectCardData?.ownerId === loggedInUser?.id;

  const shouldShowDateChangeConfirmationModal = useMemo(() => {
    if (!objectCardData?.dependencies?.length) {
      return false;
    }

    return objectCardData?.dependencies?.some(dependency => {
      return dependency.blockType === 'strict';
    });
  }, [objectCardData?.dependencies]);

  const [startDate, setStartDate] = useState<Date | null>(getDateValueFromObjectCard(null));
  const [targetDate, setTargetDate] = useState<Date | null>(getDateValueFromObjectCard(null));

  useEffect(() => {
    setStartDate(getDateValueFromObjectCard(objectCardData.startDate));
    setTargetDate(getDateValueFromObjectCard(objectCardData.targetDate));
    setDuration(getDuration(objectCardData.startDate, objectCardData.targetDate));
  }, [objectCardData.startDate, objectCardData.targetDate]);

  const [duration, setDuration] = useState<number | null>(getDuration(startDate, targetDate));

  const handleStartDateChange = useCallback(async (date: string) => {
    const updateStartDatePayload = buildUpdateObjectDiffPayload({
      objectId: objectCardData?.id,
      fieldName: START_DATE_FIELD_NAME,
      newFieldValue: date,
    });

    const isDateSameAsLastSave = dayjs(lastSavedStartDate).format() === dayjs(date).format();
    if (isDateSameAsLastSave) return;

    setLastSavedStartDate(date);
    await updateStartDate({
      variables: { input: { diffs: [updateStartDatePayload] } },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument, GetTemplatesUsedByModularObjectsDocument],
      onCompleted: ({ updateModularObject }) => {
        const [{ approvals }] = updateModularObject ?? [{}];
        const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(approvals);

        const isSaved = !fieldsPendingApprovalMap[START_DATE_FIELD_NAME];

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved start date'
          : 'Successfully requested approval for start date';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: (err) => {
        setStartDate(getDateValueFromObjectCard(objectCardData.startDate));
        let errorMessage = 'Failed to update start date, please try again';
        if (err.graphQLErrors[0]?.extensions?.code === ERROR_MESSAGES.MODULAR_OBJECTS.CANNOT_MODIFY_LOCKED_DATE.CODE
          || err.graphQLErrors[0]?.extensions?.code === ERROR_MESSAGES.DEPENDENCIES.DEPENDENCY_GRAPH_HAS_CONSTRAINT_VIOLATION.CODE
        ) {
          errorMessage = err.message;
        }
        return addToastError(errorMessage);
      },
    });

    if (canDeleteJiraTask) {
      await updateJiraTicket();
    }
  }, [canDeleteJiraTask, lastSavedStartDate, objectCardData?.id, updateJiraTicket, updateStartDate]);

  const handleConfirmDateChange = useCallback(
    async (confirmCallback) => {
      showModal(
        <ConfirmFixedDependencyObjectUpdateModal
          templateName={objectCardData?.template?.name}
          closeModal={closeModal}
          handleConfirm={async () => {
            await confirmCallback();
            closeModal();
          }}
        />,
        {
          className: '!max-w-[360px]',
        },
      );
    },
    [showModal, closeModal, objectCardData?.template?.name],
  );

  const handleTargetDateChange = useCallback(
    async (date: string) => {
      const updateTargetDatePayload = buildUpdateObjectDiffPayload({
        objectId: objectCardData?.id,
        fieldName: TARGET_DATE_FIELD_NAME,
        newFieldValue: date,
      });
      const isDateSameAsLastSave = dayjs(date).format() === dayjs(lastSavedTargetDate).format();

      if (isDateSameAsLastSave) return;
      setLastSavedTargetDate(date);

      await updateTargetDate({
        variables: { input: { diffs: [updateTargetDatePayload] } },
        refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
        onCompleted: ({ updateModularObject }) => {
          const [{ approvals }] = updateModularObject ?? [{}];
          const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(approvals);
          const isSaved = !fieldsPendingApprovalMap[TARGET_DATE_FIELD_NAME];

          const SUCCESS_MESSAGE = isSaved
            ? 'Successfully saved target date'
            : 'Successfully requested approval for target date';

          addToastSuccess(SUCCESS_MESSAGE);
        },
        onError: (err) => {
          setTargetDate(getDateValueFromObjectCard(objectCardData.targetDate));
          let errorMessage = 'Failed to update target date, please try again';
          if (err.graphQLErrors[0]?.extensions?.code === ERROR_MESSAGES.MODULAR_OBJECTS.CANNOT_MODIFY_LOCKED_DATE.CODE
            || err.graphQLErrors[0]?.extensions?.code === ERROR_MESSAGES.DEPENDENCIES.DEPENDENCY_GRAPH_HAS_CONSTRAINT_VIOLATION.CODE
          ) {
            errorMessage = err.message;
          }
          return addToastError(errorMessage);
        },
      });
      if (canDeleteJiraTask) {
        await updateJiraTicket();
      }
    },
    [canDeleteJiraTask, lastSavedTargetDate, objectCardData?.id, updateJiraTicket, updateTargetDate],
  );

  const handleLockedDatesChange = useCallback(async (newStartDate: string, newTargetDate: string) => {
    if (!startDate || !targetDate) return;

    const diff = {
      externalID: objectCardData?.id,
      externalType: 'modular_object',
      diff: {
        to: {
          startDate: newStartDate,
          targetDate: newTargetDate,
        },
        displayNames: { startDate: 'startDate', targetDate: 'targetDate' },
      },
      fromMigration: false,
      diffType: 'updated' as DiffType,
    };

    await updateObjectDates({
      variables: { input: { diffs: [diff] } },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
      onCompleted: ({ updateModularObject }) => {
        const [{ approvals }] = updateModularObject ?? [{}];
        const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(approvals);
        const isSaved = !fieldsPendingApprovalMap[START_DATE_FIELD_NAME] &&
          !fieldsPendingApprovalMap[TARGET_DATE_FIELD_NAME];

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved start and target dates'
          : 'Successfully requested approval for start and target dates';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: (err) => {
        setStartDate(getDateValueFromObjectCard(objectCardData.startDate));
        setTargetDate(getDateValueFromObjectCard(objectCardData.targetDate));
        let errorMessage = 'Failed to update start and target dates, please try again';
        if (err.graphQLErrors[0]?.extensions?.code === ERROR_MESSAGES.MODULAR_OBJECTS.CANNOT_MODIFY_LOCKED_DATE.CODE
          || err.graphQLErrors[0]?.extensions?.code === ERROR_MESSAGES.DEPENDENCIES.DEPENDENCY_GRAPH_HAS_CONSTRAINT_VIOLATION.CODE
        ) {
          errorMessage = err.message;
        }
        return addToastError(errorMessage);
      }
    });
    if (canDeleteJiraTask) {
      await updateJiraTicket();
    }
  }, [canDeleteJiraTask, objectCardData?.id, startDate, targetDate, updateJiraTicket, updateObjectDates]);

  useEffect(() => {
    handleDurationChange();
  }, [startDate, targetDate]);

  const {
    ownerId: parentOwnerId,
    owner: parentOwner,
  } = objectCardData?.parent ?? {};

  const parentStartDate = useMemo(() => {
    const { startDate } = objectCardData?.parent ?? {};

    return dayjs(startDate).toDate();
  }, [objectCardData?.parent]);

  const parentTargetDate = useMemo(() => {
    const { targetDate } = objectCardData?.parent ?? {};

    return dayjs(targetDate).toDate();
  }, [objectCardData?.parent]);

  let parentRangeColor = ParentRangeColor.Default;

  const isInternal = loggedInUser?.organizationId === parentOwner?.organizationId;

  if (parentOwnerId && !isInternal) {
    parentRangeColor = ParentRangeColor.External;
  } else if (parentOwnerId && isInternal) {
    parentRangeColor = ParentRangeColor.Internal;
  }

  const { data: modularObjectResourceCostData } = useGetTotalResourceCostQuery({
    skip: !activeModularObjectId || isTask,
    variables: {
      id: activeModularObjectId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const totalResourceCost = modularObjectResourceCostData?.getModularObjectByID?.totalResourceCost || 0;

  const handleDurationChange = useCallback(() => {
    if (objectCardData?.durationLocked) return;

    setDuration(getDuration(startDate, targetDate));
  }, [startDate, targetDate, objectCardData?.durationLocked]);

  return (
    <div className='grid grid-cols-2 gap-y-1 gap-x-8 px-[24px]'>
      <div className='flex flex-col gap-y-1'>
        <DetailItem label='Start Date' name='startDate'>
          <div className='flex gap-x-1 items-center'>
            <DatePickerInput
              id={START_DATE_FIELD_NAME}
              endDate={targetDate}
              parentStartDate={parentStartDate}
              parentEndDate={parentTargetDate}
              parentRangeColor={parentRangeColor}
              name={START_DATE_FIELD_NAME}
              value={startDate}
              onChange={(e) => {
                objectCardData?.durationLocked
                  ? handleLockedDatesChange(e, dayjs(e).add(duration, 'day').format())
                  : handleStartDateChange(e);
              }}
              onConfirmDateChange={shouldShowDateChangeConfirmationModal ? handleConfirmDateChange : null}
              placeholder='Enter start date'
              className='max-w-[15rem]'
              isLocked={objectCardData?.startDateLocked}
            />
            <StartTargetDateLock
              isObjectOwner={isObjectOwner}
              startOrTarget={StartOrTargetDate.start}
              objectCardData={objectCardData as ModularObject}
            />
          </div>
        </DetailItem>
        <DetailItem label='Target Date' name='targetDate'>
          <div className='flex gap-x-1 items-center'>
            <DatePickerInput
              id={TARGET_DATE_FIELD_NAME}
              startDate={startDate}
              parentStartDate={parentStartDate}
              parentEndDate={parentTargetDate}
              parentRangeColor={parentRangeColor}
              value={targetDate}
              onChange={(e) => {
                objectCardData?.durationLocked
                  ? handleLockedDatesChange(dayjs(e).subtract(duration, 'day').format(), e)
                  : handleTargetDateChange(e);
              }}
              onConfirmDateChange={shouldShowDateChangeConfirmationModal ? handleConfirmDateChange : null}
              name={TARGET_DATE_FIELD_NAME}
              placeholder='Enter target date'
              className='max-w-[15rem]'
              isLocked={objectCardData?.targetDateLocked}
            />
            <StartTargetDateLock
              isObjectOwner={isObjectOwner}
              startOrTarget={StartOrTargetDate.target}
              objectCardData={objectCardData as ModularObject}
            />
          </div>
        </DetailItem>
        <ObjectDuration
          ref={durationRef}
          duration={duration?.toString()}
          objectCardData={objectCardData as ModularObject}
          setDuration={(duration) => setDuration(Number(duration))}
          updateTargetDate={handleTargetDateChange}
          updateStartDate={handleStartDateChange}
          onFocus={() => {
            if (!isDependencyDurationConfirmed && shouldShowDateChangeConfirmationModal) {
              showModal(
                <ConfirmFixedDependencyObjectUpdateModal
                  templateName={objectCardData?.template?.name}
                  closeModal={() => {
                    // Blur the input so the user has to click it again and go through the modal again
                    closeModal();
                    setTimeout(() => {
                      durationRef?.current?.blur();
                    }, 500); // Hack because blurring direct afterwards doesn't work
                  }}
                  handleConfirm={async () => {
                    setDependencyDurationConfirmed(true);
                    closeModal();
                  }}
                />,
              );
            }
          }}
          afterChange={() => {
            setDependencyDurationConfirmed(false);
          }}
        />
        {isTask && !isReq && (
          <DetailItem label='Estimation' name='resourceCost'>
            <Estimation />
          </DetailItem>
        )}
        {!isTask && Boolean(totalResourceCost) && (
          <DetailItem label={`driver ${resourceCostUnit}`}>
            <TotalResourceCost cost={totalResourceCost} />
          </DetailItem>
        )}
      </div>
      <div className='flex flex-col gap-y-1'>
        <DetailItem label='Company'>
          <DisplayValueController
            value='ownerOrganization'
            className='text-[14px]'
            labelProps={{ name: 'ownerOrganization' }}
          />
        </DetailItem>
        <DetailItem label='Parent' contentClassName='truncate'>
          <ParentSelectButtonWrapper />
        </DetailItem>
        {isTask && (
          <>
            {isReq && (
              <DetailItem
                label='Requirement Level'
                name='Requirement Level'
                labelClassName={canUserEdit ? 'mr-[6px]' : ''}
              >
                <RequirementLevel />
              </DetailItem>
            )}
            {!isReq && (
              <DetailItem label='Assignee'>
                <Assignee name='assigneeId' className='font-medium text-[14px] font-effra' />
              </DetailItem>
            )}
          </>
        )}
      </div>
    </div>
  );
}
