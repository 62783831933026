import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, type IconDefinition } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';

export interface AlertProps {
  children?: React.ReactNode;
  icon?: IconDefinition;
  className?: string;
}

export default function Alert ({ children, icon = faPlus, className }: AlertProps): JSX.Element {
  return (
    <div className={cx('flex items-center w-auto bg-alluringLight shrink gap-[8px] py-[4px] px-[6px]', className)}>
      <FontAwesomeIcon icon={icon} />
      <span>
        {children}
      </span>
    </div>
  );
}
