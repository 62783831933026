import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type CreateLinksMutationVariables = Types.Exact<{
  input: Array<Types.CreateLinkInput> | Types.CreateLinkInput;
}>;


export type CreateLinksMutation = { __typename?: 'Mutation', createLinks: Array<{ __typename?: 'Link', id: string, title: string | null, url: string, modularObjectId: string, createdAt: any, createdById: string, modifiedAt: any, modifiedById: string | null, deletedAt: any | null }> };


export const CreateLinksDocument = gql`
    mutation CreateLinks($input: [CreateLinkInput!]!) {
  createLinks(input: $input) {
    id
    title
    url
    modularObjectId
    createdAt
    createdById
    modifiedAt
    modifiedById
    deletedAt
  }
}
    `;
export type CreateLinksMutationFn = Apollo.MutationFunction<CreateLinksMutation, CreateLinksMutationVariables>;

/**
 * __useCreateLinksMutation__
 *
 * To run a mutation, you first call `useCreateLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinksMutation, { data, loading, error }] = useCreateLinksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinksMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinksMutation, CreateLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLinksMutation, CreateLinksMutationVariables>(CreateLinksDocument, options);
      }
export type CreateLinksMutationHookResult = ReturnType<typeof useCreateLinksMutation>;
export type CreateLinksMutationResult = Apollo.MutationResult<CreateLinksMutation>;
export type CreateLinksMutationOptions = Apollo.BaseMutationOptions<CreateLinksMutation, CreateLinksMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateLinksMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createLinks })
 *   )
 * })
 */
export const mockCreateLinksMutation = (resolver: Parameters<typeof graphql.mutation<CreateLinksMutation, CreateLinksMutationVariables>>[1]) =>
  graphql.mutation<CreateLinksMutation, CreateLinksMutationVariables>(
    'CreateLinks',
    resolver
  )
