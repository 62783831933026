import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { ScheduleBaseDependencyFragmentDoc } from '../../../graphql/fragments/dependency.generated';
export type TaskFragment = { __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, templateId: string | null, assigneeId: string | null, ownerId: string | null, visibility: string | null, deactivated: boolean | null, status: Types.TaskStatus | null, statusModuleId: string | null, approvalsEnabled: boolean | null, permission: Types.Permission | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, assignee: { __typename?: 'User', id: string, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null }> | null }> | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null }> | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: Types.ShareRole | null, userId: string | null }> | null };

export const TaskFragmentDoc = gql`
    fragment Task on ModularObject {
  id
  name
  startDate
  targetDate
  templateId
  assigneeId
  ownerId
  visibility
  deactivated
  status
  statusModuleId
  approvalsEnabled
  owner(includeDeleted: true) {
    id
    organizationId
  }
  assignee(includeDeleted: true) {
    id
    organizationId
  }
  parent {
    id
    name
  }
  children {
    id
    name
    dependencies {
      ...ScheduleBaseDependency
    }
  }
  dependencies {
    ...ScheduleBaseDependency
  }
  template {
    id
    name
    type
    subType
  }
  permission
  shares {
    id
    role
    userId
  }
}
    ${ScheduleBaseDependencyFragmentDoc}`;