'use client';

import { Loader } from '@/components';
import Color from '@/components/types/Color.enum';
import cx from 'classnames';
import { type ButtonHTMLAttributes, type PropsWithChildren } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  figmaProps: {
    style: 'fill' | 'ghost' | 'outline' | 'text';
    size: keyof typeof sizeStyles;
  };
  disabled?: boolean;
  className?: string;
  color?: Color;
  innerClassName?: string;
  isLoading?: boolean;
  overrideBase?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const sizeStyles = {
  xsmall: 'h-[28px] py-[8px] px-[16px]',
  small: 'h-[36px] py-[12px] px-[16px]',
  large: 'h-[52px] py-[20px] px-[24px]',
};

const stylePropertyStyles = {
  primary: {
    fill: 'bg-primary text-white border border-primary hover:bg-primary-hover',
    ghost: 'text-primary hover:border hover:border-primary hover:text-primary-hover border-transparent border',
    outline: 'bg-transparent text-primary border border-primary hover:border-primary-hover hover:text-primary-hover ',
    text: 'text-primary hover:bg-gray-90 focus:border-[1px] focus:border-gray-90',
  },
  secondary: {
    fill: 'bg-secondary text-white border border-secondary hover:bg-secondary-hover',
    ghost: 'text-secondary hover:border hover:border-secondary hover:text-secondary-hover border-transparent border',
    outline:
      'bg-transparent text-secondary border border-secondary hover:border-secondary-hover hover:text-secondary-hover',
    text: 'text-secondary hover:bg-gray-90 focus:border-[1px] focus:border-gray-90',
  },
  pink: {
    fill: 'bg-pink text-white border border-pink hover:bg-pink-hover',
    ghost: 'text-pink hover:border hover:border-pink hover:text-pink-hover border-transparent border',
    outline: 'bg-transparent text-pink border border-pink hover:border-pink-hover hover:text-pink-hover',
    text: 'text-pink hover:bg-gray-90 focus:border-[1px] focus:border-gray-90',
  },
  black: {
    fill: 'bg-black text-white border border-black hover:bg-black-hover',
    ghost: 'text-black hover:border hover:border-black hover:text-black-hover',
    outline: 'bg-transparent text-black border border-black hover:border-black-hover hover:text-black-hover',
    text: 'text-gray-40 hover:bg-gray-90 hover:text-primary focus:border-[1px] focus:border-gray-90 focus:text-primary',
  },
};

export const Button = (
  {
    disabled = false,
    figmaProps: { size, style },
    children,
    className = '',
    color = Color.PRIMARY,
    innerClassName = '',
    isLoading = false,
    overrideBase = false,
    type = 'button',
    ...rest
  }: PropsWithChildren<ButtonProps>,
) => {
  return (
    <button
      disabled={disabled || isLoading}
      className={cx(
        'relative',
        {
          'btn-base': !overrideBase,
        },
        className,
        stylePropertyStyles[color][style],
        sizeStyles[size],
      )}
      type={type}
      {...rest}
    >
      <div className={cx({ 'invisible': isLoading }, innerClassName)}>
        {children}
      </div>
      {isLoading && (
        <Loader className='absolute w-[30px] h-[30px]' color={style === 'fill' ? Color.WHITE : Color.PRIMARY} />
      )}
    </button>
  );
};
