import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { KeyboardEventNames } from '@/util/constants';
import cx from 'classnames';
import { useRef } from 'react';

export interface KeyboardNavigableUserListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addUser: (user: any) => void;
  shouldShow?: boolean;
  noResultsMessage?: () => JSX.Element;
  shouldShowAnonymousUserMessage?: boolean;
}

export function KeyboardNavigableUserList (
  { users, addUser, shouldShow = true, noResultsMessage = NoResultsMessage, shouldShowAnonymousUserMessage }:
    KeyboardNavigableUserListProps,
) {
  const listItemRefMap = useRef(null);

  function getUserMap () {
    if (!listItemRefMap.current) {
      listItemRefMap.current = new Map();
    }

    return listItemRefMap.current;
  }

  function handleFocusUserListItem (user, idx) {
    return e => {
      const map = getUserMap();
      const currentItemKey = `${user.id}-${idx}`;
      const {
        prevUserId,
        node: currentItem,
        nextUserId,
      } = map.get(currentItemKey);

      const eventDelegationMap = {
        [KeyboardEventNames.ArrowDown]: () => map.get(nextUserId).node.focus(),
        [KeyboardEventNames.ArrowUp]: () => map.get(prevUserId).node.focus(),
        [KeyboardEventNames.Enter]: () => currentItem.click(),
      };

      eventDelegationMap[e.key]();
    };
  }

  const sortedUsers = users.toSorted((a, b) => a.fullName.localeCompare(b.fullName));

  if (!shouldShow) return null;

  return (
    <div
      id='navigable-user-list'
      className={cx(
        'bg-white min-h-[40px] max-h-[230px] overflow-scroll py-1 mt-2 border rounded-[2px] shadow-heavy border-gray-90',
      )}
    >
      {shouldShowAnonymousUserMessage && <AnonymousUserMessage />}
      {!sortedUsers.length && !shouldShowAnonymousUserMessage && (noResultsMessage())}
      {!shouldShowAnonymousUserMessage && sortedUsers.map((user, idx, array) => {
        return (
          <button
            key={user?.id}
            className={cx(
              'w-full gap-2 p-2 cursor-pointer effra-12 text-primary focus:bg-gray-200 focus:outline-none hover:bg-gray-200',
              {
                'text-tertiary': user.isExternal,
              },
            )}
            onKeyDown={handleFocusUserListItem(user, idx)}
            tabIndex={0}
            ref={el => {
              const map = getUserMap();

              if (el) {
                /**
                 * this creates a linked list of dom nodes, which can
                 * be navigated by using the arrow keys. The nodes are stored
                 * in a map by user id, coupled with the list index.
                 *
                 * Each node value is a reference the the node itself, as well
                 * as the ids of the previous and next node.
                 *
                 * The benefit of this is we can get a reference to the exact
                 * node we want to act on, and be very explicit when doing so
                 * while also staying within the abstraction of React.
                 */
                const itemData = {
                  prevUserId: `${array[idx - 1]?.id}-${idx - 1}`,
                  node: el,
                  nextUserId: `${array[idx + 1]?.id}-${idx + 1}`,
                };

                map.set(`${user.id}-${idx}`, itemData);
              } else {
                map.delete(`${user.id}-${idx}`);
              }
            }}
            onClick={() => {
              addUser(user);
            }}
          >
            <span className='flex gap-4 items-center text-left effra-12'>
              <ProfileImage user={user} size='small' />
              <span className='flex flex-col'>
                <p>{user.fullName}</p>
                <p className='text-neutral-400 text-[10px]'>{user.email}</p>
              </span>
            </span>
          </button>
        );
      })}
    </div>
  );
}

export function NoResultsMessage (): JSX.Element {
  return (
    <div className='flex flex-col justify-center items-center px-2 w-full text-center h-[40px] text-gray-60'>
      <p className='flex gap-2'>
        <i className='fa-sharp fa-light text-gray-40 fa-face-smile-upside-down' />
        No collaborators found.
      </p>
      <p>Add external collaborators with their email.</p>
    </div>
  );
}

export function AnonymousUserMessage () {
  return (
    <div className='flex justify-center items-center px-2 w-full h-[60px] text-gray-60'>
      <div className='flex gap-2'>
        <i className='fa-sharp fa-light text-gray-40 fa-face-smile-upside-down' />
        <p>
          This person isn&apos;t on Integrate yet. They will be sent a <span className='font-bold'>view-only</span>{' '}
          link via email. They can collaborate by creating an account.
        </p>
      </div>
    </div>
  );
}
