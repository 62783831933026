import dataURLtoBlob from '@/util/dataUrlToBlob';
import loadImage from '@/util/loadImage';
import { type Editor } from '@tiptap/react';
import cx from 'classnames';
import Pica from 'pica';
import { type ButtonHTMLAttributes, type DetailedHTMLProps, type PropsWithChildren, useRef } from 'react';
import { addToastError } from '../Toast/utils';

const MAX_WIDTH = 700;

const pica = new Pica();

interface MenuItemProps {
  onClick?: () => void;
  editor: Editor;
  allowDisable?: boolean;
}

const MenuItem = (
  { children, onClick, editor, allowDisable = true, ...rest }: PropsWithChildren<
    MenuItemProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  >,
) => {
  if (!editor) {
    return null;
  }

  const shouldDisableButtons = !editor.isEditable || editor.isEmpty;

  return (
    <button
      disabled={allowDisable ? shouldDisableButtons : false}
      type='button'
      className={cx('p-1 rounded transition-all', { 'hover:bg-neutral-200': !shouldDisableButtons })}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

const SeperatorLine = () => {
  return <div className='border-r border-black w-[1px]' />;
};

interface ToolbarProps {
  editor: Editor;
  onImageUpload?: (file: File) => Promise<string>;
}

export const Toolbar = ({ editor, onImageUpload }: ToolbarProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e) => {
    const uploadErrorMessage = 'There was an error uploading your image.';
    const file = e.target.files?.[0];

    // check if file is an image
    if (!file) {
      addToastError(uploadErrorMessage);
      return;
    }

    const fileType = file.type.split('/')[0];

    if (fileType !== 'image') {
      addToastError('Please select a valid image file.');
      return;
    }

    const canvas = document.createElement('canvas');

    canvas.width = MAX_WIDTH;
    canvas.height = MAX_WIDTH;

    // Get height based off image aspect ratio with width == MAX_WIDTH
    try {
      // Resize image
      const loadedImage = await loadImage(file);
      const aspectRatio = loadedImage.height / loadedImage.width;

      canvas.height = Math.round(MAX_WIDTH * aspectRatio);

      const resizedImageCanvas = await pica.resize(loadedImage, canvas);
      const dataURL = resizedImageCanvas.toDataURL(); // Changes the file type to an image/png
      const newFileName = `${file.name.split('.')[0] as string}.png`;

      const blob = dataURLtoBlob(dataURL);
      const resizedFile = new File([blob], newFileName, { type: file.type });

      if (onImageUpload) {
        const imageUrl = await onImageUpload(resizedFile);

        editor.chain().focus().setImage({ src: imageUrl }).run();

        // This field only saves on blur, and for some reason tiptap's "onUpdate" handler fires before this setImage command is finished running
        // so this makes sure we actually re-focus the field so it can be blurred and then saved.
        editor.commands.focus();
      }
    } catch (error) {
      console.error(error);
      addToastError(uploadErrorMessage);
    }
  };

  return (
    <div className='flex gap-2 py-1 mb-2'>
      <MenuItem
        editor={editor}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <i className='fa-kit fa-solid fa-bold' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleItalic().run()}>
        <i className='fa-kit fa-solid fa-italic' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleStrike().run()}>
        <i className='fa-solid fa-strikethrough' />
      </MenuItem>
      <SeperatorLine />
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleBulletList().run()}>
        <i className='fa-solid fa-list-ul' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => editor.chain().focus().toggleOrderedList().run()}>
        <i className='fa-solid fa-list-ol' />
      </MenuItem>
      <MenuItem editor={editor} onClick={() => fileInputRef.current?.click()} allowDisable={false}>
        <i className='fa-sharp fa-solid fa-image' />
      </MenuItem>
      <input
        ref={fileInputRef}
        type='file'
        onChange={handleFileChange}
        className='hidden'
      />
    </div>
  );
};
