import type * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type DeleteModularObjectsMutationVariables = Types.Exact<{
  input: Types.DeleteModularObjectsInput;
}>;


export type DeleteModularObjectsMutation = { __typename?: 'Mutation', deleteModularObjects: Array<string> };


export const DeleteModularObjectsDocument = gql`
    mutation DeleteModularObjects($input: DeleteModularObjectsInput!) {
  deleteModularObjects(input: $input)
}
    `;
export type DeleteModularObjectsMutationFn = Apollo.MutationFunction<DeleteModularObjectsMutation, DeleteModularObjectsMutationVariables>;

/**
 * __useDeleteModularObjectsMutation__
 *
 * To run a mutation, you first call `useDeleteModularObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModularObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModularObjectsMutation, { data, loading, error }] = useDeleteModularObjectsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteModularObjectsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModularObjectsMutation, DeleteModularObjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModularObjectsMutation, DeleteModularObjectsMutationVariables>(DeleteModularObjectsDocument, options);
      }
export type DeleteModularObjectsMutationHookResult = ReturnType<typeof useDeleteModularObjectsMutation>;
export type DeleteModularObjectsMutationResult = Apollo.MutationResult<DeleteModularObjectsMutation>;
export type DeleteModularObjectsMutationOptions = Apollo.BaseMutationOptions<DeleteModularObjectsMutation, DeleteModularObjectsMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteModularObjectsMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ deleteModularObjects })
 *   )
 * })
 */
export const mockDeleteModularObjectsMutation = (resolver: Parameters<typeof graphql.mutation<DeleteModularObjectsMutation, DeleteModularObjectsMutationVariables>>[1]) =>
  graphql.mutation<DeleteModularObjectsMutation, DeleteModularObjectsMutationVariables>(
    'DeleteModularObjects',
    resolver
  )
