import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
export type ScheduleBaseDependencyFragment = { __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null };

export const ScheduleBaseDependencyFragmentDoc = gql`
    fragment ScheduleBaseDependency on Dependency {
  id
  modularObjectId
  blockedById
  blockType
  activeAt
  gapDays
  gapMonths
  gapYears
}
    `;