import type { Document } from '@/__generated__/types';
import getAppUrl from '@/util/getAppUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFileAudio,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faLinkSimple,
} from '@fortawesome/sharp-regular-svg-icons';
import { useEffect, useState } from 'react';

const documentIconMap = {
  csv: faFileExcel,
  xlsx: faFileExcel,
  pdf: faFilePdf,
  jpg: faFileImage,
  png: faFileImage,
  gif: faFileImage,
  webp: faFileImage,
  mp4: faFileVideo,
  mov: faFileVideo,
  avi: faFileVideo,
  wmv: faFileVideo,
  mpg: faFileVideo,
  mpeg: faFileVideo,
  m4v: faFileVideo,
  mkv: faFileVideo,
  webm: faFileVideo,
  wav: faFileAudio,
  mp3: faFileAudio,
};

export default function DocumentRowIcon ({ document }: { document: Document }) {
  const [favicon, setFavicon] = useState(null);

  useEffect(() => {
    if (document.type === 'link') {
      fetch(`${getAppUrl()}/api/links/favicon?url=${document.url}`)
        .then((res) => {
          // If 204 status is returned, we did not find a favicon, and we didn't want to throw a 404 and fill up the network requests with a bunch of 404s
          if (res.status === 204) {
            return null;
          }
          return res.blob();
        })
        .then((data) => {
          setFavicon(URL.createObjectURL(data));
        })
        .catch((err) => console.error(err));
    }
  }, [document.type, document.url]);

  if (document.type === 'file') {
    const fileExtension = document.name.split('.').pop();
    const icon = documentIconMap[fileExtension] || faFile;

    return <FontAwesomeIcon icon={icon} />;
  }

  if (favicon) {
    // eslint-disable-next-line @next/next/no-img-element
    return <img src={favicon} alt='favicon' width={14} height={14} />;
  }

  // Final case is a link with no favicon
  return <FontAwesomeIcon icon={faLinkSimple} />;
}
