import {
  useGetUserCountAndModularObjectCountQuery,
} from '@/graphql/sideNavigation/getUserCountAndModularObjectCount.generated';
import { useLoggedInSubscription, useLoggedInUserRole } from '@/hooks/useLoggedInUser';
import { HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES } from '@/util/constants';
import dayjs from 'dayjs';

export default function useSubscriptionInfo () {
  const { isSuper, isAdmin } = useLoggedInUserRole();
  const { data: userAndModularObjectCountData } = useGetUserCountAndModularObjectCountQuery();
  const isAdminOrSuper = isSuper || isAdmin;
  const currentSubscription = useLoggedInSubscription();

  const shouldHidePricingInfo = currentSubscription?.type === 'pro' || !isAdminOrSuper;

  const userAndModularObjectCount = userAndModularObjectCountData?.getUserCountAndModularObjectCount;

  const planName = HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES[currentSubscription?.type] ?? null;

  const buildCountDisplay = `${userAndModularObjectCount?.buildCount ?? 0}/${
    currentSubscription?.featureLimits?.OBJECTS_BUILDS ?? 'unlimited'
  }`;

  const driverCountDisplay = `${userAndModularObjectCount?.driverCount ?? 0}/${
    currentSubscription?.featureLimits?.OBJECTS_DRIVERS ?? 'unlimited'
  }`;

  const userCountDisplay = `${userAndModularObjectCount?.userCount ?? 0}/${
    currentSubscription?.featureLimits?.USERS ?? 'unlimited'
  }`;

  const remainingTrialDays = currentSubscription?.trialEndDate
    ? dayjs(currentSubscription?.trialEndDate).diff(dayjs(), 'day')
    : 0;

  const isTrial = currentSubscription?.type === 'trial';
  const isBasic = currentSubscription?.type === 'basic';
  const isPro = currentSubscription?.type === 'pro';

  return {
    planName,
    buildCountDisplay,
    driverCountDisplay,
    userCountDisplay,
    shouldHidePricingInfo,
    remainingTrialDays,
    isTrial,
    isBasic,
    isPro,
  };
}
