import { KeyboardEventNames, THEME_COLORS } from '@/util/constants';
import cx from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import useClickAway from './hooks/useClickAway';
import { KeyboardNavigableUserList } from './KeyboardNavigableUserList';

interface UserSearchBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUsers: (users: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addedUsers: any[];
  removeUser: (userId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addUser: (user: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserByEmail?: (options: any) => Promise<any>;
  userList?: typeof KeyboardNavigableUserList;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialUsers?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectOwner?: any;
  disableExternalUsers?: boolean;
  disableAnonymousUsers?: boolean;
}

export default function UserSearchBar ({
  setUsers,
  users,
  addedUsers,
  objectOwner,
  initialUsers,
  removeUser,
  addUser,
  getUserByEmail,
  userList = KeyboardNavigableUserList,
  placeholder = 'Add people or emails...',
  disableExternalUsers = false,
  disableAnonymousUsers = true,
}: UserSearchBarProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showList, setShowList] = useState(false);

  const inputRef = useRef(null);
  const inputIconRef = useRef(null);
  const lastUserAddedRef = useRef(null);
  const userDisplayRef = useRef(null);

  const containerRef = useClickAway(() => {
    setShowList(false);
  });

  function handleSetOrRemoveUsers (userToRemove) {
    if (userToRemove?.isExternal) {
      setUsers({
        addedUsers: addedUsers.filter(user => user.id !== userToRemove.id),
        userList: users.filter(user => user.id !== userToRemove.id),
      });
      inputRef.current.focus();
    } else {
      removeUser(userToRemove.id);
      inputRef.current.focus();
    }
  }

  function handleFocusedUserPill (e) {
    const isValidKey = e.key === KeyboardEventNames.Backspace || e.key === KeyboardEventNames.Enter;

    if (isValidKey && lastUserAddedRef.current === document.activeElement) {
      handleSetOrRemoveUsers(addedUsers.at(-1));
    }
  }

  function handleInputKeydown (e) {
    if (
      e.key === KeyboardEventNames.Backspace && searchTerm === '' &&
      lastUserAddedRef.current !== document.activeElement
    ) {
      e.preventDefault();
      lastUserAddedRef.current?.focus();
    }

    if (e.key === KeyboardEventNames.Enter && searchTerm !== '') {
      e.preventDefault();
      const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value.trim());
      const isInternalUser = Boolean(users.find(user => user.email === e.target.value));
      const isAlreadyAdded = Boolean(addedUsers.find(
        addedUser => addedUser.email === e.target.value,
      ));

      if (isValidEmail && !isInternalUser && !isAlreadyAdded && !disableExternalUsers) {
        fetchUserByEmail(e.target.value);
      } else {
        addUser(searchTerm);
        setSearchTerm('');
      }
      inputRef.current.focus();
    }
  }

  function handleInputFocus () {
    if (userDisplayRef.current && inputIconRef.current) {
      userDisplayRef.current.style.borderBottom = `1px solid ${THEME_COLORS.primary.DEFAULT}`;
      inputIconRef.current.style.color = 'black';
    }
    setShowList(true);
  }

  const fetchUserByEmail = useCallback(
    async emailAddress => {
      const res = await getUserByEmail({
        variables: {
          emails: [emailAddress],
        },
      });

      if (res?.data?.getUsersByEmail?.length === 0 && !disableAnonymousUsers) {
        addUser({
          fullName: emailAddress,
          email: emailAddress,
          isAnonymousUser: true,
        });

        setSearchTerm('');
      }
    },
    [addUser, disableAnonymousUsers, getUserByEmail],
  );

  async function handleInputChange (e) {
    setSearchTerm(e.target.value);

    if (e.target.value === '') {
      const internalUsers = users.filter(user => !user.isExternal);
      setUsers((prev) => ({ ...prev, userList: internalUsers }));
    }
  }

  function handleInputBlur () {
    if (userDisplayRef.current && inputIconRef.current) {
      userDisplayRef.current.style.borderBottom = '1px solid #cccccc';
      inputIconRef.current.style.color = THEME_COLORS.gray['70'];
    }

    const emailInput = searchTerm;

    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInput.trim());
    const isInternalUser = Boolean(users.find(user => user.email === emailInput));
    const isAlreadyAdded = Boolean(addedUsers.find(
      addedUser => addedUser.email === emailInput,
    ));

    if (isValidEmail && !isInternalUser && !isAlreadyAdded && !disableExternalUsers) {
      fetchUserByEmail(emailInput);
    }
  }

  const filteredUsers = useMemo(() => {
    const filtered = users.filter(user => {
      const trimmed = searchTerm?.trim()?.toLowerCase() ?? '';
      const term = user.isExternal ? trimmed.slice(0, trimmed.indexOf('@')) : trimmed;
      const userIsOwner = objectOwner?.id === user.id;

      const isAlreadyAdded = Boolean(addedUsers.find(
        addedUser => Boolean(addedUser.id === user.id),
      ));

      const isAlreadyACollaborator = Boolean(initialUsers.find(
        collaborator => collaborator?.user?.id === user?.id,
      ));
      const termMatchesUserName = user.fullName?.toLowerCase().includes(term);
      const termMatchesUserEmail = user?.email?.toLowerCase().includes(term);

      return (termMatchesUserEmail || termMatchesUserName) && !isAlreadyAdded && !userIsOwner &&
        !isAlreadyACollaborator;
    });

    return filtered;
  }, [users, searchTerm, addedUsers, initialUsers, objectOwner]);
  const handleAddUser = useCallback((user) => {
    addUser(user);
    setSearchTerm('');
    inputRef.current.focus();
  }, [addUser]);

  const hasAnonymousUsers = addedUsers.some(user => user.isAnonymousUser);

  return (
    <div ref={containerRef} className='relative grow'>
      <div
        ref={userDisplayRef}
        className='flex flex-wrap gap-2 items-center p-2 w-full bg-white border resize-none border-gray-80 focus:border-b-primary:outline-none'
      >
        {addedUsers.map((user, idx, array) => (
          <button
            key={user?.id}
            ref={idx === array.length - 1 ? lastUserAddedRef : null}
            className={cx(
              'flex items-center gap-1 py-[2px] px-2 border rounded-3xl whitespace-nowrap focus:text-white focus:outline-none hover:bg-gray-98 group',
              {
                'border-primary focus:bg-primary': !user.isExternal,
                'border-tertiary focus:bg-tertiary': user.isExternal,
              },
            )}
            onKeyDown={handleFocusedUserPill}
            onClick={() => {
              handleSetOrRemoveUsers(user);
            }}
          >
            <span className='effra-12'>{user.fullName}</span>
            <span className='pt-[1px]'>
              <i className='group-hover:text-black group-focus:text-white fa-regular fa-sharp fa-xmark text-gray-60' />
            </span>
          </button>
        ))}
        <input
          type='text'
          name='userInput'
          ref={inputRef}
          placeholder={placeholder}
          className='bg-transparent focus:outline-none grow placeholder:text-neutral-400'
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleInputKeydown}
        />
      </div>
      <div className='absolute z-10 w-full'>
        {userList({
          users: filteredUsers,
          addUser: handleAddUser,
          shouldShow: showList,
          shouldShowAnonymousUserMessage: hasAnonymousUsers,
        })}
      </div>
    </div>
  );
}
