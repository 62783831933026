import cx from 'classnames';
import { useGetModularObjectByIdQuery } from '../../ObjectSelectionModal/getModularObjectById.generated';
import { useCollabDiscoveryContext } from '../CollabDiscovery.context';
import { DropdownOption } from '../hooks/useDropdownOptionMap';
import ApprovalSetting from './ApprovalSetting';
import DependencyApprovalWarning from './DependencyApprovalWarning';
import VisibilitySetting from './VisibilitySetting';

function VisibleByAssociation ({ objectId }: { objectId: string }) {
  const { data, loading, previousData } = useGetModularObjectByIdQuery({
    variables: { id: objectId },
  });

  const _data = loading ? previousData : data;

  const { visibility, template } = _data?.getModularObjectByID ?? {};

  const visConfig = {
    'Private': {
      title: 'Visible by association',
      description: `You can see this ${template?.name} because it is related to another item you can see.`,
    },
    'Company': {
      title: 'Visible to your company',
      description: `You can see this ${template?.name} because it has been made visible to your entire company.`,
    },
  };

  const { title, description } = visConfig[visibility] ?? {};

  return (
    <section className='flex flex-col gap-4 mt-8'>
      <div className='effra-12'>
        <div className='flex gap-4'>
          <i
            className={cx('fa-sharp fa-regular fa-circles-overlap w-[18px] text-center', {
              'hidden': visibility === 'Company',
            })}
          />
          <i
            className={cx('fa-sharp fa-regular fa-users w-[18px] text-center', {
              'hidden': visibility === 'Private',
            })}
          />
          <div className='flex flex-col gap-2'>
            <p className='text-black'>
              {title}
            </p>
            <p className='text-gray-60 max-w-[272px]'>{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function AccessSettings ({ objectId }: { objectId: string }) {
  const {
    currentUserId,
    currentUserIsOwner,
    currentUserRole,
    initialUsers: collaborators,
  } = useCollabDiscoveryContext();

  // @ts-expect-error - type data needs to be fixed
  const isNotShared = !collaborators.find(({ user }) => user?.id === currentUserId);

  if (currentUserRole === DropdownOption.Viewer) return null;

  if (isNotShared && !currentUserIsOwner) {
    return <VisibleByAssociation objectId={objectId} />;
  }

  return (
    <section className='flex flex-col gap-4 mt-8'>
      <VisibilitySetting objectId={objectId} />
      <ApprovalSetting objectId={objectId} />
      <DependencyApprovalWarning objectId={objectId} className='p-2 mt-6' />
    </section>
  );
}
