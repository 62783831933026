export const downloadFile = async (file, downloaderRef): Promise<void> => {
  if (file?.url?.includes('X-Amz-Algorithm')) {
    downloaderRef.current.href = file.url;
  } else {
    const filename = file.name;
    const id = file.externalId ?? file.id;

    const result = await fetch(`/api/file-proxy?filename=${filename}&id=${id}`, {
      credentials: 'include',
    });

    if (result.status <= 299) {
      try {
        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);
        downloaderRef.current.download = filename;
        downloaderRef.current.href = href;
      } catch (e) {
      }
    } else {
      console.error(result.statusText);
      const json = await result.json();
      throw new Error(json.error);
    }
  }
  downloaderRef?.current?.click();
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createAndDownloadFile (data: any, type: string, filename: string): void {
  const blob = new Blob([data], { type });
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  link.click();
}
