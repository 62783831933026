import type { DiffType, ModularObject } from '@/__generated__/types';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { OnOffToggle } from '@/components/modals/CollaboratorDiscovery/AccessSettings/ToggleOption';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import StatusTooltip from '@/components/tooltip/StatusTooltip';
import { GetTemplatesUsedByModularObjectsDocument } from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/sharp-solid-svg-icons';
import { useLockObjectDurationMutation } from './LockObjectDuration.generated';

interface DurationLockProps {
  isLocked: boolean;
  objectCardData: ModularObject;
}

export default function DurationLock (
  { objectCardData }: DurationLockProps,
): JSX.Element {
  const [lockObjectDuration] = useLockObjectDurationMutation();
  const { approvals } = objectCardData;

  const pendingApprovals = approvals?.filter((approval) => approval.status === 'pending').find(
    (approval) => approval.requestedChanges.find((change) => change?.field === 'durationLocked'),
  );

  const diff = {
    externalID: objectCardData?.id,
    externalType: 'modular_object',
    diff: {
      to: {
        durationLocked: !objectCardData?.durationLocked,
      },
      displayNames: { durationLocked: 'durationLocked' },
    },
    fromMigration: false,
    diffType: 'updated' as DiffType,
  };

  const handleLockChange = async () => {
    await lockObjectDuration({
      variables: { input: { diffs: [diff] } },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument, GetTemplatesUsedByModularObjectsDocument],
      onCompleted: ({ updateModularObject }) => {
        const [{ approvals }] = updateModularObject ?? [{}];
        const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(approvals);
        const isSaved = !fieldsPendingApprovalMap.durationLocked;
        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved duration lock'
          : 'Successfully requested approval for duration lock';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: () => {
        addToastError('Failed to update duration lock, please try again');
      },
    });
  };

  return (
    <span
      className='flex gap-x-1 items-center'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void handleLockChange();
      }}
    >
      <span>
        {pendingApprovals && <StatusTooltip status='waiting for approval' />}
      </span>
      <OnOffToggle
        identifier='object-duration'
        enabled={objectCardData?.durationLocked}
        onState={
          <span className='flex justify-center items-center h-full'>
            <FontAwesomeIcon icon={faLock} className='text-[8px]' />
          </span>
        }
        offState={
          <span className='flex justify-center items-center h-full'>
            <FontAwesomeIcon icon={faLockOpen} className='text-[8px]' />
          </span>
        }
      />
    </span>
  );
}
