import CreateTeamModal from '@/components/modals/CreateTeamModal/CreateTeamModal';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useModal } from '@/hooks/useModal';
import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUsersLine } from '@fortawesome/sharp-solid-svg-icons';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import SidenavSection from '../../components/SidenavSection';
import SidenavTab from '../../components/SidenavTab';
import type { TeamsForTabsQuery } from './TeamsForTabs.generated';

export default function TeamsTabs ({ teams }: { teams: TeamsForTabsQuery['teams']['edges'] }): JSX.Element {
  const router = useRouter();
  const loggedInUser = useLoggedInUser();
  const { showModal, closeModal } = useModal();
  // ENG-5743 – Show new team button if user is manager/admin/super
  const canCreateTeams = useMemo(() => {
    return loggedInUser?.role === 'manager' || loggedInUser?.role === 'admin' || loggedInUser?.role === 'super';
  }, [loggedInUser?.role]);
  const handleNewTeamClick = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    showModal(<CreateTeamModal closeModal={closeModal} />, {
      className: 'max-w-[514px] !overflow-y-visible',
    });
    metrics.track('sidenav - new team clicked from teams section', {
      currentPath: router.pathname,
    });
  };

  return (
    <SidenavSection title='Teams'>
      {teams.map((team) => (
        <SidenavTab
          key={`nav-team-${team.id}`}
          name={team.name}
          icon={<FontAwesomeIcon icon={faUsersLine} />}
          href={`/team?teamId=${team.id}`}
          isActive={router.pathname === `/team` && router?.query?.teamId === team.id}
        />
      ))}
      {canCreateTeams && (
        <SidenavTab
          name='New Team'
          className='opacity-80 transition-opacity hover:opacity-100 bg-white/20'
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleNewTeamClick}
        />
      )}
    </SidenavSection>
  );
}
