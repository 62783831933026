import type * as Types from '../__generated__/types';

import { gql } from '@apollo/client';
import { FullUserFragmentDoc } from '../graphql/fragments/user.fragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetLoggedInUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLoggedInUserQuery = { __typename?: 'Query', getUser: { __typename?: 'UserAndSubscription', user: { __typename?: 'User', forcePasswordReset: boolean | null, workCapacity: number | null, isTesting: boolean | null, id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null }, subscription: { __typename?: 'OrganizationSubscriptionPlan', id: string, organizationId: string, type: Types.SubscriptionTypes, startDate: any, endDate: any | null, trialEndDate: any | null, featureLimits: any | null } | null } };


export const GetLoggedInUserDocument = gql`
    query GetLoggedInUser {
  getUser {
    user {
      ...FullUser
      forcePasswordReset
      workCapacity
      isTesting
    }
    subscription {
      id
      organizationId
      type
      startDate
      endDate
      trialEndDate
      featureLimits
    }
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
      }
export function useGetLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
        }
export function useGetLoggedInUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(GetLoggedInUserDocument, options);
        }
export type GetLoggedInUserQueryHookResult = ReturnType<typeof useGetLoggedInUserQuery>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserLazyQuery>;
export type GetLoggedInUserSuspenseQueryHookResult = ReturnType<typeof useGetLoggedInUserSuspenseQuery>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLoggedInUserQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getUser })
 *   )
 * })
 */
export const mockGetLoggedInUserQuery = (resolver: Parameters<typeof graphql.query<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>>[1]) =>
  graphql.query<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    'GetLoggedInUser',
    resolver
  )
