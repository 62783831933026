import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersLine } from '@fortawesome/sharp-regular-svg-icons';

export default function TeamProfileImage () {
  return (
    <div className='flex justify-center items-center rounded-full border-2 border-black max-w-[40px] max-h-[40px] w-full h-full aspect-square'>
      <FontAwesomeIcon icon={faUsersLine} />
    </div>
  );
}
