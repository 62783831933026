import { useEditContext } from '@/components';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import getAppUrl from '@/util/getAppUrl';
import { postFiles } from '@/util/requests.functions';
import cx from 'classnames';
import { useCallback } from 'react';
import { v4, v5 } from 'uuid';
import { uploadNewFile } from '../common/FilesLinks/utils';
import HookFormLabel from './HookFormLabel';
import HookFormTextArea from './HookFormTextArea';

export interface TextAreaInputProps {
  inputProps?: Partial<React.ComponentProps<typeof HookFormTextArea>>;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
  className?: string;
}

export default function TextAreaInput ({
  className = '',
  labelProps = {},
  inputProps = {},
}: TextAreaInputProps): JSX.Element {
  const { className: inputClassName = '', ...rest } = inputProps;
  const { canUserEdit } = useEditContext();
  const { objectCardData } = useObjectCardContext();
  const user = useLoggedInUser();

  const handleImageUpload = useCallback(async (blob) => {
    // Upload to storage
    const uploadResponse = await uploadNewFile(blob);
    const responseFile = uploadResponse?.file;

    // Create new file row in database
    const newFileId = v5(`${responseFile.name}-${v4()}`, user?.id);
    const newFile = {
      ...uploadResponse.file,
      id: newFileId,
      externalId: objectCardData.id,
      externalType: 'modular_object',
      showInFiles: false,
    };

    const fileResponse = await postFiles([newFile]);
    const imageId = fileResponse[0].id;

    return `${getAppUrl()}/api/images/${imageId}`;
  }, [objectCardData.id, user?.id]);

  const placeholderName = (rest?.placeholder || labelProps?.label || '').replace(':', '').toLowerCase();
  const placeholderText = placeholderName ? `Enter ${placeholderName}` : 'Enter value';

  return (
    <div data-testid='text-area' className={cx('flex flex-col ', className)}>
      <HookFormLabel {...labelProps} />
      <div className='flex flex-col'>
        <HookFormTextArea
          className={cx(`w-full font-effra p-3 resize-none min-h-[100px] ${inputClassName}`, {
            'input-text': canUserEdit,
            '!h-[unset]': canUserEdit,
          })}
          placeholder={placeholderText}
          isEditable={canUserEdit}
          shouldShowToolbar
          onImageUpload={handleImageUpload}
          {...rest}
        />
      </div>
    </div>
  );
}
