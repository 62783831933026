import { useModal } from '@/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/sharp-solid-svg-icons';

export interface ModalHeaderProps {
  title: string;
}

export default function ModalHeader ({ title }: ModalHeaderProps) {
  const { closeModal } = useModal();

  return (
    <header className='flex justify-between items-center mb-[16px]'>
      <h2 className='font-bold effra-24'>{title}</h2>
      <FontAwesomeIcon
        data-testid='modal-close-icon'
        onClick={closeModal}
        icon={faXmark}
        className='cursor-pointer text-gray-60 text-[16px]'
      />
    </header>
  );
}
