import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/sharp-light-svg-icons';

interface ModalHeaderProps {
  closeModal: () => void;
}

export default function ModalHeader ({ closeModal }: ModalHeaderProps) {
  return (
    <header className='flex justify-between items-center'>
      <h1 className='text-2xl font-bold'>Remove Collaborators</h1>
      <FontAwesomeIcon
        data-testid='modal-close-icon'
        onClick={closeModal}
        icon={faXmark}
        className='cursor-pointer text-gray-60 text-[16px]'
      />
    </header>
  );
}
