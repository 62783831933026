/**
 * This function returns a Blob from a dataURL string, useful for preparing things like resized canvas images to files that can be sent in HTTP POST requests
 *
 * Adapted from this stackoverflow answer: https://stackoverflow.com/a/30407840
 *
 * @param dataurl string
 * @returns Blob
 */
export default function dataURLtoBlob (dataurl: string): Blob {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.{1,255});/)[1]; // This is limited to 255 characters, which is the max length of a MIME type. Implemented so we don't open ourselves up to DOS attacks that unbounded regex matching could cause. (Sonarlint complained)
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
