export const IGNORE_FIELDS = {
  id: true,
  ancestorId: true,
  ancestorID: true,
  organization: true,
  organizationId: true,
  owner: true,
  ownerId: true,
  ownerID: true,
  assignee: true,
  shares: true,
  mission: true,
  missionId: true,
  spacecraft: true,
  spacecraftId: true,
  createdAt: true,
  deletedAt: true,
  modifiedAt: true,
  approvalStatus: true,
  createdById: true,
  createdByID: true,
  approvalType: true,
  createdBy: true,
  spacecrafts: true,
  files: true,
  integrations: true,
  engineId: true,
  externalLink: true,
  addedFiles: true,
  removedFiles: true,
  addedLinks: true,
  removedLinks: true,
  addedShares: true,
  customFields: true,
  removedShares: true,
  updatedShares: true,
  requestedOn: true,
  requestedById: true,
  requestedDiffId: true,
  parentID: true,
  parentType: true,
  statusType: true,
  displayName: true,
};

export const BOOLEAN_FIELDS = {
  spacecraft: {
    electricalConnectivity: {
      data: true,
      charging: true,
      other: true,
    },
    licensing: {
      notLicensingWithFCC: true,
    },
    testing: {
      dotCertified: true,
      vendorCoC: true,
      proofNonLoadPath: true,
      stressLoading: true,
      acceptanceTestStrategy: true,
      burstQualTestReport: true,
      cycleTestReport: true,
      vibrationTestReport: true,
      structuralAnalysis: true,
    },
  },
  payload: {
    isSoftware: true,
    requirements: {
      dataStorage: {
        required: true,
      },
      downlinking: {
        required: true,
      },
      busDataProcessing: true,
    },
  },
};

export const DATE_FIELDS = [
  'launchDate',
  'launchWindowOpen',
  'launchWindowClose',
  'launchPeriodOpen',
  'launchPeriodClose',
  'dueDate',
  'dateCompleted',
  'startDate',
  'targetDate',
];
