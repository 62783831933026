import type { Share, Team as TeamType, TeamShare, User } from '@/__generated__/types';
import CustomCheckbox from '@/components/modules/CustomCheckbox';
import Collaborator from '@/designSystemComponents/Collaborator';
import Team from '@/designSystemComponents/Team';

import { Fragment } from 'react';

interface CollaboratorListItemProps {
  selected: Share[];
  user: Partial<User>;
  handleCheck: (user: User) => void;
}

export function CollaboratorListItem ({ selected, user, handleCheck }: CollaboratorListItemProps) {
  return (
    <div className='flex gap-3 items-center' key={user?.id}>
      <CustomCheckbox
        selected={Boolean(selected.find(selectedUser => selectedUser?.user?.id === user?.id))}
        onCheck={() => handleCheck(user as User)}
        className='cursor-pointer h-[16px] w-[16px]'
      />
      <Collaborator user={user as User} withTitle />
    </div>
  );
}

interface TeamListItemProps {
  selected: TeamShare[];
  team: TeamType;
  handleCheck: (team: TeamType) => void;
}

export function TeamListItem ({ selected, team, handleCheck }: TeamListItemProps) {
  return (
    <div className='flex gap-3 items-center' key={team?.id}>
      <CustomCheckbox
        selected={selected.some(({ team: shareTeam }) => shareTeam?.id === team?.id)}
        onCheck={() => handleCheck(team)}
        className='cursor-pointer h-[16px] w-[16px]'
      />
      <Team team={team} />
    </div>
  );
}

interface CollaboratorListProps {
  collaborators: Array<Partial<User>>;
  selected: Array<Share | TeamShare>;
  addCollaborator: (user: User) => void;
  teams: TeamType[];
  addTeam: (team: TeamType) => void;
  emptyStateMessage?: string;
}

export default function CollaboratorList (
  { collaborators, teams, selected, addCollaborator, addTeam, emptyStateMessage = 'No collaborators found' }:
    CollaboratorListProps,
) {
  const selectedShares: Share[] = selected.filter(({ __typename }) => __typename === 'Share') as Share[];
  const selectedTeams: TeamShare[] = selected.filter(({ __typename }) => __typename === 'TeamShare') as TeamShare[];

  return (
    <Fragment>
      {collaborators.map((user) => (
        <CollaboratorListItem
          key={user?.id}
          selected={selectedShares}
          user={user}
          handleCheck={addCollaborator}
        />
      ))}
      {teams.map((team) => (
        <TeamListItem
          key={team?.id}
          selected={selectedTeams}
          team={team}
          handleCheck={addTeam}
        />
      ))}
      <NoCollabsMessage emptyStateMessage={emptyStateMessage} showMessage={!collaborators.length && !teams.length} />
    </Fragment>
  );
}

function NoCollabsMessage ({ emptyStateMessage, showMessage }: { emptyStateMessage: string; showMessage: boolean }) {
  if (!showMessage) return null;

  return (
    <div className='flex justify-center items-center p-4 grow text-gray-60'>
      <div className='font-medium effra-14'>{emptyStateMessage}</div>
    </div>
  );
}
