import type { Share } from '@/__generated__/types';
import { useGetUserByIdQuery } from '@/components/User/getUserById.generated';
import User from '@/components/User/User';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import type { GetObjectCardObjectQuery } from '../../../Card/GetObjectCardObject.generated';

interface CollaboratorBubblesProps {
  shares: GetObjectCardObjectQuery['getModularObjectByIDs'][0]['shares'];
}
export default function CollaboratorBubbles (
  { shares = [] }: CollaboratorBubblesProps,
): JSX.Element {
  const currentUser = useLoggedInUser();
  const currentUserId = currentUser?.id;

  const { objectCardData } = useObjectCardContext();
  const { visibility, ownerId } = objectCardData ?? {};

  const { data: ownerData } = useGetUserByIdQuery({
    skip: !ownerId,
    variables: { id: ownerId },
  });
  const owner = ownerData?.getUserByID;

  const isOwner = ownerId === currentUserId;
  const isPrivateObject = visibility === 'Private';
  const hasNotBeenShared = shares?.find((share: Share) => share.userId === currentUserId);

  const hasImplicityVisibility = Boolean(!isOwner && isPrivateObject && !hasNotBeenShared);

  const filteredShares = shares
    .filter((s: Share) => s.userId !== ownerId && !hasImplicityVisibility);

  const numCollaborators = filteredShares.length;
  const shouldCollaboratorsOverflow = numCollaborators > 0;

  return (
    <div
      className={cx(
        'flex h-[32px] justify-between',
        {
          'w-[59px]': shouldCollaboratorsOverflow,
        },
      )}
    >
      <div className='relative w-[32px]'>
        <div className='absolute z-10 h-[32px] w-[32px]'>
          <User
            user={owner}
            imageOnly
          />
        </div>
      </div>
      {shouldCollaboratorsOverflow && (
        <div className='relative w-[32px]'>
          <div
            data-testid='collaborator-bubbles-number'
            className='flex absolute justify-center items-center rounded-full transition-colors h-[32px] w-[32px] bg-neutral-200'
          >
            +{numCollaborators}
          </div>
        </div>
      )}
    </div>
  );
}
