import type HookFormInput from '@/components/modules/HookFormInput';
import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import DatePickerInput, { type ParentRangeColor } from './DatePickerInput';

export interface ControlledDatePickerProps {
  id?: string;
  className?: string;
  name: string;
  startDate?: Date;
  endDate?: Date;
  parentStartDate?: Date;
  parentEndDate?: Date;
  parentRangeColor?: ParentRangeColor;
  placeholder?: string;
  inputProps?: Partial<React.ComponentProps<typeof HookFormInput>>;
}

function ControlledDatePicker (
  { id, name, inputProps = {}, ...props }: ControlledDatePickerProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const { field, fieldState } = useController({ name });
  return (
    <DatePickerInput
      id={id || name}
      error={fieldState.error?.message}
      ref={ref}
      {...props}
      {...inputProps}
      {
        // Input props are still used here due to the way modules are currently set up, but would like to treat this as a controlled component with these props at the root level
        ...field
      }
    />
  );
}

export default forwardRef(ControlledDatePicker);
