import { KeyboardEventNames } from '@/util/constants';
import cx from 'classnames';
import { Children, cloneElement, isValidElement, type ReactNode, useState } from 'react';
import { AnonymousUserMessage, NoResultsMessage } from '../KeyboardNavigableUserList';

interface KeyboardNavigableListProps {
  children: ReactNode;
  shouldShow?: boolean;
  noResultsMessage?: () => JSX.Element;
  shouldShowAnonymousUserMessage?: boolean;
}

export function KeyboardNavigableList (
  {
    children,
    shouldShow = true,
    noResultsMessage = NoResultsMessage,
    shouldShowAnonymousUserMessage,
  }: KeyboardNavigableListProps,
) {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const sortedChildren = Children.toArray(children).filter(isValidElement).sort(
    (a: React.ReactElement<KeyboardNavigableListItemProps>, b: React.ReactElement<KeyboardNavigableListItemProps>) => {
      const aProp = a.props?.sortBy;
      const bProp = b.props?.sortBy;
      return aProp.localeCompare(bProp);
    },
  ).map((child, index) => {
    const attributes: React.HTMLAttributes<HTMLButtonElement> = {
      id: `navigable-list-item-${index}`,
    };
    return cloneElement(child, attributes);
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    let nextIndex = focusedIndex;
    switch (event.key) {
      case KeyboardEventNames.ArrowDown:
        event.preventDefault();
        event.stopPropagation();
        nextIndex = focusedIndex < sortedChildren.length - 1 ? focusedIndex + 1 : 0;
        break;
      case KeyboardEventNames.ArrowUp:
        event.preventDefault();
        event.stopPropagation();
        nextIndex = focusedIndex > 0 ? focusedIndex - 1 : sortedChildren.length - 1;
        break;
      default:
        break;
    }

    setFocusedIndex(nextIndex);

    // focus the next element by id
    const nextElement = document.getElementById(`navigable-list-item-${nextIndex}`);
    if (nextElement) {
      nextElement.focus();
    }
  };

  if (!shouldShow) return null;

  return (
    <div
      id='navigable-list'
      tabIndex={0} // Make the div focusable
      onKeyDown={handleKeyDown}
      className={cx(
        'bg-white min-h-[40px] max-h-[230px] overflow-y-scroll py-1 mt-2 border rounded-[2px] shadow-heavy border-gray-90 flex flex-col',
      )}
    >
      {shouldShowAnonymousUserMessage && <AnonymousUserMessage />}
      {!sortedChildren.length && !shouldShowAnonymousUserMessage && (noResultsMessage())}
      {sortedChildren}
    </div>
  );
}

interface KeyboardNavigableListItemProps {
  children: React.ReactNode;
  sortBy: string;
  onSelect: () => void;
  id?: string;
  className?: string;
}

export function KeyboardNavigableListItem ({ children, onSelect, id, className }: KeyboardNavigableListItemProps) {
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSelect();
    }
    // else propagates key down event to list
  };

  return (
    <button
      id={id}
      onClick={onSelect}
      onKeyDown={onKeyDown}
      className={className}
    >
      {children}
    </button>
  );
}
