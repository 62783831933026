import type * as Types from '../../../../../__generated__/types';

import { gql } from '@apollo/client';
export type UserPermissionOnObjectPropsFragment = { __typename?: 'ModularObject', id: string, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, userId: string | null, role: Types.ShareRole | null }> | null };

export const UserPermissionOnObjectPropsFragmentDoc = gql`
    fragment UserPermissionOnObjectProps on ModularObject {
  id
  owner(includeDeleted: true) {
    id
    organizationId
  }
  shares {
    id
    userId
    role
  }
}
    `;